import { queryOptions } from '@tanstack/react-query';

import type { ApiReview, ReviewsApi } from '@legalfly/api/reviews';

export const reviewsQueryOptions = ({ reviewsApi }: { reviewsApi: ReviewsApi }) => ({
  reviews: (query: Parameters<ReviewsApi['getReviews']>[0] = {}) =>
    queryOptions({
      queryKey: ['reviews', query.sortBy, query.sortOrder].concat(
        query?.documentUuid ? [query.documentUuid] : [],
      ),
      queryFn: () => reviewsApi.getReviews(query),
    }),
  review: ({ uuid }: { uuid: ApiReview['uuid'] }) =>
    queryOptions({
      queryKey: ['reviews', uuid],
      queryFn: () => reviewsApi.getReview({ uuid }),
    }),
});
