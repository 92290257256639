import { createCommentsModule } from '@legalfly/modules/comments';

import { commentsApi } from '../../di';

export const { useComments, useAddComment, useDeleteComment, commentsQueryOptions } =
  createCommentsModule({
    commentsApi,
  });

export * from './toasts';
