import { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import type { ApiComment } from '@legalfly/api/comments';
import { IconButton } from '@legalfly/ui/button';
import { Divider } from '@legalfly/ui/divider';
import { Text } from '@legalfly/ui/text';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';

import { UserAvatar } from '../users/UserAvatar';

export const CommentsList = ({
  comments,
  scrollEnabled,
  onDeleteComment,
}: {
  comments: ApiComment[];
  scrollEnabled: boolean;
  onDeleteComment: (uuid: ApiComment['uuid']) => void;
}) => {
  const commentsRef = useRef<HTMLDivElement>(null);
  const { i18n } = useTranslation();

  useLayoutEffect(() => {
    if (commentsRef.current && scrollEnabled && comments.length) {
      commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
    }
  }, [comments.length, scrollEnabled]);

  return (
    <>
      <div className='flex max-h-[280px] flex-col overflow-y-auto py-2' ref={commentsRef}>
        {comments.map((comment) => (
          <div
            key={comment.uuid}
            className='group flex flex-col gap-1 px-5 py-2 duration-300 ease-in-out animate-in fade-in slide-in-from-bottom-2 hover:bg-fill-strong'
          >
            <div className='flex items-center gap-2'>
              <UserAvatar
                firstName={comment.user.firstName}
                lastName={comment.user.lastName}
                avatarUrl={comment.user.avatarUrl}
                size='sm'
              />
              <Text>
                {comment.user.firstName} {comment.user.lastName}
              </Text>
              <Text
                className='text-content-body-weak'
                title={formatLongDate(comment.createdAt, i18n.language)}
              >
                {formatRelativeTime(comment.createdAt, i18n.language)}
              </Text>
              <IconButton
                name='trash-2'
                variant='tertiary'
                className='invisible ms-auto group-hover:visible'
                size='sm'
                onClick={() => onDeleteComment(comment.uuid)}
              />
            </div>

            <div className='ps-10'>
              <Text
                className="[&_span[data-type='mention']]:text-content-body-tag"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(comment.content, {
                    ALLOWED_TAGS: ['p', 'span'],
                    ALLOWED_ATTR: ['data-type'],
                  }),
                }}
              />
            </div>
          </div>
        ))}
      </div>
      {Boolean(comments.length) && (
        <Divider variant='weak' className='w-[calc(100%-40px)] self-center' />
      )}
    </>
  );
};
