import { createNotificationsModule } from '@legalfly/modules/notifications';

import { notificationsApi } from '../../di';

export const {
  useNotifications,
  useMarkNotificationAsSeen,
  useMarkAllNotificationsAsSeen,
  useDeleteNotification,
  useDeleteAllNotifications,
  notificationsQueryOptions,
} = createNotificationsModule({
  notificationsApi,
});

export * from './toasts';
