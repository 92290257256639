import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { ApiComment, CommentsApi } from '@legalfly/api/comments';

import { commentsQueryOptions } from './commentsQueryOptions';

export const createCommentsModule = ({ commentsApi }: { commentsApi: CommentsApi }) => {
  const queryOptions = commentsQueryOptions({ commentsApi });

  const useComments = ({
    enabled,
    ...params
  }: Parameters<CommentsApi['getComments']>[0] & { enabled?: boolean }) => {
    const { data, isLoading, error } = useQuery({
      ...queryOptions.comments(params),
      enabled,
    });

    return {
      comments: data ?? [],
      isLoading,
      error,
    };
  };

  const useAddComment = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['comments', 'add'],
      mutationFn: (params: Parameters<CommentsApi['addComment']>[0]) =>
        commentsApi.addComment(params),
      onSuccess: (comment, { params }) => {
        queryClient.setQueryData(queryOptions.comments(params).queryKey, (comments) => {
          return [...(comments ?? []), comment];
        });
      },
    });

    return {
      addComment: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteComment = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['comments', 'delete'],
      mutationFn: (
        params: Parameters<CommentsApi['deleteComment']>[0] &
          Pick<ApiComment, 'uuid' | 'entityType'>,
      ) => commentsApi.deleteComment(params),
      onMutate: async ({ params, uuid, entityType }) => {
        const queryKey = queryOptions.comments({ entityUuid: uuid, entityType }).queryKey;
        await queryClient.cancelQueries({ queryKey });

        const previousComments = queryClient.getQueryData(queryKey);

        queryClient.setQueriesData({ queryKey }, (comments: ApiComment[] | undefined) => {
          if (!comments) return [];
          return comments.filter((comment) => comment.uuid !== params.commentUuid);
        });

        return { previousComments };
      },
      onError: (_, { uuid, entityType }, context) => {
        queryClient.setQueryData(
          queryOptions.comments({ entityUuid: uuid, entityType }).queryKey,
          context?.previousComments,
        );
      },
    });

    return {
      deleteComment: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useComments,
    useAddComment,
    useDeleteComment,
    commentsQueryOptions: queryOptions,
  };
};
