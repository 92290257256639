import type * as React from 'react';
import type { AvatarImageProps } from '@radix-ui/react-avatar';

import { Avatar, AvatarFallback, AvatarImage } from '@legalfly/ui/avatar';
import { cn } from '@legalfly/ui/utils';

import { AvatarStatus } from './AvatarStatus';

const getFirstLetter = (value: string) => value?.[0] ?? '';

interface Props extends React.ComponentProps<typeof Avatar> {
  avatarUrl: AvatarImageProps['src'] | null;
  firstName: string | undefined;
  lastName: string | undefined;
  lastActivity?: Date | null;
}

export const UserAvatar = ({
  avatarUrl,
  firstName,
  lastName,
  lastActivity,
  className,
  ...props
}: Props) => {
  return (
    <Avatar {...props} className={cn('relative overflow-visible', className)}>
      {avatarUrl && <AvatarImage src={avatarUrl} alt={`${firstName} ${lastName}`} />}
      <AvatarFallback>
        {firstName && lastName ? `${getFirstLetter(firstName)}${getFirstLetter(lastName)}` : 'LF'}
      </AvatarFallback>
      {lastActivity && <AvatarStatus lastActivity={lastActivity} />}
    </Avatar>
  );
};
