import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { useDefaultJurisdiction } from 'common/hooks/useDefaultJurisdiction';

import type { ApiConversation, ConversationTypeEnum } from '@legalfly/api/conversations';
import type { Jurisdiction } from '@legalfly/api/core';
import { DocumentStatus } from '@legalfly/api/documents';
import {
  SelectedDocumentsProvider,
  useSelectedDocuments,
} from '@legalfly/components/documentPicker/SelectedDocumentsProvider';
import type { TrackingEventMeta } from '@legalfly/reporting/tracking';
import { trackEvent } from '@legalfly/reporting/tracking';
import { SidePaneProvider } from '@legalfly/ui/sidePane';
import { withToasts } from '@legalfly/ui/toast';
import { withViewTransition } from '@legalfly/utils/dom';
import { ShareDialog } from 'components/common/dialog/share/ShareDialog';
import {
  useConversations,
  useCreateConversation,
  useCreateConversationMessage,
  useUpdateConversation,
} from 'core/modules/conversations';
import { conversationToasts } from 'core/modules/conversations/toasts';
import { useCreateFiles } from 'core/modules/documents/helpers/useCreateFiles';

import { Copilot } from './copilot/Copilot';
import { ConversationList } from './list/ConversationList';
import {
  OptimisticConversationProvider,
  useOptimisticConversationContext,
} from './OptimisticConversationProvider';

interface Props {
  uuid?: ApiConversation['uuid'];
  conversationType: ConversationTypeEnum;
  analyticsMeta: TrackingEventMeta;
}

const Component = ({ uuid, conversationType, analyticsMeta }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { createConversationMessage } = useCreateConversationMessage({ type: conversationType });
  const { createConversation, isLoading: isCreatingConversation } = useCreateConversation({
    type: conversationType,
  });
  const { clearSelectedDocuments, selectedDocuments } = useSelectedDocuments();

  const { conversations } = useConversations({ type: conversationType });

  const { conversation, setOptimisticConversationUuid } = useOptimisticConversationContext();
  const defaultJurisdiction = useDefaultJurisdiction();

  const [jurisdiction, setJurisdiction] = useState<Jurisdiction>(
    conversation?.jurisdiction ?? defaultJurisdiction,
  );

  const { updateConversation } = useUpdateConversation({ type: conversationType });

  const { createFiles, isCreatingFiles, numberOfFilesToUpload } = useCreateFiles('discovery');

  const hasDocuments = (conversation?.documents ?? []).length > 0;
  const hasUploadedFiles = conversation?.documents?.some(
    (doc) =>
      doc.type === 'file' &&
      (doc.status === DocumentStatus.COMPLETE || doc.status === DocumentStatus.ERROR),
  );

  const isLoading =
    isCreatingConversation || isCreatingFiles || (hasDocuments && !hasUploadedFiles);

  const handleUpdateJurisdiction = async (jurisdiction: Jurisdiction) => {
    trackEvent({
      action: 'click',
      category: 'discovery',
      label: 'jurisdiction',
    });
    setJurisdiction(jurisdiction);
    if (!uuid) return;

    await withToasts(updateConversation({ uuid, body: { jurisdiction } }))(
      conversationToasts.updateConversation(),
    );
  };

  const uploadFiles = async (files: File[]) => {
    const docs = await createFiles({ uuid: undefined, files });

    if (!docs.length) return;

    if (!conversation?.uuid) {
      const createdConversation = await withToasts(
        createConversation({
          jurisdiction,
          documentUuids: [
            ...docs.map((doc) => doc.uuid),
            ...selectedDocuments.map((doc) => doc.uuid),
          ],
        }),
      )(conversationToasts.createConversation());

      setOptimisticConversationUuid(createdConversation.uuid);
      return;
    }

    await withToasts(
      updateConversation({
        uuid: conversation.uuid,
        body: {
          documentUuids: [
            ...(conversation?.documents.map((doc) => doc.uuid) ?? []),
            ...docs.map((doc) => doc.uuid),
          ],
        },
      }),
    )(conversationToasts.updateConversation());
  };

  const onSendMessage = async (msg: string) => {
    withViewTransition(async () => {
      const createdConversation = await withToasts(
        createConversationMessage({
          uuid: conversation?.uuid,
          question: msg,
          context: {
            documentUuids: selectedDocuments.map((doc) => doc.uuid),
          },
          jurisdiction,
        }),
      )(conversationToasts.createConversation());

      if (createdConversation.uuid !== uuid) {
        navigate({ to: `/discovery/$uuid`, params: { uuid: createdConversation.uuid } });
      }

      // The documents are now added to the conversation, so we clear the selected documents
      clearSelectedDocuments();
    });
  };

  const goToDiscovery = () => navigate({ to: '/discovery' });

  const hasActiveConversation = Boolean(uuid);
  const hasMessages = Boolean(conversation?.messages && conversation?.messages.length > 0);

  return (
    <SidePaneProvider className='h-content'>
      <ConversationList
        uuid={conversation?.uuid}
        conversationType={conversationType}
        documents={conversation?.documents ?? []}
        numberOfFilesToUpload={numberOfFilesToUpload}
        conversations={conversations}
        onCreateConversation={goToDiscovery}
        onDeleteConversation={goToDiscovery}
        onClickConversation={(uuid) => navigate({ to: `/discovery/$uuid`, params: { uuid } })}
      />
      <Copilot
        conversationUuid={conversation?.uuid}
        conversationHasMessages={hasMessages}
        onSendMessage={onSendMessage}
        conversationType={conversationType}
        className='relative ms-3 flex-1'
      >
        {!hasActiveConversation && <Copilot.Background />}
        <Copilot.ScrollContainer
          stickyClassName='flex items-center justify-end pe-4'
          stickyChildren={
            hasActiveConversation && (
              <ShareDialog
                title={t('dialog.share.conversation.title')}
                description={t('dialog.share.conversation.description')}
                entityType='discovery'
                entityUuid={conversation?.uuid!}
              />
            )
          }
        >
          <Copilot.Content className='mx-auto w-[90%] @lg:w-[70%]'>
            {!hasMessages ? <Copilot.EmptyState /> : null}
            <Copilot.Messages offsetTop={44} analyticsMeta={analyticsMeta} />
          </Copilot.Content>
        </Copilot.ScrollContainer>
        <Copilot.Footer className='mx-auto mb-11 w-[90%] pt-4 @lg:w-[70%]'>
          {hasActiveConversation && <Copilot.StopResponding />}

          {!hasMessages && <Copilot.Input size='large' className='mb-4' isLoading={isLoading} />}

          <Copilot.Actions>
            <Copilot.Actions.SelectDocuments>
              <Copilot.Actions.SelectDocuments.Content />
            </Copilot.Actions.SelectDocuments>
            <Copilot.Actions.AttachDocuments onUpload={uploadFiles} />
            <Copilot.Actions.SelectJurisdiction
              value={jurisdiction}
              onChange={handleUpdateJurisdiction}
            />
          </Copilot.Actions>

          {hasMessages && <Copilot.Input className='mt-4' isLoading={isLoading} />}
        </Copilot.Footer>
      </Copilot>
    </SidePaneProvider>
  );
};

export const Conversation = (props: Props) => {
  return (
    <SelectedDocumentsProvider>
      <OptimisticConversationProvider
        conversationUuid={props.uuid}
        conversationType={props.conversationType}
      >
        <Component {...props} />
      </OptimisticConversationProvider>
    </SelectedDocumentsProvider>
  );
};
