import type { ApiReview } from '@legalfly/api/reviews';
import { trackEvent } from '@legalfly/reporting/tracking';
import { withToasts } from '@legalfly/ui/toast';
import { EditableValueForm } from 'components/common/form/EditableValueForm';
import { useUpdateReview } from 'core/modules/reviews';
import { reviewToasts } from 'core/modules/reviews/toasts';

interface Props {
  review: ApiReview;
}

export const EditableReviewName = ({ review }: Props) => {
  const { updateReview } = useUpdateReview();

  const handleSubmit = async (newName: string) => {
    trackEvent({
      action: 'submit',
      category: 'review',
      label: 'editName',
    });

    await withToasts(
      updateReview({
        uuid: review.uuid,
        body: { name: newName },
      }),
    )(reviewToasts.updateReview());
  };

  return <EditableValueForm value={review.name ?? review.document.name} onsubmit={handleSubmit} />;
};
