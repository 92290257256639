import { CopyToClipboardButton } from '@legalfly/components/copyToClipboard/CopyToClipboardButton';

const ConversationMessageDraft = ({ content }: { content: string }) => {
  return (
    <div className='relative border border-stroke-weaker bg-fill-strongest'>
      <div
        className='prose break-words p-6 duration-700 dark:prose-invert'
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <CopyToClipboardButton content={content} className='absolute bottom-1 right-1' />
    </div>
  );
};

export { ConversationMessageDraft };
