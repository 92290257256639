import type { ApiDraft } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { withToasts } from '@legalfly/ui/toast';
import { EditableValueForm } from 'components/common/form/EditableValueForm';
import { draftingToasts, useUpdateDraft } from 'core/modules/drafting';

interface Props {
  draft: ApiDraft;
}

export const EditableDraftName = ({ draft }: Props) => {
  const { updateDraft } = useUpdateDraft();

  const handleSubmit = async (title: string) => {
    trackEvent({
      action: 'click',
      category: 'drafts',
      label: 'changeTitle',
    });

    await withToasts(updateDraft({ uuid: draft.uuid, body: { ...draft, title } }))(
      draftingToasts.updateDraft(),
    );
  };

  return <EditableValueForm value={draft.title} onsubmit={handleSubmit} />;
};
