import { useTranslation } from 'react-i18next';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';
import { useTableHeight } from 'common/hooks/useTableHeight';

import { useDocumentCategoryLabel } from '@legalfly/components/documentCategory/useDocumentCategoryLabel';
import { JurisdictionIcon } from '@legalfly/components/jurisdiction/SelectJurisdiction';
import { useJurisdictionCountryLabel } from '@legalfly/components/jurisdiction/useJurisdictionCountryLabel';
import { UserAvatar } from '@legalfly/components/users/UserAvatar';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, UploadButton } from '@legalfly/ui/button';
import { SearchInputForm } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@legalfly/ui/table';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';
import { ContentDropZone } from 'components/common/content/ContentDropZone';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { ContentSidebar } from 'components/common/content/ContentSidebar';
import { DropZone } from 'components/common/upload/DropZone';
import { ReviewsInsights } from 'components/review/ReviewsInsights';
import { ReviewsTableActionPopover } from 'components/review/ReviewsTableActionPopover';
import { useCreateFiles } from 'core/modules/documents/helpers/useCreateFiles';
import { playbookQueryOptions } from 'core/modules/playbooks';
import { reviewsQueryOptions, useReviews } from 'core/modules/reviews';

export const Route = createFileRoute('/_auth/_layout/review/')({
  component: ReviewOverviewRoute,
  loader({ context }) {
    return Promise.all([
      context.queryClient.ensureQueryData(playbookQueryOptions.playbooks()),
      context.queryClient.ensureQueryData(reviewsQueryOptions.reviews()),
    ]);
  },
});

function ReviewOverviewRoute() {
  const { t, i18n } = useTranslation();
  const getDocumentCategoryLabel = useDocumentCategoryLabel();
  const navigate = useNavigate();
  const { tableHeight, tableRef } = useTableHeight();

  const { reviews, stats } = useReviews();

  const { createFiles, isCreatingFiles } = useCreateFiles('review');

  const { filteredItems: filteredReviews, setKeyword } = useKeywordFilter(reviews, 'document.name');
  const getJurisdictionCountryLabel = useJurisdictionCountryLabel();

  const uploadFiles = async (files: File[]) => {
    const results = await createFiles({ uuid: undefined, files });

    if (results.length) {
      const [document] = results;
      navigate({
        to: '/review/new',
        search: {
          documentUuid: document.uuid,
        },
      });
    }
  };

  const hasReviews = reviews.length > 0;

  return (
    <div className='flex gap-3'>
      <ContentDropZone
        parentClassName='flex-1'
        isSingle
        onDropFiles={(files: File[]) => {
          trackEvent({
            action: 'drop',
            category: 'review',
          });
          uploadFiles(files);
        }}
        disabled={!hasReviews}
      >
        <ContentHeader>
          <ContentHeader.Title>{t('review.title')}</ContentHeader.Title>
          <ContentHeader.SubTitle>{t('review.subtitle')}</ContentHeader.SubTitle>
          <ContentHeader.Actions>
            <SearchInputForm
              placeholder={t('label.search')}
              onKeywordChange={setKeyword}
              onBlur={() =>
                trackEvent({
                  action: 'blur',
                  category: 'review',
                  label: 'search',
                })
              }
            />
            <Link to='/review/start' disabled={isCreatingFiles}>
              <Button renderLeft={<Icon name='folder' />} disabled={isCreatingFiles} variant='soft'>
                {t('review.actions.selectDocument')}
              </Button>
            </Link>
            <UploadButton
              onUpload={uploadFiles}
              renderLeft={<Icon name='upload-1' />}
              isLoading={isCreatingFiles}
              isSingle
              onClick={() =>
                trackEvent({
                  action: 'click',
                  category: 'review',
                  label: 'upload',
                })
              }
            >
              {t('review.actions.uploadDocument')}
            </UploadButton>
          </ContentHeader.Actions>
        </ContentHeader>

        {hasReviews ? (
          <Table
            parentClassName='mb-4 h-fit overflow-y-auto relative'
            ref={tableRef}
            style={{ maxHeight: tableHeight }}
          >
            <TableHeader className='sticky top-0 z-10 bg-surface-base'>
              <TableRow className='shadow-table-border'>
                <TableHead>{t('review.table.name')}</TableHead>
                <TableHead>{t('review.table.category')}</TableHead>
                <TableHead>{t('review.table.jurisdiction')}</TableHead>
                <TableHead>{t('review.table.owner')}</TableHead>
                <TableHead>{t('review.table.createdAt')}</TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredReviews.map((review) => {
                const handleOnClick = () => {
                  navigate({
                    to: '/review/$uuid',
                    params: { uuid: review.uuid },
                  });
                };

                return (
                  <TableRow key={review.uuid} className='cursor-pointer'>
                    <TableCell className='break-all text-body-semibold' onClick={handleOnClick}>
                      {review.name ?? `${review.document.name}.${review.document.extension}`}
                    </TableCell>
                    <TableCell onClick={handleOnClick}>
                      {getDocumentCategoryLabel(review.document.category)}
                    </TableCell>
                    <TableCell onClick={handleOnClick}>
                      <div className='mr-2 inline-block'>
                        <JurisdictionIcon jurisdiction={review.jurisdiction} />
                      </div>
                      {getJurisdictionCountryLabel(review.jurisdiction)}
                    </TableCell>
                    <TableCell onClick={handleOnClick}>
                      <UserAvatar size='sm' {...review.owner} />
                    </TableCell>
                    <TableCell
                      onClick={handleOnClick}
                      title={formatLongDate(review.createdAt, i18n.language)}
                    >
                      {formatRelativeTime(review.createdAt, i18n.language)}
                    </TableCell>
                    <TableCell>
                      <ReviewsTableActionPopover review={review} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <DropZone
            onDropFiles={(files: File[]) => {
              trackEvent({
                action: 'drop',
                category: 'review',
              });
              uploadFiles(files);
            }}
            isSingle
            withBackground
            renderActions={() => (
              <div className='flex flex-col gap-3'>
                <UploadButton
                  onUpload={uploadFiles}
                  isSingle
                  renderLeft={<Icon name='upload-1' />}
                  onClick={() =>
                    trackEvent({
                      action: 'click',
                      category: 'review',
                      label: 'upload',
                    })
                  }
                >
                  {t('review.uploader.upload')}
                </UploadButton>
                <Button variant='soft' renderLeft={<Icon name='folder' />} asChild>
                  <Link to='/review/start'>{t('review.uploader.select')}</Link>
                </Button>
              </div>
            )}
          />
        )}
      </ContentDropZone>
      {hasReviews && (
        <ContentSidebar>
          <ReviewsInsights stats={stats} />
        </ContentSidebar>
      )}
    </div>
  );
}
