import { useTranslation } from 'react-i18next';

import { Button, IconButton } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { cn } from '@legalfly/ui/utils';

interface Props {
  snippetIds: string[];
  activeSnippetTextId: string;
  currentIndex: number;
  handlePrevious: () => void;
  handleNext: () => void;
  handleHighlightClick: () => void;
}

export const SnippetHighlightButtons = ({
  snippetIds,
  activeSnippetTextId,
  currentIndex,
  handlePrevious,
  handleNext,
  handleHighlightClick,
}: Props) => {
  const { t } = useTranslation('components');

  if (!snippetIds.length) {
    return null;
  }

  if (snippetIds.length === 1) {
    return (
      <Button
        size='sm'
        variant='soft'
        renderLeft={<Icon size='sm' name='file-search-3' className='text-icon-strongest' />}
        onClick={handleHighlightClick}
        className={cn('border', {
          'border-stroke-strongest': activeSnippetTextId === snippetIds[currentIndex],
        })}
      >
        {t('review.items.action.highlight')}
      </Button>
    );
  }

  return (
    <div
      className={cn('flex border border-transparent', {
        'border-stroke-strongest': activeSnippetTextId === snippetIds[currentIndex],
      })}
    >
      <IconButton
        className='border-e-0'
        size='sm'
        variant='soft'
        onClick={handlePrevious}
        name='chevron-left'
      />
      <Button
        size='sm'
        variant='soft'
        renderLeft={<Icon size='sm' name='search-md' className='text-icon-strongest' />}
        onClick={handleHighlightClick}
      >
        {`${currentIndex === -1 ? 1 : currentIndex + 1}/${snippetIds.length}`}
      </Button>
      <IconButton
        className='border-s-0'
        size='sm'
        variant='soft'
        onClick={handleNext}
        name='chevron-right'
      />
    </div>
  );
};
