import type { ReactNode } from 'react';
import { memo } from 'react';

import type { ApiConversation, ApiConversationMessage } from '@legalfly/api/conversations';
import { ConversationTypeEnum } from '@legalfly/api/conversations';
import { BaseCopilotMessage } from '@legalfly/components/copilot/BaseCopilotMessage';
import { CopyToClipboardButton } from '@legalfly/components/copyToClipboard/CopyToClipboardButton';
import type { TrackingEventMeta } from '@legalfly/reporting/tracking';
import { useWebSocketSubscriber } from '@legalfly/websockets';
import { CurrentUserAvatar } from 'components/users/CurrentUserAvatar';
import { useUpdateConversationMessage } from 'core/modules/conversations';

import { ConversationMessageDraft } from '../message/ConversationMessageDraft';
import { ConversationMessageMultipleChoice } from '../message/ConversationMessageMultipleChoice';
import { ConversationMessageSources } from '../message/ConversationMessageSources';
import { InlineSource } from '../message/InlineSource';

type Props = {
  conversationUuid: ApiConversation['uuid'];
  message: ApiConversationMessage;
  conversationType: ConversationTypeEnum;
  minHeight: string;
  analyticsMeta: TrackingEventMeta;
  onClickDocumentSnippet?: (textId: string, riskLevel: string) => void;
  renderActions?: (message: ApiConversationMessage) => ReactNode;
};

const CopilotMessage = ({
  conversationUuid,
  message,
  conversationType,
  minHeight,
  renderActions,
  onClickDocumentSnippet,
  analyticsMeta,
}: Props) => {
  const updateConversationMessage = useUpdateConversationMessage({ conversationType });

  useWebSocketSubscriber(`conversation_message_${message.uuid}`, (data) => {
    updateConversationMessage(conversationUuid, message.uuid, {
      assistantMessage: data.message,
      sources: data.sources,
      status: data.status,
    });
  });

  const renderElement = (element: Element, index: number) => {
    if (element.tagName.toLowerCase() === 'sup') {
      return (
        <InlineSource
          key={`inline-source-${index}`}
          question={message.question}
          element={element}
          onClickDocumentSnippet={onClickDocumentSnippet}
          sources={message.sources}
          analyticsMeta={analyticsMeta}
        />
      );
    }

    if (element.tagName.toLowerCase() === 'article' && element.classList.contains('draft')) {
      return <ConversationMessageDraft key={index} content={element.innerHTML} />;
    }

    if (element.tagName.toLowerCase() === 'ul' && element.classList.contains('multiple-choice')) {
      return <ConversationMessageMultipleChoice key={index} choices={element.children} />;
    }

    return null;
  };

  return (
    <BaseCopilotMessage key={message.uuid} minHeight={minHeight}>
      <BaseCopilotMessage.Question
        question={message.question}
        avatar={<CurrentUserAvatar size='sm' />}
      />
      <BaseCopilotMessage.Response
        assistantMessage={message.assistantMessage}
        renderElement={renderElement}
      >
        <BaseCopilotMessage.Actions>
          <div className='flex items-center gap-2'>
            {[ConversationTypeEnum.DISCOVERY, ConversationTypeEnum.AGENT].includes(
              conversationType,
            ) &&
              message.sources?.length > 0 && (
                <ConversationMessageSources
                  message={message}
                  sources={message.sources}
                  conversationType={conversationType}
                />
              )}
            {renderActions?.(message)}
          </div>
          <CopyToClipboardButton content={message.assistantMessage} />
        </BaseCopilotMessage.Actions>
      </BaseCopilotMessage.Response>
    </BaseCopilotMessage>
  );
};

export default memo(CopilotMessage);
