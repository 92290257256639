import { useCallback, useEffect, useState } from 'react';

interface Props {
  index: number;
  snippetIds: string[];
  onHighlight?: (snippetId: string, riskLevel: string) => void;
  riskLevel: string;
  activeSnippetTextId: string;
}

export const useSnippetHighlights = ({
  index,
  snippetIds,
  riskLevel,
  onHighlight,
  activeSnippetTextId,
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState(index);

  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);

  const handleHighlight = useCallback(
    (index: number, riskLevel: string) => {
      const snippetId = snippetIds[index];
      if (snippetId) {
        onHighlight?.(snippetId, riskLevel);
        setCurrentIndex(index);
      }
    },
    [snippetIds, onHighlight],
  );

  const handlePrevious = useCallback(() => {
    const newIndex =
      currentIndex === -1 ? 0 : (currentIndex - 1 + snippetIds.length) % snippetIds.length;
    handleHighlight(newIndex, riskLevel);
  }, [currentIndex, snippetIds.length, handleHighlight, riskLevel]);

  const handleNext = useCallback(() => {
    const newIndex = (currentIndex + 1) % snippetIds.length;
    handleHighlight(newIndex, riskLevel);
  }, [currentIndex, snippetIds.length, handleHighlight, riskLevel]);

  const handleHighlightClick = useCallback(() => {
    if (activeSnippetTextId === snippetIds[currentIndex]) {
      onHighlight?.('', '');
      setCurrentIndex(-1);
      return;
    }
    const nextIndex = currentIndex === -1 ? 0 : (currentIndex + 1) % snippetIds.length;
    handleHighlight(nextIndex, riskLevel);
  }, [currentIndex, snippetIds, handleHighlight, riskLevel, activeSnippetTextId, onHighlight]);

  const handleHighlightOnRedraftClick = useCallback(() => {
    const index = currentIndex === -1 ? 0 : snippetIds.indexOf(activeSnippetTextId);
    onHighlight?.(snippetIds[index], riskLevel);
  }, [currentIndex, snippetIds, riskLevel, activeSnippetTextId, onHighlight]);

  return {
    currentIndex,
    handlePrevious,
    handleNext,
    handleHighlightClick,
    handleHighlightOnRedraftClick,
  };
};
