import { useTranslation } from 'react-i18next';

import type { ApiNotification } from '@legalfly/api/notifications';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, IconButton } from '@legalfly/ui/button';
import { Spinner } from '@legalfly/ui/spinner';
import { Text } from '@legalfly/ui/text';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';
import { notificationToasts, useDeleteNotification } from 'core/modules/notifications';
import { useHandleNotificationClick } from 'core/modules/notifications/useHandleNotificationClick';

const Notification = ({
  notification,
  isLast,
}: {
  notification: ApiNotification;
  isLast: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const { deleteNotification } = useDeleteNotification();
  const { handleNotificationClick, isLoading } = useHandleNotificationClick();

  const handleDeleteNotification = () => {
    trackEvent({
      action: 'click',
      category: 'notifications',
      label: 'delete',
    });
    withToasts(deleteNotification(notification.uuid))(notificationToasts.deleteNotification());
  };

  return (
    <div
      key={notification.uuid}
      className={cn('group relative flex items-start justify-between p-3 hover:bg-fill-strong', {
        'border-b border-stroke-weaker': !isLast,
      })}
    >
      <Button
        variant='unstyled'
        onClick={() => handleNotificationClick(notification)}
        className='flex flex-col items-start'
        disabled={isLoading}
      >
        <Text
          variant='caption'
          className='text-content-body-strong'
          title={formatLongDate(notification.createdAt, i18n.language)}
        >
          {formatRelativeTime(notification.createdAt, i18n.language)}
        </Text>
        <Text className='mt-2 text-left'>
          {t(`config.notifications.${notification.type}.description`, {
            ...('entityName' in notification.metadata && {
              entityName: notification.metadata.entityName,
            }),
          })}
        </Text>
      </Button>
      {isLoading && <Spinner />}
      <IconButton
        className='opacity-0 transition-opacity group-hover:opacity-100'
        name='trash-2'
        variant='tertiary'
        onClick={handleDeleteNotification}
        disabled={isLoading}
      />
    </div>
  );
};

export default Notification;
