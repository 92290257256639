import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { FormItemLabel } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { TextareaAutoSize } from '@legalfly/ui/textarea';
import { cn } from '@legalfly/ui/utils';

import { PlaybookItemTooltip } from '../PlaybookItemTooltip';

export const PlaybookItemContextRedraftInstructionsField = ({
  className,
  ...rest
}: ComponentProps<typeof TextareaAutoSize>) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='mb-2 flex items-center gap-2'>
        <FormItemLabel
          label={t('form.redraftInstructions.label')}
          htmlFor='contextRedraftInstructions'
        />
        <PlaybookItemTooltip description={t('tooltips.playbooks.redraftInstructions')}>
          <Icon name='info-circle' className='-mt-3' />
        </PlaybookItemTooltip>
      </div>
      <TextareaAutoSize {...rest} className={cn('min-h-32', className)} />
    </>
  );
};
