import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';

import { FetcherError } from '@legalfly/api/fetcher';
import { reportError } from '@legalfly/reporting/tracing';
import { ErrorCard } from '@legalfly/ui/error';
import { cn } from '@legalfly/ui/utils';

interface Props {
  error: FetcherError | Error;
  className?: string;
  contentClassName?: string;
  showStackTrace?: boolean;
  cta?: {
    label: string;
    onClick: () => void;
  };
}

export const BaseDefaultError = ({
  error,
  showStackTrace = false,
  cta,
  className,
  contentClassName,
}: Props) => {
  const { t } = useTranslation('components');
  const queryErrorResetBoundary = useQueryErrorResetBoundary();

  useEffect(() => {
    // Reset the query error boundary
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  useEffect(() => {
    // fetch errors get automatically reported
    if (!(error instanceof FetcherError)) {
      reportError(error);
    }
  }, [error]);

  const getInfo = () => {
    if (!showStackTrace) {
      return {
        title: t('errors.default.title'),
        message: t('errors.default.message'),
      };
    }

    if (error instanceof FetcherError) {
      return {
        title: error.response.name,
        message: JSON.stringify({ url: error.request.url, ...error.response }, null, 2),
      };
    }

    return {
      title: error.name,
      message: JSON.stringify(
        {
          cause: error.cause,
          message: error.message,
          stack: error.stack,
        },
        null,
        2,
      ),
    };
  };

  const info = getInfo();

  return (
    <ErrorCard className={cn('h-dvh', className)}>
      <ErrorCard.Content className={contentClassName}>
        <ErrorCard.Title>{info.title}</ErrorCard.Title>
        <ErrorCard.Message>{info.message}</ErrorCard.Message>
        {cta && <ErrorCard.Action onClick={cta.onClick}>{cta.label}</ErrorCard.Action>}
      </ErrorCard.Content>
    </ErrorCard>
  );
};
