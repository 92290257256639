import { useTranslation } from 'react-i18next';

import type { ApiAgent } from '@legalfly/api/agents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { agentToasts, useDeleteAgent } from 'core/modules/agents';

interface Props {
  agent: ApiAgent;
  className?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const AgentsListMenu = ({ agent, className, isOpen, setIsOpen }: Props) => {
  const { t } = useTranslation();

  const { deleteAgent, isLoading: isLoadingDeleteAgent } = useDeleteAgent();

  const handleDeleteAgent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    trackEvent({
      action: 'click',
      category: 'agents',
      label: 'delete',
    });
    withToasts(deleteAgent({ uuid: agent.uuid }))(agentToasts.deleteAgent());
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          name='dots-horizontal'
          className={cn('rotate-90', isOpen && 'bg-fill-hover-weak', className)}
          variant='tertiary'
          size='sm'
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className='flex flex-col gap-2' align='end'>
        <DropdownMenuItem onClick={handleDeleteAgent}>
          {isLoadingDeleteAgent ? <Spinner /> : <Icon name='trash-2' className='text-icon-weak' />}
          {t('action.delete')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
