import type { ApiTemplate } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { withToasts } from '@legalfly/ui/toast';
import { EditableValueForm } from 'components/common/form/EditableValueForm';
import { draftingToasts, useUpdateTemplate } from 'core/modules/drafting';

interface Props {
  template: ApiTemplate;
}

export const EditableTemplateName = ({ template }: Props) => {
  const { updateTemplate } = useUpdateTemplate();

  const handleSubmit = async (title: string) => {
    trackEvent({
      action: 'click',
      category: 'templates',
      label: 'changeTitle',
    });

    await withToasts(updateTemplate({ uuid: template.uuid, body: { ...template, title } }))(
      draftingToasts.updateDraftingTemplate(),
    );
  };

  return <EditableValueForm value={template.title} onsubmit={handleSubmit} />;
};
