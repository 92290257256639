import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import type { ApiReview, ApiReviewItem } from '@legalfly/api/reviews';
import { withToasts } from '@legalfly/ui/toast';
import { useDownloadBlobWithProgress } from 'core/modules/documents/helpers/useDownloadBlobWithProgress';

const escapeAndQuote = (value: string | null | undefined): string => {
  if (!value) return '""';
  return `"${value?.replace(/"/g, '""')}"`;
};

const stripHtml = (value: string | null | undefined): string => {
  if (!value) return '';
  const sanitizedValue = DOMPurify.sanitize(value, {
    ALLOWED_TAGS: [],
    ALLOWED_ATTR: [],
  });
  return sanitizedValue;
};

const createCSV = (reviews: ApiReview[]): string => {
  const groupedReviews = reviews.reduce(
    (acc, review) => {
      review.items.forEach((item) => {
        if (!acc[item.groupName]) {
          acc[item.groupName] = {};
        }
        if (!acc[item.groupName][item.text]) {
          acc[item.groupName][item.text] = [];
        }
        acc[item.groupName][item.text].push(item.answer.text || '');
      });
      return acc;
    },
    {} as Record<string, Record<string, string[]>>,
  );

  let csv = 'Playbook,Item';
  reviews.forEach((review) => {
    csv += `,${review.document.name}`;
  });
  csv += '\r\n';

  Object.entries(groupedReviews).forEach(([groupName, questions]) => {
    let isFirstInGroup = true;
    Object.entries(questions).forEach(([question, answers]) => {
      if (isFirstInGroup) {
        csv += `${escapeAndQuote(groupName)},`;
        isFirstInGroup = false;
      } else {
        csv += ',';
      }
      csv += `${escapeAndQuote(question)}`;
      answers.forEach((answer) => {
        csv += `,${escapeAndQuote(stripHtml(answer))}`;
      });
      csv += '\r\n';
    });
    csv += '\r\n';
  });

  return csv;
};

export const useReviewToCSV = (reviews: ApiReview[], fileName: string) => {
  const { t } = useTranslation();

  const modifyReviewItems = (
    reviews: ApiReview[],
    modifications: ((item: ApiReviewItem) => ApiReviewItem)[],
  ): ApiReview[] => {
    return reviews.map((review) => ({
      ...review,
      items: review.items.map((item) => modifications.reduce((acc, mod) => mod(acc), item)),
    }));
  };

  const riskAssessmentModification = (item: ApiReviewItem): ApiReviewItem => {
    if (item.type === PlaybookItemTypeEnum.RISK_ASSESSMENT) {
      return {
        ...item,
        text: item.answer.title ?? item.text,
      };
    }
    return item;
  };

  const anomaliesModification = (item: ApiReviewItem): ApiReviewItem => {
    if (item.groupName === 'lf_anomaly') {
      return {
        ...item,
        groupName: t('review.new.anomalies'),
      };
    }
    return item;
  };

  const modifiedReviews = modifyReviewItems(reviews, [
    anomaliesModification,
    riskAssessmentModification,
  ]);

  const blob = useMemo(
    () => new Blob([createCSV(modifiedReviews)], { type: 'text/csv' }),
    [modifiedReviews],
  );

  const { handleDownload, isDownloading } = useDownloadBlobWithProgress({
    blob,
    fileName,
  });

  const exportReviewAsCSV = async () => {
    await withToasts(handleDownload())({
      errorToast: {
        title: t('config.notifications.EXPORT_REVIEW_AS_CSV.error.title'),
        description: t('config.notifications.EXPORT_REVIEW_AS_CSV.error.description'),
        variant: 'danger',
      },
    });
  };

  return { isDownloading, exportReviewAsCSV };
};
