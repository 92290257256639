import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiUserPermission } from '@legalfly/api/collaboration';
import type { PermissionEntityType } from '@legalfly/api/core';
import { useCopyToClipboard } from '@legalfly/components/copyToClipboard/useCopyToClipboard';
import { Button } from '@legalfly/ui/button';
import { DialogBody, DialogFooter } from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { Switch } from '@legalfly/ui/switch';
import { Text } from '@legalfly/ui/text';
import { withToasts } from '@legalfly/ui/toast';
import { usePermissions, useSyncPermissions } from 'core/modules/collaboration';
import { collaborationToasts } from 'core/modules/collaboration/toasts';
import { useCurrentUser } from 'core/modules/users';

import { generatePermissionsBody } from './utils/permissionMappers';
import { SearchUserInput } from './SearchUserInput';
import { ShareDialogUserList } from './ShareDialogUserList';

export const ShareDialogBody = ({
  entityType,
  entityUuid,
  closeDialog,
}: {
  entityType: PermissionEntityType;
  entityUuid: string;
  closeDialog: VoidFunction;
}) => {
  const { t } = useTranslation();
  const copyToClipboard = useCopyToClipboard({
    successToast: {
      title: t('dialog.share.copyLink.toast.title'),
      description: t('dialog.share.copyLink.toast.description'),
    },
  });

  const handleCopyLink = () => {
    copyToClipboard(window.location.href);
  };

  const { currentUser } = useCurrentUser();
  const { syncPermissions, isLoading: isSyncingPermissions } = useSyncPermissions({
    entityType,
    entityUuid,
  });
  const { permissions } = usePermissions({ entityType, entityUuid });

  const [newPermissions, setNewPermissions] = useState(permissions);
  const isSharedWithOrganization = newPermissions.organizations.some(
    ({ organization }) => organization.uuid === currentUser.organization.uuid,
  );

  const handleAddUser = (userPermission: ApiUserPermission) => {
    setNewPermissions((prev) => {
      const isUserAlreadyAdded = prev.users.some(
        ({ user }) => user.uuid === userPermission.user.uuid,
      );
      if (isUserAlreadyAdded) return prev;

      return {
        ...prev,
        users: [...prev.users, userPermission],
      };
    });
  };

  const handleRemoveUser = (userPermission: ApiUserPermission['user']) => {
    setNewPermissions((prev) => ({
      ...prev,
      users: prev.users.filter(({ user }) => user.uuid !== userPermission.uuid),
    }));
  };

  const handleToggleOrganization = (checked: boolean) => {
    setNewPermissions((prev) => ({
      ...prev,
      organizations: checked
        ? [
            {
              organization: {
                uuid: currentUser.organization.uuid,
                name: currentUser.organization.name,
              },
              permissions: ['view'],
            },
          ]
        : [],
    }));
  };

  const handleDone = async () => {
    await withToasts(
      syncPermissions({
        entityType,
        entityUuid,
        body: generatePermissionsBody(newPermissions),
      }),
    )(collaborationToasts.syncPermissions());
    closeDialog();
  };

  return (
    <>
      <DialogBody className='mb-6 flex flex-col'>
        <div className='mb-6 flex items-center gap-2'>
          <Switch
            id='share-org'
            checked={isSharedWithOrganization}
            onCheckedChange={handleToggleOrganization}
          />
          <Text as='label' htmlFor='share-org' className='text-content-body-strong'>
            {t('dialog.share.organization')}
          </Text>
        </div>
        {!isSharedWithOrganization && (
          <>
            <SearchUserInput onChange={handleAddUser} />
            <ShareDialogUserList users={newPermissions.users} onRemoveUser={handleRemoveUser} />
          </>
        )}
      </DialogBody>
      <DialogFooter className='justify-between'>
        <Button variant='soft' renderLeft={<Icon name='link-3' />} onClick={handleCopyLink}>
          {t('dialog.share.copyLink.button')}
        </Button>
        <Button onClick={handleDone} isLoading={isSyncingPermissions}>
          {t('action.share')}
        </Button>
      </DialogFooter>
    </>
  );
};
