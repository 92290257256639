import { useEffect } from 'react';

import { createAgentsApi } from '@legalfly/api/agents';
import { createAnonymizationApi } from '@legalfly/api/anonymization';
import { createCollaborationApi } from '@legalfly/api/collaboration';
import { createCommentsApi } from '@legalfly/api/comments';
import { createConfigApi } from '@legalfly/api/config';
import {
  createConversationsAgentApi,
  createConversationsDiscoveryApi,
  createConversationsDraftApi,
  createConversationsReviewApi,
} from '@legalfly/api/conversations';
import { createDocumentsApi } from '@legalfly/api/documents';
import { createDraftingApi } from '@legalfly/api/drafting';
import { createFavoritesApi } from '@legalfly/api/favorites';
import { createFeedbackApi } from '@legalfly/api/feedback';
import { Fetcher } from '@legalfly/api/fetcher';
import { createMultiReviewsApi } from '@legalfly/api/multi-reviews';
import { createNotificationsApi } from '@legalfly/api/notifications';
import { createPlaybooksApi } from '@legalfly/api/playbooks';
import { createReviewsApi } from '@legalfly/api/reviews';
import { createSettingsApi } from '@legalfly/api/settings';
import { createStatisticsApi } from '@legalfly/api/statistics';
import { createUsersApi } from '@legalfly/api/users';
import { createWebSocketApi } from '@legalfly/api/websocket';
import { legalFlyConfig } from '@legalfly/config';

import { useAuth } from './auth';

export const fetcher = new Fetcher(
  `${legalFlyConfig.api.url}${legalFlyConfig.api.port === 80 ? '' : `:${legalFlyConfig.api.port}`}`,
  {
    afterResponse: () => {
      // console.log('Api request', request.url, 'responded with status', response.status);
    },
    beforeError: (error) => {
      console.log(error.response);
    },
  },
);

export const useSetFetcherToken = () => {
  const { getAccessTokenSilently } = useAuth();

  useEffect(() => {
    const setAuthHeader = async (request: Request) => {
      const token = await getAccessTokenSilently();
      request.headers.set('Authorization', `Bearer ${token}`);
      return request;
    };

    fetcher.hooks.beforeRequest = setAuthHeader;
  }, [getAccessTokenSilently]);
};

export const documentsApi = createDocumentsApi(fetcher);
export const usersApi = createUsersApi(fetcher);
export const draftConversationsApi = createConversationsDraftApi(fetcher);
export const reviewConversationsApi = createConversationsReviewApi(fetcher);
export const discoveryConversationsApi = createConversationsDiscoveryApi(fetcher);
export const agentConversationsApi = createConversationsAgentApi(fetcher);
export const websocketApi = createWebSocketApi(fetcher);
export const notificationsApi = createNotificationsApi(fetcher);
export const configApi = createConfigApi(fetcher);
export const playbooksApi = createPlaybooksApi(fetcher);
export const reviewsApi = createReviewsApi(fetcher);
export const draftingApi = createDraftingApi(fetcher);
export const multiReviewsApi = createMultiReviewsApi(fetcher);
export const settingsApi = createSettingsApi(fetcher);
export const feedbackApi = createFeedbackApi(fetcher);
export const anonymizationApi = createAnonymizationApi(fetcher);
export const statisticsApi = createStatisticsApi(fetcher);
export const agentsApi = createAgentsApi(fetcher);
export const favoritesApi = createFavoritesApi(fetcher);
export const collaborationApi = createCollaborationApi(fetcher);
export const commentsApi = createCommentsApi(fetcher);
