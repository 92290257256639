import { queryOptions } from '@tanstack/react-query';

import type { CollaborationApi } from '@legalfly/api/collaboration';

export const collaborationQueryOptions = ({
  collaborationApi,
}: {
  collaborationApi: CollaborationApi;
}) => ({
  permissions: (params: Parameters<typeof collaborationApi.getPermissions>[0]) =>
    queryOptions({
      queryKey: ['collaboration', 'permissions', params],
      queryFn: () => collaborationApi.getPermissions(params),
    }),
});
