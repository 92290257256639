import i18n from 'i18next';

import type { FetcherError } from '@legalfly/api/fetcher';
import { createToasts } from '@legalfly/ui/toast';

export const documentsToasts = createToasts({
  createFile: (filename: File['name']) => ({
    errorToast: {
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createFile.description', { filename }),
    },
  }),
  createFolder: (folderName: string) => ({
    errorToast: {
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createFolder.description', { folderName }),
    },
  }),
  createDocumentEntity: () => ({
    errorToast: {
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createDocumentEntity.description'),
    },
  }),
  updateFolderEntity: () => ({
    errorToast: {
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateFolderEntity.description'),
    },
  }),
  updateDocumentEntity: () => ({
    errorToast: {
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateDocumentEntity.description'),
    },
  }),
  deleteDocumentEntity: () => ({
    errorToast: {
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteDocumentEntity.description'),
    },
  }),
  dropDocuments: () => ({
    errorToast: {
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.dropDocument.description'),
    },
  }),
  deleteFile: () => ({
    errorToast: (err: FetcherError) => {
      const title = i18n.t('toast.error.title');
      switch (err.response.code) {
        case 'FILE_HAS_REVIEW_RELATIONS':
          return {
            title,
            description: i18n.t('toast.error.deleteFile.hasReviewRelations.description'),
          };
        case 'FILE_HAS_MULTI_REVIEW_RELATIONS':
          return {
            title,
            description: i18n.t('toast.error.deleteFile.hasMultiReviewRelations.description'),
          };
        case 'FILE_HAS_CONVERSATION_RELATIONS':
          return {
            title,
            description: i18n.t('toast.error.deleteFile.hasConversationRelations.description'),
          };
        default:
          return {
            title,
            description: i18n.t('toast.error.deleteFile.description'),
          };
      }
    },
  }),
  deleteFolder: () => ({
    errorToast: (err: FetcherError) => {
      const title = i18n.t('toast.error.title');
      switch (err.response.code) {
        case 'FILE_HAS_REVIEW_RELATIONS':
          return {
            title,
            description: i18n.t('toast.error.deleteFolder.hasReviewRelations.description'),
          };
        case 'FILE_HAS_CONVERSATION_RELATIONS':
          return {
            title,
            description: i18n.t('toast.error.deleteFolder.hasConversationRelations.description'),
          };
        case 'FILE_HAS_MULTI_REVIEW_RELATIONS':
          return {
            title,
            description: i18n.t('toast.error.deleteFolder.hasMultiReviewRelations.description'),
          };
        default:
          return {
            title,
            description: i18n.t('toast.error.deleteFolder.description'),
          };
      }
    },
  }),
  uploadFile: (filename: File['name']) => ({
    errorToast: (err: FetcherError) => {
      const title = i18n.t('toast.error.title');
      let description: string;

      switch (err.response.code) {
        case 'DOCUMENT_UPLOAD_FAILED':
          description = i18n.t('toast.error.uploadFile.documentUploadFailed.description');
          break;
        case 'FILE_SIZE_EXCEEDS_LIMIT':
          description = i18n.t('toast.error.uploadFile.fileSizeExceedsLimit.description', {
            maxSizeMB: err.response.message,
          });
          break;
        case 'FILE_CREATION_FAILED':
          description = i18n.t('toast.error.uploadFile.fileCreationFailed.description');
          break;
        default:
          description = i18n.t('toast.error.uploadFile.description', { filename });
      }

      return { title, description };
    },
  }),
  createAnonymization: () => ({
    errorToast: {
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createAnonymization.description'),
    },
  }),
  createDocumentsZip: () => ({
    initialToast: {
      title: i18n.t('config.notifications.ZIP_CREATION_STARTED.title'),
      description: i18n.t('config.notifications.ZIP_CREATION_STARTED.description'),
    },
  }),
});
