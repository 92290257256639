import type { ApiUserStatistic } from '@legalfly/api/users';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

interface Props {
  statistics?: ApiUserStatistic;
  className?: string;
}

export const UserStatistics = ({ statistics, className }: Props) => {
  return (
    <div className={cn('flex items-center gap-4', className)}>
      <div className={cn('flex items-center gap-1', { 'opacity-25': !statistics?.totalReviews })}>
        <Icon name='check-done' />
        <Text variant='bodyLight'>{statistics?.totalReviews ?? 0}</Text>
      </div>
      <div
        className={cn('flex items-center gap-1', {
          'opacity-25': !statistics?.totalMultiReviews,
        })}
      >
        <Icon name='table' />
        <Text variant='bodyLight'>{statistics?.totalMultiReviews ?? 0}</Text>
      </div>
      <div className={cn('flex items-center gap-1', { 'opacity-25': !statistics?.totalDrafts })}>
        <Icon name='edit' />
        <Text variant='bodyLight'>{statistics?.totalDrafts ?? 0}</Text>
      </div>
      <div className={cn('flex items-center gap-1', { 'opacity-25': !statistics?.totalDiscovery })}>
        <Icon name='file-search-3' />
        <Text variant='bodyLight'>{statistics?.totalDiscovery ?? 0}</Text>
      </div>
      <div
        className={cn('flex items-center gap-1', {
          'opacity-25': !statistics?.totalAnonymizations,
        })}
      >
        <Icon name='shield-tick' />
        <Text variant='bodyLight'>{statistics?.totalAnonymizations ?? 0}</Text>
      </div>
    </div>
  );
};
