import type { ReactNode } from 'react';

import type { ApiConversation, ConversationTypeEnum } from '@legalfly/api/conversations';
import { BaseCopilotContent } from '@legalfly/components/copilot/BaseCopilotContent';
import { BaseCopilotFooter } from '@legalfly/components/copilot/BaseCopilotFooter';
import { cn } from '@legalfly/ui/utils';

import { CopilotActions } from './CopilotActions';
import { CopilotBackground } from './CopilotBackground';
import CopilotEmptyState from './CopilotEmptyState';
import { CopilotInput } from './CopilotInput';
import { CopilotMessages } from './CopilotMessages';
import { CopilotProvider } from './CopilotProvider';
import { CopilotScrollContainer } from './CopilotScrollContainer';
import { CopilotStopRespondingButton } from './CopilotStopRespondingButton';

interface Props {
  conversationUuid?: ApiConversation['uuid'];
  conversationHasMessages: boolean;
  onSendMessage: (message: string) => void;
  conversationType: ConversationTypeEnum;
  className?: string;
  children: ReactNode;
}

const Copilot = ({
  conversationUuid,
  conversationHasMessages,
  onSendMessage,
  conversationType,
  className,
  children,
}: Props) => {
  return (
    <CopilotProvider
      conversationUuid={conversationUuid}
      onSendMessage={onSendMessage}
      conversationType={conversationType}
    >
      <div
        className={cn(
          'flex h-full w-full flex-col bg-fill-maximal @container',
          !conversationHasMessages && 'justify-center',
          className,
        )}
      >
        {children}
      </div>
    </CopilotProvider>
  );
};

Copilot.Content = BaseCopilotContent;
Copilot.Footer = BaseCopilotFooter;
Copilot.EmptyState = CopilotEmptyState;
Copilot.Messages = CopilotMessages;
Copilot.Actions = CopilotActions;
Copilot.Input = CopilotInput;
Copilot.StopResponding = CopilotStopRespondingButton;
Copilot.ScrollContainer = CopilotScrollContainer;
Copilot.Background = CopilotBackground;

export { Copilot };
