import type { ReactNode } from 'react';
import { useState } from 'react';
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import type { TableDataRow } from './DataTable';
import { DraggableOverlay } from './DraggableOverlay';

interface Props {
  children: ReactNode;
  onDropDocuments?: (p: { sourceUuids: string[]; targetUuid: string }) => void;
  selectedRows: TableDataRow[];
  rows: TableDataRow[];
}

export const DndDataTable = ({ onDropDocuments, selectedRows, rows, children }: Props) => {
  const [activeId, setActiveId] = useState('');

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor),
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(String(event.active.id));
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (event.over?.data.current?.isDroppable && event.active.id !== event.over.id) {
      const selectedIds = selectedRows.map((r) => r.uuid);
      const activeIsSelected = selectedIds.includes(String(event.active.id));
      const sourceUuids = activeIsSelected ? selectedIds : [String(event.active.id)];

      onDropDocuments?.({
        sourceUuids,
        targetUuid: String(event.over.id),
      });
    }
  };

  if (!onDropDocuments) {
    return children;
  }

  return (
    <DndContext
      collisionDetection={pointerWithin}
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {children}
      <DraggableOverlay
        activeRow={rows.find((r) => r.uuid === activeId)}
        selectedRows={selectedRows}
      />
    </DndContext>
  );
};
