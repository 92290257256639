export enum ReviewStatus {
  RUNNING = 'running',
  IN_PROGRESS = 'in progress',
  ANOMALIES = 'anomalies',
  COMPLETED = 'completed',
}

export enum ReviewPlaybookItemStatus {
  CREATED = 'created',
  IN_PROGRESS = 'in progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export const SortByReviewFields = [
  'createdAt',
  'status',
  'document.category',
  'jurisdiction',
  'name',
] as const;

export type ReviewSortField = (typeof SortByReviewFields)[number];
