import type { ComponentProps, CSSProperties } from 'react';

import { cn } from '@legalfly/ui/utils';

import { DropZone } from '../upload/DropZone';

interface Props extends ComponentProps<typeof DropZone> {
  parentClassName?: string;
  style?: CSSProperties;
}

export const ContentDropZone = ({
  parentClassName,
  className,
  disabled,
  style,
  ...props
}: Props) => {
  return (
    <div
      className={cn('flex h-content w-auto bg-fill-maximal px-6 py-5', parentClassName)}
      style={style}
    >
      <DropZone
        className={cn('h-full w-full px-6 py-5', disabled && 'flex flex-col gap-4', className)}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};
