import type { ReactNode } from 'react';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import { type ApiReviewItem, ReviewPlaybookItemStatus } from '@legalfly/api/reviews';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';

import { useTextToParagraphedText } from './hooks/useTextToParagraphedText';
import { useSnippetHighlights } from '../documents/hooks/useSnippetHighlights';
import { PlaybookItemMultipleChoice } from '../playbooks/PlaybookItemMultipleChoice';
import { PlaybookItemYesNo } from '../playbooks/PlaybookItemYesNo';
import { RISK_RELATED_PLAYBOOK_ITEM_TYPES } from './helpers';
import { ReviewRiskGlow } from './ReviewRiskGlow';
import { SnippetHighlightBackground } from './SnippetHighlightBackground';
import { SnippetHighlightButtons } from './SnippetHighlightButtons';

interface Props {
  item: ApiReviewItem;
  activeSnippetTextId: string;
  isHighlighted?: boolean;
  onClickExplain?: (item: ApiReviewItem) => void;
  onClickRedraft?: (item: ApiReviewItem) => void;
  onClickHighlight?: (snippetId: string, riskLevel: string) => void;
  renderItemActions?: (item: ApiReviewItem) => ReactNode;
}

const ReviewDetailItem = ({
  item,
  renderItemActions,
  onClickExplain,
  onClickRedraft,
  onClickHighlight,
  activeSnippetTextId,
  isHighlighted,
}: Props) => {
  const { t } = useTranslation('components');

  const snippetIds = useMemo(() => item.answer.snippetIds ?? [], [item.answer.snippetIds]);

  const formattedAnswerParagraphs = useTextToParagraphedText({
    text: item.answer?.text,
  });

  const [startIndex, setStartIndex] = useState(-1);
  const highlightAnswer = isHighlighted || snippetIds.includes(activeSnippetTextId);

  const handleHighlight = useCallback(
    (snippetId: string, riskLevel: string) => {
      onClickHighlight?.(snippetId, riskLevel);
      setStartIndex(snippetIds.indexOf(snippetId));
    },
    [onClickHighlight, snippetIds],
  );

  const snippetHighlights = useSnippetHighlights({
    index: startIndex,
    snippetIds,
    riskLevel: item.answer.level || 'neutral',
    activeSnippetTextId,
    onHighlight: handleHighlight,
  });

  if (item.status !== ReviewPlaybookItemStatus.COMPLETED) return null;

  return (
    <div className='group relative mb-3 grid overflow-hidden border border-stroke-frail bg-fill-maximal p-6 last:mb-0'>
      <ReviewRiskGlow item={item} highlight={highlightAnswer} className='absolute right-8 top-8' />
      <div className='relative z-10'>
        <SnippetHighlightBackground
          snippetIds={snippetIds}
          onHighlight={handleHighlight}
          riskLevel={
            item.type === PlaybookItemTypeEnum.YES_NO
              ? item.answer.isYes
                ? 'low'
                : 'high'
              : (item.answer.level ?? 'neutral')
          }
          startIndex={startIndex}
          activeSnippetTextId={activeSnippetTextId}
        >
          <div className='flex items-baseline gap-2'>
            <Text variant='bodyLargeSemiBold' className='mb-3 pe-8'>
              {RISK_RELATED_PLAYBOOK_ITEM_TYPES.includes(item.type)
                ? item?.answer?.title || item.text
                : item.text}
            </Text>
          </div>
          <div className='mb-5 text-content-body-strong'>
            {(() => {
              if (item.type === PlaybookItemTypeEnum.YES_NO) {
                return <PlaybookItemYesNo item={item} />;
              }
              if (item.type === PlaybookItemTypeEnum.MULTIPLE_CHOICE) {
                return <PlaybookItemMultipleChoice item={item} />;
              }

              return formattedAnswerParagraphs.map((paragraph, index) => (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                  className='prose mb-4 text-content-body-strong last:mb-0'
                />
              ));
            })()}
          </div>
        </SnippetHighlightBackground>
      </div>
      <div className='relative z-10 flex flex-wrap gap-4'>
        {onClickHighlight && (
          <SnippetHighlightButtons
            snippetIds={snippetIds}
            activeSnippetTextId={activeSnippetTextId}
            {...snippetHighlights}
          />
        )}
        {onClickExplain && (
          <Button
            variant='soft'
            size='sm'
            renderLeft={<Icon size='sm' name='paragraph-wrap' className='text-icon-strongest' />}
            onClick={() => onClickExplain(item)}
          >
            {t('review.items.action.explain')}
          </Button>
        )}
        {onClickRedraft && (
          <Button
            variant='soft'
            size='sm'
            renderLeft={<Icon size='sm' name='edit-4' className='text-icon-strongest' />}
            onClick={() => {
              snippetHighlights.handleHighlightOnRedraftClick();
              onClickRedraft(item);
            }}
          >
            {t(`review.items.action.${snippetIds.length > 0 ? 'redraft' : 'draft'}`)}
          </Button>
        )}
        {renderItemActions?.(item)}
      </div>
    </div>
  );
};

export default memo(ReviewDetailItem);
