import type {
  ApiOrganizationPermission,
  ApiPermission,
  ApiPermissionBodyEntry,
  ApiTeamPermission,
  ApiUserPermission,
} from '@legalfly/api/collaboration';

interface PermissionMappers {
  user: (permission: ApiUserPermission) => ApiPermissionBodyEntry;
  team: (permission: ApiTeamPermission) => ApiPermissionBodyEntry;
  organization: (permission: ApiOrganizationPermission) => ApiPermissionBodyEntry;
}

export const permissionMappers: PermissionMappers = {
  user: (permission) => ({
    type: 'user',
    uuid: permission.user.uuid,
    permissions: permission.permissions,
  }),
  team: (permission) => ({
    type: 'team',
    uuid: permission.team.uuid,
    permissions: permission.permissions,
  }),
  organization: (permission) => ({
    type: 'organization',
    uuid: permission.organization.uuid,
    permissions: permission.permissions,
  }),
};

export function generatePermissionsBody({ users, teams, organizations }: ApiPermission) {
  return [
    ...users.map(permissionMappers.user),
    ...teams.map(permissionMappers.team),
    ...organizations.map(permissionMappers.organization),
  ];
}
