import type { PropsWithChildren, ReactNode } from 'react';
import React, { useCallback, useMemo } from 'react';
import DOMPurify from 'dompurify';

import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

import { BaseCopilotSkeleton } from './BaseCopilotSkeleton';

type Props = React.HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    minHeight: string;
  }>;

export const BaseCopilotMessage = ({ minHeight, children, className, ...props }: Props) => (
  <div
    className={cn('copilot-message mx-auto mb-5 flex w-full flex-col last:mb-0', className)}
    style={{ minHeight }}
    {...props}
  >
    {children}
  </div>
);

interface CopilotMessageQuestionProps extends React.HTMLAttributes<HTMLDivElement> {
  question: string;
  avatar: ReactNode;
}

const CopilotMessageQuestion = ({
  question,
  avatar,
  className,
  ...props
}: CopilotMessageQuestionProps) => {
  const purifiedQuestion = DOMPurify.sanitize(question, {
    ALLOWED_TAGS: ['span'],
    ALLOWED_ATTR: ['hidden'],
  });

  return (
    <div
      className={cn(
        'mb-3 ms-auto flex w-fit items-center gap-4 whitespace-pre-line border border-stroke-weaker bg-fill-strongest px-5 py-3',
        className,
      )}
      {...props}
    >
      <span dangerouslySetInnerHTML={{ __html: purifiedQuestion }} />
      {avatar}
    </div>
  );
};

type CopilotMessageActionsProps = PropsWithChildren & React.HTMLAttributes<HTMLDivElement>;

const CopilotMessageActions = ({ children, className, ...props }: CopilotMessageActionsProps) => (
  <div className={cn('flex items-center justify-between px-4 py-3', className)} {...props}>
    {children}
  </div>
);

type CopilotMessageResponseProps = PropsWithChildren<{
  assistantMessage: string;
  renderHeader?: () => ReactNode;
  renderElement?: (element: Element, index: number) => ReactNode;
}>;

const CopilotMessageResponse = ({
  assistantMessage,
  renderElement,
  renderHeader,
  children,
}: CopilotMessageResponseProps) => {
  const render = useCallback(
    (element: Element, index: number): React.ReactNode => {
      const renderedElement = renderElement?.(element, index);
      if (renderedElement) return renderedElement;

      const children = Array.from(element.childNodes).map((child, childIndex) => {
        if (child.nodeType === Node.ELEMENT_NODE) return render(child as Element, childIndex);
        if (child.nodeType === Node.TEXT_NODE) return child.textContent;
        return null;
      });

      return React.createElement(
        element.tagName.toLowerCase(),
        {
          key: `${element.tagName.toLowerCase()}-${index}`,
          className: element.className || undefined,
        },
        children.length > 0 ? children : undefined,
      );
    },
    [renderElement],
  );

  const tags = useMemo(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(assistantMessage, 'text/html');

    if (doc.body.children.length === 0 && doc.body.textContent) {
      return [doc.body.textContent];
    }

    return Array.from(doc.body.childNodes).map((node, index) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return (
          <div key={index} className='prose w-full text-body dark:prose-invert'>
            {node.textContent}
          </div>
        );
      }

      const el = node as HTMLElement;

      if (el.tagName.toLowerCase() === 'p' && el.querySelector('var')) {
        const varElement = el.querySelector('var');
        if (varElement) {
          return (
            <Text key={index} className='flex items-center'>
              <Icon name='list' className='mr-2' />
              <var className='not-italic'>{varElement.textContent}</var>
            </Text>
          );
        }
      }

      return (
        <div key={index} className='prose w-full text-body dark:prose-invert'>
          {render(el, index)}
        </div>
      );
    });
  }, [assistantMessage, render]);

  if (tags.length === 0) {
    return (
      <div className='border border-stroke-weaker bg-fill-weak p-7'>
        <BaseCopilotSkeleton />
      </div>
    );
  }

  return (
    <div className='border border-stroke-weaker'>
      <div className='flex flex-col gap-4 border-b border-stroke-weaker bg-fill-strong p-7'>
        {renderHeader?.()}
        {tags.map((tag, index) =>
          typeof tag === 'string' ? (
            <div
              key={index}
              className='prose w-full text-body dark:prose-invert'
              dangerouslySetInnerHTML={{ __html: tag }}
            />
          ) : (
            tag
          ),
        )}
      </div>
      {children}
    </div>
  );
};

BaseCopilotMessage.Question = CopilotMessageQuestion;
BaseCopilotMessage.Response = CopilotMessageResponse;
BaseCopilotMessage.Actions = CopilotMessageActions;
