import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { useSelectedDocuments } from '@legalfly/components/documentPicker/SelectedDocumentsProvider';
import { withSelectedDocuments } from '@legalfly/components/documentPicker/withSelectedDocuments';
import {
  DocumentsUploadProgress,
  useDocumentsUploadProgress,
} from '@legalfly/components/documentsUploadProgress';
import { Button } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { cn } from '@legalfly/ui/utils';
import { EditableAnonymizationName } from 'components/anonymization/detail/EditableAnonymizationName';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { DocumentsList } from 'components/documents/DocumentsList';
import { useDocumentActions } from 'components/documents/useDocumentActions';
import {
  anonymizationQueryOptions,
  ListenToAnonymizationDocumentsStatus,
  useAnonymization,
} from 'core/modules/anonymization';

export const Route = createFileRoute('/_auth/_layout/anonymisation/$uuid')({
  component: withSelectedDocuments(AnonymisationRoute),
  loader({ context, params }) {
    return context.queryClient.ensureQueryData(
      anonymizationQueryOptions.anonymization({ uuid: params.uuid }),
    );
  },
});

function AnonymisationRoute() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid } = Route.useParams();

  const { anonymization } = useAnonymization({ uuid });

  const handleBackPress = () => {
    navigate({
      to: '/anonymisation',
    });
  };

  const { selectedDocuments } = useSelectedDocuments();

  const documentUploadProgress = useDocumentsUploadProgress(
    anonymization.documents,
    anonymization.documents.length,
  );

  const { handleCreateDocumentsZip, handleCreateAnonymizedDocumentsZip } = useDocumentActions(
    anonymization.documents,
  );

  useEffect(() => {
    if (selectedDocuments.length) {
      navigate({
        to: '/anonymisation/file/$uuid',
        params: { uuid: selectedDocuments[0].uuid },
      });
    }
  }, [selectedDocuments, navigate]);

  return (
    <div className='flex flex-col gap-3'>
      <Content
        innerClassName='p-0'
        className={cn(
          !documentUploadProgress.isUploading ? 'h-content' : 'h-content-with-progress',
          'flex-none',
        )}
      >
        <ContentHeader>
          <ContentHeader.Title>{t('anonymisation.title')}</ContentHeader.Title>
          <ContentHeader.SubTitle asChild>
            <EditableAnonymizationName anonymization={anonymization} />
          </ContentHeader.SubTitle>
          <ContentHeader.Actions>
            <Button onClick={handleBackPress} variant='soft'>
              {t('action.back')}
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant='soft' size='sm' renderRight={<Icon name='chevron-down' />}>
                  {t('documents.actionBar.download')}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align='start'>
                <DropdownMenuItem
                  onSelect={() => handleCreateDocumentsZip({ category: 'anonymisation' })}
                >
                  <Icon name='download-1' />
                  {t('action.downloadZip')}
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => handleCreateAnonymizedDocumentsZip({ category: 'anonymisation' })}
                >
                  <Icon name='download-1' />
                  {t('action.downloadAnonymizedZip')}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </ContentHeader.Actions>
        </ContentHeader>
        <ListenToAnonymizationDocumentsStatus anonymization={anonymization} />
        <DocumentsList
          documents={anonymization.documents}
          allowDelete={false}
          allowSelection={false}
          allowMultiple={false}
          allowFavorite={false}
        />
      </Content>
      <DocumentsUploadProgress {...documentUploadProgress} />
    </div>
  );
}
