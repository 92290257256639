export const LLMModels = [
  'default',
  'gpt-4o',
  'gpt-4o-mini',
  'o1-preview',
  'claude-3-5-sonnet-20241022',
  'gemini-1.5-flash',
  'gemini-1.5-pro',
] as const;

export const defaultLLMModel: LLMModelType = 'claude-3-5-sonnet-20241022';

export type LLMModelType = (typeof LLMModels)[number];
