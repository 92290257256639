import type { Modifier } from '@dnd-kit/core';
import { DragOverlay } from '@dnd-kit/core';
import { getEventCoordinates } from '@dnd-kit/utilities';

import { cn } from 'utils';

import type { TableDataRow } from './DataTable';

const snapCursorTopLeft: Modifier = (_ref) => {
  const { activatorEvent, draggingNodeRect, transform } = _ref;

  if (draggingNodeRect && activatorEvent) {
    const activatorCoordinates = getEventCoordinates(activatorEvent);

    if (!activatorCoordinates) {
      return transform;
    }

    const offsetX = activatorCoordinates.x - draggingNodeRect.left;
    const offsetY = activatorCoordinates.y - draggingNodeRect.top;
    return { ...transform, x: transform.x + offsetX, y: transform.y + offsetY };
  }

  return transform;
};

const modifiers = [snapCursorTopLeft];

interface Props {
  activeRow: TableDataRow | undefined;
  selectedRows: TableDataRow[];
}

export const DraggableOverlay = ({ activeRow, selectedRows }: Props) => {
  if (!activeRow) return null;

  const activeIsSelected = selectedRows.map((r) => r.uuid).includes(activeRow.uuid);
  const rows = activeIsSelected
    ? [activeRow, ...selectedRows.filter((r) => r.uuid !== activeRow.uuid)]
    : [activeRow];

  return (
    <DragOverlay modifiers={modifiers} dropAnimation={null}>
      <div className='relative flex w-fit flex-col'>
        {rows.length > 1 && (
          <span className='absolute -right-3 -top-3 z-30 flex h-6 min-h-6 w-6 min-w-6 items-center justify-center rounded-full bg-fill-lemon text-content-body-constant-dark'>
            {rows.length}
          </span>
        )}
        {rows.map((row, index) => (
          <div
            key={row.uuid}
            className={cn(
              'relative truncate border border-stroke-weak bg-fill-hover-weak px-5 py-3',
            )}
            style={{
              top: index === 0 ? '0' : `-${index * 42}px`,
              left: `${index * 4}px`,
              zIndex: 20 - index,
            }}
          >
            {row.name}
          </div>
        ))}
      </div>
    </DragOverlay>
  );
};
