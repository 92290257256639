import { useTranslation } from 'react-i18next';

import type { ApiReviewItem } from '@legalfly/api/reviews';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';

interface Props {
  item: ApiReviewItem;
}

export const PlaybookItemYesNo = ({ item }: Props) => {
  const { t } = useTranslation('components');

  return (
    <div className='flex items-center gap-2'>
      {item.answer.isYes ? (
        <Icon name='check-square' className='text-content-body-success ' />
      ) : (
        <Icon name='cross-square' className='text-content-body-error' />
      )}
      <Text className='text-content-body-strong'>
        {item.answer.isYes ? t('label.yes') : t('label.no')}
      </Text>
    </div>
  );
};
