import type { ComponentProps, ComponentRef } from 'react';
import { useRef } from 'react';

import type { ApiUser } from '@legalfly/api/users';
import { IconButton } from '@legalfly/ui/button';

import { UserAvatar } from '../users/UserAvatar';
import { TextareaMentions } from './TextareaMentions';

type TextareaMentionsProps = ComponentProps<typeof TextareaMentions>;

type Props = {
  currentUser: ApiUser;
  onAddComment: TextareaMentionsProps['onSubmit'];
} & Pick<TextareaMentionsProps, 'useUsers'>;

export const AddCommentRow = ({ currentUser, onAddComment, useUsers }: Props) => {
  const textareaMentionsRef = useRef<ComponentRef<typeof TextareaMentions>>(null);

  return (
    <div className='flex items-start gap-2 px-5 py-3'>
      <UserAvatar {...currentUser} size='sm' />
      <TextareaMentions
        ref={textareaMentionsRef}
        onSubmit={onAddComment}
        useUsers={useUsers}
        containerClassName='flex-1'
      />
      <IconButton
        name='trend-up-2'
        variant='tertiary'
        size='sm'
        className='self-end'
        onClick={() => textareaMentionsRef.current?.onSubmit()}
      />
    </div>
  );
};
