import { useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useDefaultJurisdiction } from 'common/hooks/useDefaultJurisdiction';

import type { ApiAgentDetail } from '@legalfly/api/agents';
import type { ApiConversation } from '@legalfly/api/conversations';
import { ConversationTypeEnum } from '@legalfly/api/conversations';
import type { Jurisdiction } from '@legalfly/api/core';
import { useSelectedDocuments } from '@legalfly/components/documentPicker/SelectedDocumentsProvider';
import { withSelectedDocuments } from '@legalfly/components/documentPicker/withSelectedDocuments';
import type { TrackingEventMeta } from '@legalfly/reporting/tracking';
import { trackEvent } from '@legalfly/reporting/tracking';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { withViewTransition } from '@legalfly/utils/dom';
import { Copilot } from 'components/conversations/copilot/Copilot';
import { useOptimisticConversationContext } from 'components/conversations/OptimisticConversationProvider';
import { useCreateAgentConversationMessage } from 'core/modules/agents';
import { useCreateConversation, useUpdateConversation } from 'core/modules/conversations';
import { conversationToasts } from 'core/modules/conversations/toasts';
import type { useCreateFiles } from 'core/modules/documents/helpers/useCreateFiles';

import { AgentConversationEmptyState } from './AgentConversationEmptyState';

interface Props extends ReturnType<typeof useCreateFiles> {
  agent: ApiAgentDetail;
  conversationUuid?: ApiConversation['uuid'];
  variant?: 'default' | 'sandbox';
}

const analyticsMeta: TrackingEventMeta = { category: 'agents' };

const AgentCopilot = ({
  agent,
  conversationUuid,
  createFiles,
  isCreatingFiles,
  variant = 'default',
}: Props) => {
  const navigate = useNavigate();

  const createConversationMessage = useCreateAgentConversationMessage();
  const { createConversation } = useCreateConversation({ type: ConversationTypeEnum.AGENT });
  const { clearSelectedDocuments, selectedDocuments } = useSelectedDocuments();

  const defaultJurisdiction = useDefaultJurisdiction();
  const { conversation, setOptimisticConversationUuid } = useOptimisticConversationContext();

  const [jurisdiction, setJurisdiction] = useState<Jurisdiction>(
    conversation?.jurisdiction ?? defaultJurisdiction,
  );

  const { updateConversation } = useUpdateConversation({ type: ConversationTypeEnum.AGENT });

  const handleUpdateJurisdiction = async (jurisdiction: Jurisdiction) => {
    trackEvent({
      action: 'click',
      category: 'agents',
      label: 'jurisdiction',
    });
    setJurisdiction(jurisdiction);

    if (!conversation?.uuid) return;

    await withToasts(updateConversation({ uuid: conversation.uuid, body: { jurisdiction } }))(
      conversationToasts.updateConversation(),
    );
  };

  const uploadFiles = async (files: File[]) => {
    const docs = await createFiles({ uuid: undefined, files });

    if (!docs.length) return;

    if (!conversation?.uuid) {
      const createdConversation = await withToasts(
        createConversation({
          jurisdiction,
          documentUuids: [
            ...docs.map((doc) => doc.uuid),
            ...selectedDocuments.map((doc) => doc.uuid),
          ],
          agentUuid: agent.uuid,
        }),
      )(conversationToasts.createConversation());

      setOptimisticConversationUuid(createdConversation.uuid);
      return;
    }

    await withToasts(
      updateConversation({
        uuid: conversation.uuid,
        body: {
          documentUuids: [
            ...(conversation?.documents.map((doc) => doc.uuid) ?? []),
            ...docs.map((doc) => doc.uuid),
          ],
        },
      }),
    )(conversationToasts.updateConversation());
  };

  const onSendMessage = async (msg: string) => {
    withViewTransition(async () => {
      const createdConversation = await withToasts(
        createConversationMessage({
          uuid: conversation?.uuid,
          question: msg,
          context: {
            agentUuid: agent.uuid,
            documentUuids: selectedDocuments.map((doc) => doc.uuid),
          },
          jurisdiction,
        }),
      )(conversationToasts.createConversation());

      // In the sandbox conversation, we don't want to navigate to the conversation page
      if (createdConversation.uuid !== conversationUuid && variant === 'default') {
        navigate({
          to: `/agents/$agentUuid/conversations/$conversationUuid`,
          params: {
            agentUuid: agent.uuid,
            conversationUuid: createdConversation.uuid,
          },
        });
      }

      // The documents are now added to the conversation, so we clear the selected documents
      clearSelectedDocuments();
    });
  };

  const hasActiveConversation = Boolean(conversation?.uuid);
  const hasMessages = Boolean(conversation?.messages && conversation?.messages.length > 0);

  return (
    <>
      <Copilot
        conversationUuid={conversation?.uuid}
        conversationHasMessages={hasMessages}
        onSendMessage={onSendMessage}
        conversationType={ConversationTypeEnum.AGENT}
        className={cn('relative flex-1', {
          'justify-start pt-4': variant === 'sandbox',
        })}
      >
        <Copilot.ScrollContainer
          className={cn({
            'px-content-x': variant === 'sandbox',
          })}
          stickyClassName={variant === 'sandbox' ? 'hidden' : undefined}
        >
          <Copilot.Content
            className={cn({
              'mx-auto w-[90%] @lg:w-[70%]': variant === 'default',
            })}
          >
            {!hasMessages && (
              <AgentConversationEmptyState agent={agent} onPromptClick={onSendMessage} />
            )}
            <Copilot.Messages
              offsetTop={variant === 'default' ? 44 : 0}
              analyticsMeta={analyticsMeta}
            />
          </Copilot.Content>
        </Copilot.ScrollContainer>
        <Copilot.Footer
          className={cn('mb-11 pt-4', {
            'px-content-x': variant === 'sandbox',
            'mx-auto w-[90%] @lg:w-[70%]': variant === 'default',
          })}
        >
          {hasActiveConversation && <Copilot.StopResponding />}

          {!hasMessages && (
            <Copilot.Input size='large' className='mb-4' isLoading={isCreatingFiles} />
          )}

          <Copilot.Actions>
            <Copilot.Actions.SelectDocuments>
              <Copilot.Actions.SelectDocuments.Content />
            </Copilot.Actions.SelectDocuments>
            <Copilot.Actions.AttachDocuments onUpload={uploadFiles} />
            <Copilot.Actions.SelectJurisdiction
              value={jurisdiction}
              onChange={handleUpdateJurisdiction}
            />
          </Copilot.Actions>

          {hasMessages && <Copilot.Input className='mt-4' />}
        </Copilot.Footer>
      </Copilot>
    </>
  );
};

export default withSelectedDocuments(AgentCopilot);
