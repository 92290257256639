import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import type { ApiClause } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton } from '@legalfly/ui/button';
import { SidePaneProvider } from '@legalfly/ui/sidePane';
import { withToasts } from '@legalfly/ui/toast';
import { ContentRow } from 'components/common/content/ContentRow';
import { ShareDialog } from 'components/common/dialog/share/ShareDialog';
import { EmptyClausesState } from 'components/drafting/clauses/EmptyClausesState';
import { SortableClausesList } from 'components/drafting/clauses/SortableClausesList';
import { DraftingHeader } from 'components/drafting/DraftingHeader';
import { EditableTemplateName } from 'components/drafting/EditableTemplateName';
import {
  draftingQueryOptions,
  draftingToasts,
  useCreateTemplateClause,
  useDeleteTemplateClause,
  useMoveTemplateClause,
  useTemplate,
  useUpdateTemplateClause,
} from 'core/modules/drafting';

export const Route = createFileRoute('/_auth/_layout/drafting/templates/$uuid')({
  component: DraftingTemplateRoute,
  loader({ context, params }) {
    return context.queryClient.ensureQueryData(
      draftingQueryOptions.template({ uuid: params.uuid }),
    );
  },
});

function DraftingTemplateRoute() {
  const { uuid } = Route.useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { template } = useTemplate({ uuid });

  const { createClause } = useCreateTemplateClause();
  const { moveClause } = useMoveTemplateClause();
  const { updateClause } = useUpdateTemplateClause();
  const { deleteClause } = useDeleteTemplateClause();

  const { clauses } = template;

  const handleAddClause = useCallback(
    (sequence: number) => {
      trackEvent({
        action: 'click',
        category: 'templates',
        label: 'addClause',
      });
      withToasts(
        createClause({
          uuid,
          body: {
            title: t('drafting.clauses.new.title'),
            content: t('drafting.clauses.new.subtitle'),
            sequence,
          },
        }),
      )(draftingToasts.createDraftClause());
    },
    [createClause, uuid, t],
  );

  const handleAddFirstClause = useCallback(() => {
    trackEvent({
      action: 'click',
      category: 'templates',
      label: 'addFirstClause',
    });
    handleAddClause(0);
  }, [handleAddClause]);

  const handleUpdateClause = useCallback(
    async (clause: ApiClause) => {
      const updatedClause = await withToasts(
        updateClause({
          uuid,
          clauseUuid: clause.uuid,
          body: {
            title: clause.title,
            content: clause.content,
            instructions: clause.instructions,
          },
        }),
      )(draftingToasts.updateDraftClause());

      return updatedClause;
    },
    [updateClause, uuid],
  );

  const handleDeleteClause = useCallback(
    (clauseUuid: ApiClause['uuid']) => {
      trackEvent({
        action: 'click',
        category: 'templates',
        label: 'deleteClause',
      });
      withToasts(
        deleteClause({
          uuid,
          clauseUuid,
        }),
      )(draftingToasts.deleteDraftClause());
    },
    [deleteClause, uuid],
  );

  const handleMoveClause = useCallback(
    (clauseUuid: string, newSequence: number) => {
      trackEvent({
        action: 'click',
        category: 'templates',
        label: 'moveClause',
      });
      withToasts(
        moveClause({
          uuid,
          clauseUuid,
          body: { sequence: newSequence },
        }),
      )(draftingToasts.moveDraftClause());
    },
    [moveClause, uuid],
  );

  return (
    <SidePaneProvider>
      <div className='flex h-content flex-1 flex-col gap-3'>
        <ContentRow className='justify-between'>
          <div className='flex items-center gap-2'>
            <IconButton
              name='chevron-left'
              onClick={() => navigate({ to: '/drafting/templates' })}
              variant='tertiary'
              size='sm'
            />
            <EditableTemplateName template={template} />
          </div>
          <ShareDialog
            title={t('dialog.share.template.title')}
            description={t('dialog.share.template.description')}
            entityType='template'
            entityUuid={template.uuid}
          />
        </ContentRow>
        <div className='flex h-full flex-col overflow-auto bg-fill-maximal px-content-x py-content-y'>
          {clauses.length > 0 ? (
            <>
              <DraftingHeader
                title={template.title}
                createdAt={template.createdAt}
                className='w-[90%] xl:w-[var(--lf-template-width)]'
              />
              <SortableClausesList
                clauses={clauses}
                onAddClause={handleAddClause}
                onUpdateClause={handleUpdateClause}
                onDeleteClause={handleDeleteClause}
                onMoveClause={handleMoveClause}
              />
            </>
          ) : (
            <EmptyClausesState onAddClause={handleAddFirstClause} />
          )}
        </div>
      </div>
    </SidePaneProvider>
  );
}
