import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from '@tanstack/react-router';

import type { ApiAgentConversations, ApiAgentDetail } from '@legalfly/api/agents';
import type { ApiConversation } from '@legalfly/api/conversations';
import { ConversationTypeEnum } from '@legalfly/api/conversations';
import { FavoriteTypeEnum } from '@legalfly/api/favorites';
import { Button } from '@legalfly/ui/button';
import { SidePaneProvider } from '@legalfly/ui/sidePane';
import { Text } from '@legalfly/ui/text';
import { FavoriteButton } from 'components/common/button/FavoriteButton';
import { ContentRow } from 'components/common/content/ContentRow';
import { ConversationList } from 'components/conversations/list/ConversationList';
import {
  OptimisticConversationProvider,
  useOptimisticConversationContext,
} from 'components/conversations/OptimisticConversationProvider';
import { useFeatureFlag } from 'core/analytics';
import { useCreateFiles } from 'core/modules/documents/helpers/useCreateFiles';

import AgentCopilot from './AgentCopilot';

interface Props {
  agent: ApiAgentDetail;
  conversations: ApiAgentConversations;
  activeConversationUuid?: ApiConversation['uuid'];
}

const Component = ({ agent, conversations, activeConversationUuid }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isCustomAgentsEnabled = useFeatureFlag('custom-agents');

  const { createFiles, isCreatingFiles, numberOfFilesToUpload } = useCreateFiles('agents');
  const { conversation } = useOptimisticConversationContext();

  const goToAgentConversations = () =>
    navigate({ to: '/agents/$agentUuid', params: { agentUuid: agent.uuid } });

  return (
    <SidePaneProvider className='h-content'>
      <ConversationList
        uuid={activeConversationUuid}
        conversations={conversations}
        conversationType={ConversationTypeEnum.AGENT}
        documents={conversation?.documents ?? []}
        numberOfFilesToUpload={numberOfFilesToUpload}
        onCreateConversation={goToAgentConversations}
        onDeleteConversation={goToAgentConversations}
        onClickConversation={(uuid) =>
          navigate({
            to: '/agents/$agentUuid/conversations/$conversationUuid',
            params: { agentUuid: agent.uuid, conversationUuid: uuid },
          })
        }
      />
      <div className='ms-3 flex flex-1 flex-col gap-3 '>
        <ContentRow className='justify-between'>
          <Text>{agent.name}</Text>
          <div className='flex items-center gap-2'>
            <FavoriteButton entityType={FavoriteTypeEnum.AGENT} entity={agent} />
            {isCustomAgentsEnabled && (
              <Button variant='soft' asChild>
                <Link to='/agents/manage/$agentUuid' params={{ agentUuid: agent.uuid }}>
                  {t('action.edit')}
                </Link>
              </Button>
            )}
          </div>
        </ContentRow>
        <div className='h-content-with-row'>
          <AgentCopilot
            agent={agent}
            conversationUuid={activeConversationUuid}
            createFiles={createFiles}
            isCreatingFiles={isCreatingFiles}
            numberOfFilesToUpload={numberOfFilesToUpload}
          />
        </div>
      </div>
    </SidePaneProvider>
  );
};

export const AgentConversation = (props: Props) => {
  return (
    <OptimisticConversationProvider
      conversationUuid={props.activeConversationUuid}
      conversationType={ConversationTypeEnum.AGENT}
    >
      <Component {...props} />
    </OptimisticConversationProvider>
  );
};
