import { type ComponentProps, useMemo } from 'react';

import type { DocumentCategoryKey } from '@legalfly/api/documents';
import { DocumentCategory } from '@legalfly/api/documents';
import {
  Combobox,
  ComboboxContent,
  ComboboxInput,
  ComboboxList,
  ComboboxListItem,
  ComboboxTrigger,
} from '@legalfly/ui/combobox';
import { getKeys } from '@legalfly/utils/object';

import { useDocumentCategoryLabel } from './useDocumentCategoryLabel';

interface Props {
  value: DocumentCategoryKey;
  onChange: (v: DocumentCategoryKey) => void;
  triggerProps?: ComponentProps<typeof ComboboxTrigger>;
  contentProps?: ComponentProps<typeof ComboboxContent>;
}

export const SelectDocumentCategory = ({ value, onChange, triggerProps, contentProps }: Props) => {
  const getDocumentCategoryLabel = useDocumentCategoryLabel();

  const values = useMemo(() => {
    return getKeys(DocumentCategory)
      .map((key) => ({ key, label: getDocumentCategoryLabel(key) }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [getDocumentCategoryLabel]);

  return (
    <Combobox value={value} onChange={onChange}>
      <ComboboxTrigger {...triggerProps}>
        {value ? getDocumentCategoryLabel(value) : getDocumentCategoryLabel('UNDEFINED_DOCUMENT')}
      </ComboboxTrigger>
      <ComboboxContent {...contentProps}>
        <ComboboxInput />
        <ComboboxList>
          {values.map((value) => (
            <ComboboxListItem key={value.key} value={value.key}>
              {value.label}
            </ComboboxListItem>
          ))}
        </ComboboxList>
      </ComboboxContent>
    </Combobox>
  );
};

export default SelectDocumentCategory;
