import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiDocument, DocumentOrigin } from '@legalfly/api/documents';
import type { FetcherError } from '@legalfly/api/fetcher';
import { useDialog } from '@legalfly/ui/dialog';
import { withToasts } from '@legalfly/ui/toast';
import { FileTypeErrorDialog } from 'components/common/errors/FileTypeErrorDialog';

import { useCreateFile } from '..';
import { documentsToasts } from '../toasts';

type DocumentOriginValue = `${DocumentOrigin}`;

const dialogErrorPriority: Record<string, number> = {
  INVALID_FILE: 1,
  // add more errors here as needed
};

const getDialogErrorPriority = (code: string) => dialogErrorPriority[code] || 999;

export const useCreateFiles = (origin: DocumentOriginValue) => {
  const { createFile } = useCreateFile();
  const dialog = useDialog();
  const [isCreatingFiles, setIsCreatingFiles] = useState(false);
  const [numberOfFilesToUpload, setNumberOfFilesToUpload] = useState(0);
  const { t } = useTranslation();

  const createFiles = async ({
    uuid,
    files,
  }: {
    uuid: ApiDocument['uuid'] | undefined;
    files: File[];
  }) => {
    setNumberOfFilesToUpload(files.length);
    setIsCreatingFiles(true);

    type UploadResult =
      | { success: true; document: ApiDocument }
      | { success: false; error: FetcherError };

    const uploadResults = await Promise.all<UploadResult>(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('origin', origin);

        try {
          const { document } = await withToasts(createFile({ uuid, file: formData }))(
            documentsToasts.uploadFile(file.name),
          );
          return { success: true, document };
        } catch (error) {
          return { success: false, error: error as FetcherError };
        }
      }),
    );

    setIsCreatingFiles(false);

    const errors = uploadResults
      .filter((result) => !result.success)
      .filter((result) => result.error);

    if (errors.length > 0) {
      const highestPriorityError = errors.reduce((prev, curr) =>
        getDialogErrorPriority(curr.error.response.code) <
        getDialogErrorPriority(prev.error.response.code)
          ? curr
          : prev,
      );
      if (highestPriorityError.error.response.code === 'INVALID_FILE') {
        dialog.open(<FileTypeErrorDialog onClose={dialog.close} actionText={t('action.close')} />);
      }
    }

    return uploadResults.filter((result) => result.success).map(({ document }) => document);
  };

  return {
    createFiles,
    numberOfFilesToUpload,
    isCreatingFiles,
  };
};
