import {
  Children,
  cloneElement,
  type ComponentProps,
  isValidElement,
  type ReactNode,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiReview } from '@legalfly/api/reviews';
import { getDocumentName } from '@legalfly/components/documents/helpers';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, IconButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { ContentRow } from 'components/common/content/ContentRow';
import { ShareDialog } from 'components/common/dialog/share/ShareDialog';
import { useDownloadAnonymizedDocument, useDownloadDocument } from 'core/modules/documents/helpers';
import { useReviewToCSV } from 'core/modules/reviews/helpers';

import { useDocumentContext } from './DocumentProvider';

const DocumentActions = ({ className, children }: { children: ReactNode; className?: string }) => {
  return <ContentRow className={cn('justify-between', className)}>{children}</ContentRow>;
};

const BackButton = ({
  parentClassName,
  children,
  ...props
}: {
  parentClassName?: string;
  children?: ReactNode;
} & Omit<ComponentProps<typeof IconButton>, 'name'>) => {
  const { document } = useDocumentContext();

  return (
    <div className={cn('flex items-center gap-2', parentClassName)}>
      <IconButton name='chevron-left' variant='tertiary' size='sm' {...props} />
      {children ? children : <Text className='me-2 line-clamp-2 break-all'>{document.name}</Text>}
    </div>
  );
};

const AnonymousButton = () => {
  const { t } = useTranslation();
  const { isAnonymous, setIsAnonymous } = useDocumentContext();

  const handleClick = () => {
    trackEvent({
      action: 'click',
      category: 'documentActions',
      label: isAnonymous ? 'deanonymise' : 'anonymise',
    });
    setIsAnonymous(!isAnonymous);
  };

  return (
    <Button
      renderLeft={<Icon name={isAnonymous ? 'eye-off' : 'eye'} />}
      onClick={handleClick}
      variant='soft'
      size='sm'
    >
      {isAnonymous ? t('documents.actionBar.show') : t('documents.actionBar.anonymise')}
    </Button>
  );
};

interface ChildProps {
  closeMenu?: () => void;
}

const DownloadMenu = ({ children }: { children?: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { document } = useDocumentContext();

  const { handleDownload: handleDownloadAnonimised, isDownloading: isDownloadingAnonimised } =
    useDownloadAnonymizedDocument();

  const { handleDownload: handleDownloadOriginal, isDownloading: isDownloadingOriginal } =
    useDownloadDocument();

  const onClickDownloadOriginal = async (e: Event) => {
    e.preventDefault();
    trackEvent({
      action: 'click',
      category: 'documentActions',
      label: 'downloadOriginal',
    });

    await handleDownloadOriginal({
      uuid: document.uuid,
      fileName: getDocumentName(document),
    });
    setOpen(false);
  };

  const onClickDownloadAnonimised = (e: Event) => {
    e.preventDefault();
    trackEvent({
      action: 'click',
      category: 'documentActions',
      label: 'downloadAnonimised',
    });

    handleDownloadAnonimised(
      {
        uuid: document.uuid,
        fileName: getDocumentName(document),
      },
      () => setOpen(false),
    );
  };
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement<ChildProps>(child)) {
      return cloneElement(child, { closeMenu: () => setOpen(false) });
    }
    return child;
  });

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant='soft' size='sm' renderRight={<Icon name='chevron-down' />}>
          {t('documents.actionBar.download')}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end'>
        <DropdownMenuItem onSelect={onClickDownloadOriginal}>
          {isDownloadingOriginal ? <Spinner /> : <Icon name='download-1' />}
          {t('documents.actionBar.original')}
        </DropdownMenuItem>
        <DropdownMenuItem onSelect={onClickDownloadAnonimised}>
          {isDownloadingAnonimised ? <Spinner /> : <Icon name='download-1' />}
          {t('documents.actionBar.anonymised')}
        </DropdownMenuItem>
        {childrenWithProps}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

interface MenuItemDownloadCsvProps extends ChildProps {
  review: ApiReview;
}

const MenuItemDownloadCsv = ({ review, closeMenu }: MenuItemDownloadCsvProps) => {
  const { t } = useTranslation();
  const { isDownloading, exportReviewAsCSV } = useReviewToCSV(
    [review],
    `${review.document.name}.csv`,
  );

  const handleDownload = async (e: Event) => {
    e.preventDefault();
    trackEvent({
      action: 'click',
      category: 'documentActions',
      label: 'downloadReport',
    });
    await exportReviewAsCSV();
    closeMenu?.();
  };

  return (
    <DropdownMenuItem onSelect={handleDownload}>
      {isDownloading ? <Spinner /> : <Icon name='download-1' />}
      {t('documents.actionBar.downloadReport')}
    </DropdownMenuItem>
  );
};

const Container = ({ children, className }: { children: ReactNode; className?: string }) => {
  return <div className={cn('flex items-center gap-2', className)}>{children}</div>;
};

const ShareButton = ShareDialog;

DocumentActions.BackButton = BackButton;
DocumentActions.AnonymousButton = AnonymousButton;
DocumentActions.Container = Container;
DocumentActions.DownloadMenu = DownloadMenu;
DocumentActions.ShareButton = ShareButton;

DownloadMenu.MenuItemDownloadCsv = MenuItemDownloadCsv;

export { DocumentActions };
