import type { PropsWithChildren } from 'react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { Divider } from '@legalfly/ui/divider';
import { Icon } from '@legalfly/ui/icon';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@legalfly/ui/sheet';
import { Spinner } from '@legalfly/ui/spinner';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

type Props = PropsWithChildren<{
  selectedDocumentsCount: number;
  textClassName?: string;
  containerClassName: string;
  onOpenChange?: (open: boolean) => void;
}>;

export const BaseSelectDocumentsSheet = ({
  onOpenChange,
  selectedDocumentsCount,
  children,
  textClassName,
  containerClassName,
}: Props) => {
  const { t } = useTranslation('components');

  return (
    <Sheet onOpenChange={onOpenChange}>
      <SheetTrigger asChild>
        <Button
          renderLeft={<Icon name='folder' />}
          className='gap-2'
          size='md'
          variant='soft'
          onClick={() => {
            trackEvent({
              action: 'click',
              category: 'discovery',
              label: 'selectDocuments',
            });
          }}
        >
          <Text as='span' className={textClassName}>
            {t('documentPicker.selectDocuments', {
              count: selectedDocumentsCount,
            })}
          </Text>
        </Button>
      </SheetTrigger>
      <SheetContent
        style={{
          top: 'calc(var(--lf-content-header-height) + var(--lf-body-padding) * 2)',
        }}
        className={cn('overflow-hidden bg-fill-maximal', containerClassName)}
      >
        <SheetHeader>
          <SheetTitle>{t('documentPicker.sheet.title')}</SheetTitle>
        </SheetHeader>
        <Divider variant='weak' className='my-4 px-6' />
        <div className='px-6'>
          <Suspense fallback={<Spinner size='md' className='mt-10' />}>{children}</Suspense>
        </div>
      </SheetContent>
    </Sheet>
  );
};
