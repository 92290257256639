import type { ComponentProps, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiConversation } from '@legalfly/api/conversations';
import type { TrackingEventMeta } from '@legalfly/reporting/tracking';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, UploadButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  dropdownMenuItemStyles,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

type Props = {
  analyticsMeta: TrackingEventMeta;
  onUpload: (files: File[]) => void;
  onDeleteDocument: (documentUuid: string) => void;
  conversation?: ApiConversation;
  buttonTextClassName?: string;
} & Pick<ComponentProps<typeof DropdownMenuContent>, 'align' | 'side'>;

export const BaseCopilotAttachDocuments = ({
  analyticsMeta,
  conversation,
  onUpload,
  onDeleteDocument,
  buttonTextClassName,
  align = 'start',
  side = 'bottom',
}: Props) => {
  const { t } = useTranslation('components');

  const countAttachedDocuments = conversation?.documents.length ?? 0;

  if (countAttachedDocuments === 0) {
    return (
      <UploadButton
        onUpload={onUpload}
        renderLeft={<Icon name='link-1' />}
        className='gap-2 border-solid'
        size='sm'
        onClick={() => {
          trackEvent({
            action: 'click',
            category: analyticsMeta.category,
            label: 'attachDocuments',
          });
        }}
      >
        <Text className={buttonTextClassName}>{t('action.attachDocuments')}</Text>
      </UploadButton>
    );
  }

  const handleDeleteDocument = async (e: MouseEvent<HTMLDivElement>, documentUuid: string) => {
    trackEvent({
      action: 'click',
      category: analyticsMeta.category,
      label: 'deleteDocument',
    });
    e.preventDefault();
    e.stopPropagation();

    if (!conversation?.uuid) return;

    onDeleteDocument(documentUuid);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button renderLeft={<Icon name='link-1' />} variant='soft' size='md'>
          <Text className={buttonTextClassName}>
            {t('attachDocuments.files', { count: countAttachedDocuments })}
          </Text>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='flex w-72 flex-col gap-2' align={align} side={side}>
        <UploadButton
          onUpload={onUpload}
          renderRight={<Icon name='plus' />}
          className={cn(dropdownMenuItemStyles, 'justify-between')}
          variant='unstyled'
          onClick={() => {
            trackEvent({
              action: 'click',
              category: analyticsMeta.category,
              label: 'attachExtraDocuments',
            });
          }}
        >
          <span className='text-content-body-placeholder'>
            {t('attachDocuments.filesAttached', { count: countAttachedDocuments })}
          </span>
        </UploadButton>
        {conversation?.documents.map((document) => (
          <DropdownMenuItem
            key={document.uuid}
            className='justify-between'
            onClick={(e) => handleDeleteDocument(e, document.uuid)}
          >
            <span className='truncate'>{document.name}</span>
            <Icon name='trash-4' className='flex-shrink-0' size='sm' />
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
