import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { PermissionEntityType } from '@legalfly/api/core';
import { Button } from '@legalfly/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';
import { withFeatureFlag } from 'core/analytics';

import { ShareDialogBody } from './ShareDialogBody';

type Props = {
  title: string;
  description: string;
  entityType: PermissionEntityType;
  entityUuid: string;
};

export const ShareDialog = withFeatureFlag(
  ({ title, description, entityType, entityUuid }: Props) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant='soft' size='sm' renderLeft={<Icon name='users-2' />}>
            {t('dialog.share.button')}
          </Button>
        </DialogTrigger>
        <DialogContent preventClose>
          <VisuallyHidden>
            <DialogDescription>{description}</DialogDescription>
          </VisuallyHidden>
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          <Suspense fallback={<Spinner />}>
            <ShareDialogBody
              entityType={entityType}
              entityUuid={entityUuid}
              closeDialog={() => setIsOpen(false)}
            />
          </Suspense>
        </DialogContent>
      </Dialog>
    );
  },
  'collaboration-p1',
);
