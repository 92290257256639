import { cn } from 'utils';

import { Spinner } from '../spinner';
import { Text } from '../text';

interface Props {
  textClassName?: string;
  showSpinner?: boolean;
}

export const LegalFlyLoader = ({ showSpinner = true, textClassName }: Props) => {
  return (
    <div className='m-auto flex h-full w-3/4 flex-col items-center justify-center'>
      <Text variant='title' as='h1' className={cn('mb-8', textClassName)}>
        LEGALFLY
      </Text>
      <Spinner className={cn('opacity-0', showSpinner && 'opacity-100')} />
    </div>
  );
};
