import type { ApiDocumentTypeFile } from '@legalfly/api/documents';
import { useSnippetHighlights } from '@legalfly/components/documents/hooks/useSnippetHighlights';
import { SnippetHighlightButtons } from '@legalfly/components/review/SnippetHighlightButtons';
import { DialogTitle } from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { SheetClose, SheetContent } from '@legalfly/ui/sheet';
import { Text } from '@legalfly/ui/text';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';
import { DocumentActions } from 'components/documents/detail/DocumentActions';
import { DocumentProvider } from 'components/documents/detail/DocumentProvider';
import { DocumentViewer } from 'components/documents/detail/DocumentViewer';

interface Props {
  snippetIds: string[];
  document: ApiDocumentTypeFile;
  activeSnippetTextId: string | undefined;
  question: string | undefined;
  answer: string | undefined;
  setActiveSnippetTextId?: (id: string) => void;
}

export const DocumentSnippetsViewerSheetContent = ({
  document,
  snippetIds,
  activeSnippetTextId,
  question,
  answer,
  setActiveSnippetTextId,
}: Props) => {
  const snippetHighlights = useSnippetHighlights({
    index: 0,
    snippetIds,
    riskLevel: 'neutral',
    activeSnippetTextId: activeSnippetTextId ?? '',
    onHighlight: setActiveSnippetTextId,
  });

  return (
    <SheetContent className='flex h-full flex-col gap-0 sm:max-w-[75%]'>
      <DocumentProvider document={document}>
        <div className='bg-surface-main p-body'>
          <VisuallyHidden>
            <DialogTitle>{document.name}</DialogTitle>
          </VisuallyHidden>
          <DocumentActions className='mb-3 flex-1'>
            <SheetClose asChild>
              <DocumentActions.BackButton />
            </SheetClose>
            <DocumentActions.Container>
              {snippetIds.length > 1 && (
                <SnippetHighlightButtons
                  snippetIds={snippetIds}
                  activeSnippetTextId={activeSnippetTextId ?? ''}
                  {...snippetHighlights}
                />
              )}
              <DocumentActions.AnonymousButton />
            </DocumentActions.Container>
          </DocumentActions>

          <div className='flex min-h-20 w-full gap-2'>
            <div className='flex w-1/4 gap-2 bg-fill-strong p-6'>
              <Icon name='list' className='self-start' />
              <Text className='text-content-body'>{question}</Text>
            </div>
            <div
              className='prose max-h-80 w-3/4 overflow-y-auto bg-fill-strongest p-6 '
              dangerouslySetInnerHTML={{ __html: answer ?? '' }}
            />
          </div>
        </div>

        <div className='flex-1 overflow-y-auto'>
          <div className='mx-3 bg-fill-maximal'>
            <DocumentViewer
              className='flex flex-col items-center'
              activeSnippetTextId={activeSnippetTextId}
            />
          </div>
        </div>
      </DocumentProvider>
    </SheetContent>
  );
};
