import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiPlaybook } from '@legalfly/api/playbooks';
import {
  Combobox,
  ComboboxContent,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxList,
  ComboboxListItem,
  ComboboxTrigger,
} from '@legalfly/ui/combobox';
import { Icon } from '@legalfly/ui/icon';

interface Props {
  playbooks: ApiPlaybook[];
  selectedPlaybooks?: ApiPlaybook[];
  triggerProps?: ComponentProps<typeof ComboboxTrigger>;
  contentProps?: ComponentProps<typeof ComboboxContent>;
  onChange: (playbook: ApiPlaybook) => void;
}

export const SelectPlaybook = ({
  playbooks,
  selectedPlaybooks,
  triggerProps,
  contentProps,
  onChange,
}: Props) => {
  const { t } = useTranslation('components');

  return (
    <Combobox
      value={undefined}
      onChange={(playbookUuid: ApiPlaybook['uuid']) => {
        const playbook = playbooks.find((p) => p.uuid === playbookUuid)!;
        onChange(playbook);
      }}
    >
      <ComboboxTrigger {...triggerProps}>
        <div className='flex items-center gap-2'>
          <Icon name='book-open' />
          {t('playbooks.addPlaybook')}
        </div>
      </ComboboxTrigger>
      <ComboboxContent {...contentProps}>
        <ComboboxInput />
        <ComboboxList>
          <ComboboxEmpty>{t('playbooks.noPlaybooks')}</ComboboxEmpty>
          {playbooks
            .filter((playbook) => !selectedPlaybooks?.some((p) => p.uuid === playbook.uuid))
            .map((playbook) => (
              <ComboboxListItem key={playbook.uuid} value={playbook.uuid}>
                {playbook.name}
              </ComboboxListItem>
            ))}
        </ComboboxList>
      </ComboboxContent>
    </Combobox>
  );
};
