import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';

import { agentStatus, type AgentStatusType } from '@legalfly/api/agents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { type IconName } from '@legalfly/ui/icon';
import { withToasts } from '@legalfly/ui/toast';
import { agentToasts, useCreateAgent } from 'core/modules/agents';

import { AgentForm } from '../AgentForm';

interface Props {
  afterSubmit: () => void;
}

interface FormValues {
  name: string;
  description?: string;
  icon: IconName;
  status: AgentStatusType;
}

const defaultValues: FormValues = {
  name: '',
  description: '',
  icon: 'plus',
  status: agentStatus.PRIVATE,
};

export const CreateAgentForm = ({ afterSubmit }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createAgent, isCreatingAgent } = useCreateAgent();

  const handleCreateAgent = async (values: FormValues) => {
    trackEvent({
      action: 'submit',
      category: 'agents',
      label: 'createAgent',
    });

    const agent = await withToasts(
      createAgent({
        body: {
          name: values.name,
          description: values.description ?? '',
          icon: values.icon,
          status: values.status,
        },
      }),
    )(agentToasts.createAgent());

    navigate({ to: `/agents/manage/$agentUuid`, params: { agentUuid: agent.uuid } });
    afterSubmit();
  };

  return (
    <AgentForm
      defaultValues={defaultValues}
      onSubmit={handleCreateAgent}
      onCancel={afterSubmit}
      isLoading={isCreatingAgent}
      submitLabel={t('action.createAgent')}
      includeDescription
    />
  );
};
