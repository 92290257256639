import { t } from 'i18next';

import type { ApiAnonymization } from '@legalfly/api/anonymization';
import { getDocumentName } from '@legalfly/components/documents/helpers';

export const getAnonymizationName = (anonymisation: ApiAnonymization) => {
  if (anonymisation.name) {
    return anonymisation.name;
  }

  const firstPart = anonymisation.documents
    .slice(0, 2)
    .map((document) => getDocumentName(document))
    .join(', ');

  if (anonymisation.documents.length <= 2) {
    return firstPart;
  }

  const additionalCount = anonymisation.documents.length - 2;
  return `${firstPart} ${t('anonymisation.additionalDocuments', { count: additionalCount })}`;
};
