import type { ComponentProps, ComponentRef } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Document from '@tiptap/extension-document';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { EditorContent, mergeAttributes, ReactRenderer, useEditor } from '@tiptap/react';
import DOMPurify from 'dompurify';

import { cn } from '@legalfly/ui/utils';

import { MentionsList } from './MentionsList';
import './styles.css';

type Props = {
  onSubmit: (comment: string) => void;
  className?: string;
  containerClassName?: string;
} & Pick<ComponentProps<typeof MentionsList>, 'useUsers'>;

type Ref = {
  onSubmit: () => void;
};

export const TextareaMentions = forwardRef<Ref, Props>(
  ({ onSubmit, useUsers, containerClassName, className }, ref) => {
    const { t } = useTranslation('components');
    const isMentionsListOpen = useRef(false);

    const editor = useEditor({
      extensions: [
        Document,
        Paragraph,
        Text,
        Placeholder.configure({
          placeholder: t('comments.create.input.placeholder'),
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'text-content-body-tag',
          },
          renderHTML({ options, node }) {
            return [
              'span',
              // eslint-disable-next-line react/no-this-in-sfc
              mergeAttributes(this.HTMLAttributes ?? {}, options.HTMLAttributes),
              `${options.suggestion.char}${node.attrs.label}`,
            ];
          },
          suggestion: {
            char: '@',
            render: () => {
              let component: ReactRenderer<
                ComponentRef<typeof MentionsList>,
                ComponentProps<typeof MentionsList>
              >;

              return {
                onStart: (props) => {
                  isMentionsListOpen.current = true;
                  component = new ReactRenderer(MentionsList, {
                    props: { ...props, useUsers },
                    editor: props.editor,
                  });
                },
                onUpdate(props) {
                  component.updateProps(props);
                },
                // forward keydown events to the mention list
                onKeyDown(props) {
                  return component.ref?.onKeyDown(props) ?? false;
                },
                onExit() {
                  isMentionsListOpen.current = false;
                  component.destroy();
                },
              };
            },
          },
        }),
      ],
      editorProps: {
        attributes: {
          class: cn(
            'relative min-h-8 w-full border border-transparent bg-transparent py-2',
            'focus-visible:border-b-stroke-weaker focus-visible:outline-0',
            className,
          ),
        },
        handleKeyDown: (_, event) => {
          if (event.key === 'Enter') {
            // when the mention list is open, allow the enter key to select an option
            if (isMentionsListOpen.current) {
              return false;
            }

            // Insert new line with Shift+Enter
            if (event.shiftKey) {
              editor?.commands.first(({ commands }) => [
                () => commands.newlineInCode(),
                () => commands.createParagraphNear(),
                () => commands.liftEmptyBlock(),
                () => commands.splitBlock(),
              ]);
              return false;
            }

            if (editor?.isEmpty) {
              // dont submit on empty
              return true;
            } else {
              // submit on enter
              event.preventDefault();
              handleSubmit();
              return true;
            }
          }

          return false;
        },
      },
    });

    const handleSubmit = () => {
      const html = editor?.getHTML();
      if (editor?.isEmpty || !html) return;

      // sanitize and remove span content but keep data-id
      const sanitizedHtml = DOMPurify.sanitize(html, {
        ALLOWED_TAGS: ['p', 'span'],
        FORBID_ATTR: ['data-label', 'data-type', 'class', 'style'],
      }).replace(/(<span[^>]*?)>[^<]*<\/span>/g, '$1></span>');

      onSubmit(sanitizedHtml);

      editor?.commands.clearContent();
      editor?.commands.focus();
    };

    useImperativeHandle(ref, () => ({ onSubmit: handleSubmit }));

    useEffect(() => {
      editor?.commands.focus();
    }, [editor]);

    if (!editor) {
      return null;
    }

    return <EditorContent editor={editor} className={containerClassName} />;
  },
);

TextareaMentions.displayName = 'TextareaMentions';
