import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiAnonymization } from '@legalfly/api/anonymization';
import { withSelectedDocuments } from '@legalfly/components/documentPicker/withSelectedDocuments';
import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { useDocumentActions } from 'components/documents/useDocumentActions';
import { useDeleteAnonymization } from 'core/modules/anonymization';
import { anonymizationToasts } from 'core/modules/anonymization/toasts';

interface Props {
  anonymization: ApiAnonymization;
}

export const AnonymizationsMenu = withSelectedDocuments(({ anonymization }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { deleteAnonymization, isLoading: isDeletingAnonymization } = useDeleteAnonymization();

  const handleDeleteAnonymization = () => {
    trackEvent({
      action: 'click',
      category: 'anonymisation',
      label: 'delete',
    });
    withToasts(deleteAnonymization({ uuid: anonymization.uuid }))(anonymizationToasts.delete());
  };

  const { handleCreateDocumentsZip, handleCreateAnonymizedDocumentsZip } = useDocumentActions(
    anonymization.documents,
  );

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          name='dots-horizontal'
          className={cn('rotate-90', isOpen && 'bg-fill-hover-strong')}
          variant='tertiary'
          size='sm'
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className='flex flex-col gap-2' align='end'>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger className='flex items-center gap-3'>
            <Icon name='download-1' />
            {t('documents.detailPane.download')}
          </DropdownMenuSubTrigger>
          <DropdownMenuSubContent className='w-60'>
            <DropdownMenuItem
              onSelect={() => handleCreateDocumentsZip({ category: 'anonymisation' })}
              className='flex items-center gap-3'
            >
              {t('action.downloadZip')}
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => handleCreateAnonymizedDocumentsZip({ category: 'anonymisation' })}
              className='flex items-center gap-3'
            >
              {t('documents.detailPane.downloadAnonymizedZip')}
            </DropdownMenuItem>
          </DropdownMenuSubContent>
        </DropdownMenuSub>

        <DropdownMenuItem onClick={handleDeleteAnonymization}>
          {isDeletingAnonymization ? (
            <Spinner />
          ) : (
            <Icon name='trash-2' className='text-icon-weak' />
          )}
          {t('action.deleteAnonymization')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
});
