import { queryOptions } from '@tanstack/react-query';

import type {
  ApiConversation,
  ConversationApiType,
  ConversationTypeEnum,
} from '@legalfly/api/conversations';

interface ConversationsQueryOptions {
  createConversationsApi: (type: ConversationTypeEnum) => ConversationApiType | undefined;
}

export const conversationsQueryOptions = ({
  createConversationsApi,
}: ConversationsQueryOptions) => ({
  conversations: ({ type }: { type: ConversationTypeEnum }) => {
    const conversationsApi = createConversationsApi(type);

    return queryOptions({
      queryKey: [type, 'conversations'],
      queryFn: () => conversationsApi!.getConversations(),
    });
  },
  conversation: ({
    type,
    uuid,
  }: {
    type: ConversationTypeEnum;
    uuid: ApiConversation['uuid'] | undefined;
  }) => {
    const conversationsApi = createConversationsApi(type);

    return queryOptions({
      queryKey: [type, 'conversation', uuid],
      queryFn: () => conversationsApi!.getConversation({ uuid: uuid! }),
      enabled: Boolean(uuid),
    });
  },
});
