import { useTranslation } from 'react-i18next';

import type { ApiUserPermission } from '@legalfly/api/collaboration';
import { UserAvatar } from '@legalfly/components/users/UserAvatar';
import { Button } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { List, ListItem } from '@legalfly/ui/list';
import { Text } from '@legalfly/ui/text';

interface Props {
  users: ApiUserPermission[];
  onRemoveUser: (user: ApiUserPermission['user']) => void;
}

export const ShareDialogUserList = ({ users, onRemoveUser }: Props) => {
  const { t } = useTranslation();

  return (
    <List className='mt-6 max-h-[305px] overflow-y-auto border-b border-stroke-weaker'>
      {users.map(({ user }) => (
        <ListItem key={user.uuid} className='hover:bg-transparent'>
          <div className='flex items-center gap-3'>
            <UserAvatar {...user} size='sm' />
            <div className='flex flex-col text-left'>
              <Text>
                {user.firstName} {user.lastName}
              </Text>
              <Text variant='caption' className='text-content-body-strong'>
                {user.email}
              </Text>
            </div>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant='ghost'
                size='sm'
                renderRight={<Icon name='chevron-down' />}
                className='data-[state=open]:bg-fill-hover-weak'
              >
                {t('dialog.share.permissions.viewer')}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='end'>
              <DropdownMenuItem
                className='flex items-center gap-2'
                onClick={() => onRemoveUser(user)}
              >
                {t('dialog.share.permissions.remove')}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </ListItem>
      ))}
    </List>
  );
};
