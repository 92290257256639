import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiUserPermission } from '@legalfly/api/collaboration';
import type { ApiUser } from '@legalfly/api/users';
import { UserAvatar } from '@legalfly/components/users/UserAvatar';
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from '@legalfly/ui/command';
import { Popover, PopoverAnchor, PopoverContent } from '@legalfly/ui/popover';
import { Spinner } from '@legalfly/ui/spinner';
import { useDebouncedValue } from '@legalfly/utils/hooks';
import { useUsers } from 'core/modules/users';

interface Props {
  onChange: (value: ApiUserPermission) => void;
}

interface State {
  value: string;
  isOpen: boolean;
}

const initialState: State = {
  value: '',
  isOpen: false,
};

type Action =
  | { type: 'SET_VALUE'; payload: string }
  | { type: 'SET_OPEN'; payload: boolean }
  | { type: 'RESET' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_VALUE':
      return { ...state, value: action.payload };
    case 'SET_OPEN':
      return { ...state, isOpen: action.payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
}

export const SearchUserInput = ({ onChange }: Props) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const keyword = useDebouncedValue(state.value, 300);

  const { users, isLoading } = useUsers({
    keyword,
    isSuspenseEnabled: false,
    enabled: Boolean(keyword),
  });

  const onSelect = (user: ApiUser) => {
    dispatch({ type: 'RESET' });
    onChange({
      user,
      permissions: ['view'],
    });
  };

  useEffect(() => {
    if (keyword) {
      dispatch({ type: 'SET_OPEN', payload: true });
    }
  }, [keyword]);

  return (
    <Popover
      open={state.isOpen}
      onOpenChange={(open) => dispatch({ type: 'SET_OPEN', payload: open })}
    >
      <Command shouldFilter={false} className='overflow-visible'>
        <PopoverAnchor asChild>
          <CommandInput
            value={state.value}
            onValueChange={(value) => dispatch({ type: 'SET_VALUE', payload: value })}
            renderRight={isLoading ? <Spinner /> : undefined}
            placeholder={t('dialog.share.userSearch.input.placeholder')}
          />
        </PopoverAnchor>
        {!state.isOpen && <CommandList aria-hidden='true' className='hidden' />}
        <PopoverContent
          asChild
          onOpenAutoFocus={(e) => e.preventDefault()}
          className='w-[--radix-popover-trigger-width] p-0'
          sideOffset={10}
        >
          <CommandList>
            {users.map((user) => (
              <CommandItem
                key={user.uuid}
                value={user.uuid}
                className='flex gap-3'
                onSelect={() => onSelect(user)}
              >
                <UserAvatar {...user} size='sm' />
                {user.firstName} {user.lastName}
              </CommandItem>
            ))}
            {!isLoading && (
              <CommandEmpty className='py-4'>{t('dialog.share.userSearch.noResults')}</CommandEmpty>
            )}
          </CommandList>
        </PopoverContent>
      </Command>
    </Popover>
  );
};
