import { useState } from 'react';

import type { ApiComment } from '@legalfly/api/comments';
import {
  BaseCommentsPopover,
  BaseCommentsPopoverContent,
} from '@legalfly/components/comments/BaseCommentsPopover';
import { withToasts } from '@legalfly/ui/toast';
import { withFeatureFlag } from 'core/analytics';
import {
  commentsToasts,
  useAddComment,
  useComments,
  useDeleteComment,
} from 'core/modules/comments';
import { useCurrentUser, useUsers } from 'core/modules/users';

export const CommentsPopover = withFeatureFlag(
  ({
    entityUuid,
    entityType,
  }: {
    entityUuid: ApiComment['uuid'];
    entityType: ApiComment['entityType'];
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [scrollEnabled, setScrollEnabled] = useState(true);

    const { currentUser } = useCurrentUser();
    const { comments } = useComments({ entityUuid, entityType, enabled: isOpen });
    const { addComment } = useAddComment();
    const { deleteComment } = useDeleteComment();

    const handleAddComment = async (content: string) => {
      setScrollEnabled(true);
      await withToasts(addComment({ params: { entityUuid, entityType }, body: { content } }))(
        commentsToasts.addComment(),
      );
    };

    const handleDeleteComment = async (uuid: ApiComment['uuid']) => {
      setScrollEnabled(false);
      await withToasts(
        deleteComment({ params: { commentUuid: uuid }, uuid: entityUuid, entityType }),
      )(commentsToasts.deleteComment());
    };

    return (
      <BaseCommentsPopover open={isOpen} onOpenChange={setIsOpen}>
        <BaseCommentsPopoverContent>
          <BaseCommentsPopoverContent.CommentsList
            comments={comments}
            scrollEnabled={scrollEnabled}
            onDeleteComment={handleDeleteComment}
          />
          <BaseCommentsPopoverContent.AddCommentRow
            useUsers={useUsers}
            currentUser={currentUser}
            onAddComment={handleAddComment}
          />
        </BaseCommentsPopoverContent>
      </BaseCommentsPopover>
    );
  },
  'collaboration-p2',
);
