import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const reviewToasts = createToasts({
  updateReview: () => ({
    errorToast: () => ({
      title: i18n.t('components:toast.error.title'),
      description: i18n.t('components:toast.error.updateReview.description'),
    }),
  }),
});
