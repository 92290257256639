import { useNavigate } from '@tanstack/react-router';
import { useDefaultJurisdiction } from 'common/hooks/useDefaultJurisdiction';

import type { ApiDocumentTypeFile } from '@legalfly/api/documents';
import {
  BaseNewReviewForm,
  type BaseNewReviewFormData,
} from '@legalfly/components/review/BaseNewReviewForm';
import { usePlaybooks, useSystemPlaybooks } from 'core/modules/playbooks';
import { useCreateReview } from 'core/modules/reviews';

interface Props {
  document: ApiDocumentTypeFile;
}

export const NewReviewForm = ({ document }: Props) => {
  const navigate = useNavigate();
  const defaultJurisdiction = useDefaultJurisdiction();
  const { systemPlaybooks, fetchSystemPlaybooks } = useSystemPlaybooks(document.category);
  const { createReview } = useCreateReview();
  const { playbooks } = usePlaybooks();

  const onSubmit = async (data: BaseNewReviewFormData) => {
    const review = await createReview({
      body: {
        category: data.category,
        party: data.party,
        documentUuid: document.uuid,
        jurisdiction: data.jurisdiction,
        language: data.language,
        playbookUuids: data.activePlaybookIds,
      },
    });

    navigate({
      to: `/review/$uuid`,
      replace: true,
      params: {
        uuid: review.uuid,
      },
    });
  };

  const onCategoryChange = (category: ApiDocumentTypeFile['category']) => {
    return fetchSystemPlaybooks(category);
  };

  return (
    <BaseNewReviewForm
      document={document}
      defaultJurisdiction={defaultJurisdiction}
      playbooks={playbooks}
      systemPlaybooks={systemPlaybooks}
      onCategoryChange={onCategoryChange}
      onSubmit={onSubmit}
    />
  );
};
