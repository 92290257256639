import type { ComponentProps } from 'react';
import { useCallback, useRef } from 'react';

import { cn } from 'utils';

import { Button } from './Button';

export interface ProcessedFile {
  dataUrl: string;
  size: number;
  name: string;
  type: string;
}

export const UploadButton = ({
  className,
  isSingle = false,
  variant = 'soft',
  onUpload,
  onClick,
  ...buttonProps
}: { onUpload: (files: File[]) => void; isSingle?: boolean } & ComponentProps<typeof Button>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.currentTarget.files ?? []);
      return onUpload(files);
    },
    [onUpload],
  );

  const onInputTriggerClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      inputRef.current?.click();
      onClick?.(e);
    },
    [onClick],
  );

  return (
    <>
      <Button
        onClick={onInputTriggerClick}
        className={cn('border-dashed', className)}
        variant={variant}
        {...buttonProps}
      />
      <input
        ref={inputRef}
        className='hidden'
        onChange={onFileInputChange}
        type='file'
        multiple={!isSingle}
      />
    </>
  );
};
