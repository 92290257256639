import type { ComponentProps, PropsWithChildren } from 'react';

import headerBackgroundVideo from '../../assets/headerBackgroundVideo.mp4';
import { cn } from '../../utils';
import { Button } from '../button';
import { Icon } from '../icon';
import { Text } from '../text';

type PropsWithChildrenAndClassName = PropsWithChildren<{
  className?: string;
}>;

const ErrorCard = ({ children, className }: PropsWithChildrenAndClassName) => {
  return (
    <div className={cn('relative flex', className)}>
      <div className='relative z-10 flex flex-1 items-center justify-center'>{children}</div>
      <video
        id='background-video'
        loop
        autoPlay
        muted
        className='absolute left-0 top-0 z-0 h-full w-full object-cover opacity-20'
      >
        <track kind='captions' />
        <source src={headerBackgroundVideo} type='video/mp4' />
      </video>
    </div>
  );
};

const ErrorCardContent = ({ children, className }: PropsWithChildrenAndClassName) => (
  <div
    className={cn(
      'mx-auto flex max-h-[80vh] w-full max-w-5xl flex-col items-center gap-8 overflow-auto bg-surface-main/90 p-6 text-center',
      className,
    )}
  >
    {children}
  </div>
);

const ErrorCardTitle = ({ children }: PropsWithChildren) => (
  <Text variant='heading2' as='h1'>
    {children}
  </Text>
);

const ErrorCardMessage = ({ children }: PropsWithChildren) => (
  <Text
    variant='bodyLarge'
    className='w-full whitespace-pre-wrap text-content-body-strong'
    as='pre'
  >
    {children}
  </Text>
);

const ErrorCardAction = (props: ComponentProps<typeof Button>) => (
  <Button renderLeft={<Icon name='home-line' />} variant='soft' {...props} />
);

ErrorCard.Content = ErrorCardContent;
ErrorCard.Title = ErrorCardTitle;
ErrorCard.Message = ErrorCardMessage;
ErrorCard.Action = ErrorCardAction;

export { ErrorCard };
