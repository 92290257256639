import type { ComponentProps } from 'react';
import { useState } from 'react';

import type { ApiDocument } from '@legalfly/api/documents';
import { useFolder } from 'core/modules/documents';

import { DocumentPicker } from './DocumentPicker';

export const SheetDocumentPicker = (
  props: Omit<ComponentProps<typeof DocumentPicker>, 'documents'>,
) => {
  const [activeDocument, setActiveDocument] = useState<ApiDocument | undefined>(undefined);
  const { documents } = useFolder({
    uuid: activeDocument?.uuid,
  });

  const handleBackPress = () => {
    if (activeDocument?.parent) {
      setActiveDocument(activeDocument.parent);
    } else {
      setActiveDocument(undefined);
    }
  };

  return (
    <DocumentPicker
      documents={documents}
      onSelectFolder={setActiveDocument}
      onBackPress={activeDocument ? handleBackPress : undefined}
      {...props}
    />
  );
};
