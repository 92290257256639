import { useDownload } from './useDownload';

export const useDownloadZip = () => {
  const {
    isDownloading,
    setProgress,
    setIsDownloading,
    cancelDownload,
    performDownload,
    showErrorToast,
  } = useDownload();

  const downloadZip = async ({ url, filename }: { url: string; filename: string }) => {
    if (isDownloading) {
      cancelDownload();
      return;
    }

    setIsDownloading(true);
    setProgress(0);
    try {
      await performDownload(url, filename);
    } catch {
      showErrorToast();
    } finally {
      setIsDownloading(false);
    }
  };

  return { isDownloading, downloadZip };
};
