import { useTranslation } from 'react-i18next';
import { type ErrorComponentProps, useNavigate } from '@tanstack/react-router';

import { BaseDefaultError } from '@legalfly/components/errors/BaseDefaultError';
import { env } from 'core/env';

export const DefaultError = (props: ErrorComponentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BaseDefaultError
      {...props}
      showStackTrace={!env.isProduction}
      cta={{ label: t('errors.card.cta'), onClick: () => navigate({ to: '/' }) }}
    />
  );
};
