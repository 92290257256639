import { type ComponentProps } from 'react';
import { useTableHeight } from 'common/hooks/useTableHeight';

import { BaseDocumentPicker } from '@legalfly/components/documentPicker/BaseDocumentPicker';

import { useDocumentPickerColumns } from './columns';

export const DocumentPicker = (
  props: Omit<ComponentProps<typeof BaseDocumentPicker>, 'useColumns' | 'tableHeight' | 'tableRef'>,
) => {
  const { tableHeight, tableRef } = useTableHeight();

  return (
    <BaseDocumentPicker
      tableRef={tableRef}
      useColumns={useDocumentPickerColumns}
      tableHeight={tableHeight}
      {...props}
    />
  );
};
