import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { useTableHeight } from 'common/hooks/useTableHeight';
import i18n from 'i18next';

import type { ApiDraft } from '@legalfly/api/drafting';
import { useDocumentCategoryLabel } from '@legalfly/components/documentCategory/useDocumentCategoryLabel';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@legalfly/ui/table';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';

import DraftsActionPopover from './DraftsActionPopover';
import { DraftsEmptyState } from './DraftsEmptyState';

interface Props {
  filteredDrafts: ApiDraft[];
  hasAvailableDrafts: boolean;
}

export const DraftsList = ({ filteredDrafts, hasAvailableDrafts }: Props) => {
  const { t } = useTranslation();
  const getDocumentCategoryLabel = useDocumentCategoryLabel();
  const navigate = useNavigate();
  const { tableHeight, tableRef } = useTableHeight();

  if (!hasAvailableDrafts && filteredDrafts.length === 0) {
    return <DraftsEmptyState />;
  }

  return (
    <Table
      parentClassName='h-fit overflow-y-auto relative'
      ref={tableRef}
      style={{ maxHeight: tableHeight }}
    >
      <TableHeader className='sticky top-0 z-10 bg-fill-maximal'>
        <TableRow className='shadow-table-border'>
          <TableHead>{t('drafting.templates.list.title')}</TableHead>
          <TableHead>{t('drafting.templates.list.contractType')}</TableHead>
          <TableHead>{t('drafting.templates.list.createdAt')}</TableHead>
          <TableHead />
        </TableRow>
      </TableHeader>
      <TableBody>
        {hasAvailableDrafts && filteredDrafts.length === 0 ? (
          <TableRow>
            <TableCell colSpan={3}>{t('drafting.drafts.list.empty')}</TableCell>
          </TableRow>
        ) : (
          filteredDrafts.map((draft) => {
            const handleOnClick = () => {
              navigate({
                to: '/drafting/drafts/$uuid',
                params: { uuid: draft.uuid },
              });
            };

            return (
              <TableRow key={draft.uuid} className='cursor-pointer'>
                <TableCell onClick={handleOnClick} className='text-body-semibold'>
                  {draft.title}
                </TableCell>
                <TableCell onClick={handleOnClick}>
                  {getDocumentCategoryLabel(draft.category)}
                </TableCell>
                <TableCell
                  onClick={handleOnClick}
                  title={formatLongDate(draft.createdAt, i18n.language)}
                >
                  {formatRelativeTime(draft.createdAt, i18n.language)}
                </TableCell>
                <TableCell>
                  <DraftsActionPopover draft={draft} />
                </TableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  );
};
