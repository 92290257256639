import type { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { useTableHeight } from 'common/hooks/useTableHeight';

import type { ApiPlaybook } from '@legalfly/api/playbooks';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Icon } from '@legalfly/ui/icon';
import { List, ListItem } from '@legalfly/ui/list';
import { Text } from '@legalfly/ui/text';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';

import { PlaybookListMenu } from './PlaybookListMenu';

interface Props {
  playbooks: ApiPlaybook[];
}

export const PlaybookList = ({ playbooks }: Props) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { tableHeight, tableRef } = useTableHeight();

  return (
    <List style={{ maxHeight: tableHeight }} ref={tableRef} className='relative overflow-y-auto'>
      {playbooks.map((playbook) => {
        const handleOnClick: MouseEventHandler<HTMLDivElement> = (e) => {
          const isActionPopover = (e.target as HTMLElement).closest('[data-dropdown-menu]');

          if (!isActionPopover) {
            trackEvent({
              action: 'click',
              category: 'playbooks',
              label: 'delete',
            });

            navigate({
              to: '/playbooks/$playbookUuid',
              params: { playbookUuid: playbook.uuid },
            });
          }
        };

        return (
          <ListItem key={playbook.uuid} onClick={handleOnClick} className='min-h-16'>
            <div className='flex items-center gap-2'>
              <Icon name='book-open' />
              <span className='text-body-semibold'>{playbook.name}</span>
            </div>
            <div className='flex items-center gap-2'>
              <Text
                className='text-content-body-weak'
                title={formatLongDate(playbook.createdAt, i18n.language)}
              >
                {formatRelativeTime(playbook.createdAt, i18n.language)}
              </Text>
              <PlaybookListMenu playbook={playbook} />
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};
