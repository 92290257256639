import type { MouseEvent } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import type { ApiAgentDetail } from '@legalfly/api/agents';
import type { LLMModelType } from '@legalfly/api/core';
import type { ApiDocument } from '@legalfly/api/documents';
import { UserRole } from '@legalfly/api/users';
import { useSelectedDocuments } from '@legalfly/components/documentPicker/SelectedDocumentsProvider';
import { withSelectedDocuments } from '@legalfly/components/documentPicker/withSelectedDocuments';
import { getDocumentIcon } from '@legalfly/components/documents/helpers';
import {
  DocumentsUploadProgress,
  useDocumentsUploadProgress,
} from '@legalfly/components/documentsUploadProgress';
import { requiredValidator } from '@legalfly/components/forms/validators';
import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton, UploadButton } from '@legalfly/ui/button';
import { FormItem, FormItemLabel } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { List, ListItem } from '@legalfly/ui/list';
import { SidePaneProvider } from '@legalfly/ui/sidePane';
import { Text } from '@legalfly/ui/text';
import { Textarea } from '@legalfly/ui/textarea';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { SelectAIModel } from 'components/admin/SelectAIModel';
import { SelectDocumentsSheet } from 'components/documents/documentPicker/SelectDocumentsSheet';
import { agentToasts, ListenToAgentDocumentsStatus, useUpdateAgent } from 'core/modules/agents';
import { useCreateFiles } from 'core/modules/documents/helpers/useCreateFiles';
import { useCurrentUser } from 'core/modules/users';

import { AgentConversationStarterDialog } from './AgentConversationStarterDialog';

interface Props {
  agent: ApiAgentDetail;
}

interface FormValues {
  description: string;
  instructions: string;
  conversationStarters: ApiAgentDetail['conversationStarters'];
  model: LLMModelType;
}

const Component = ({ agent }: Props) => {
  const { t } = useTranslation();

  const [isExpandedInstructions, setIsExpandedInstructions] = useState(false);
  const { selectedDocuments, clearSelectedDocuments } = useSelectedDocuments();
  const { currentUser } = useCurrentUser();
  const { updateAgent } = useUpdateAgent();

  const { createFiles, numberOfFilesToUpload } = useCreateFiles('agents');
  const documentUploadProgress = useDocumentsUploadProgress(agent.documents, numberOfFilesToUpload);

  const updateAgentDocuments = useCallback(
    async (documents: ApiDocument[]) => {
      await withToasts(
        updateAgent({
          uuid: agent.uuid,
          body: {
            documentUuids: [
              ...agent.documents.map((document) => document.uuid),
              ...documents.map((document) => document.uuid),
            ],
          },
        }),
      )(agentToasts.updateAgent());
    },
    [agent, updateAgent],
  );

  const uploadFiles = async (files: File[]) => {
    const documents = await createFiles({ uuid: undefined, files });

    if (!documents.length) return;

    updateAgentDocuments(documents);
  };

  const handleSelectedDocumentsChange = async (open: boolean) => {
    if (!open) {
      await updateAgentDocuments(selectedDocuments);

      clearSelectedDocuments();
    }
  };

  const defaultValues: FormValues = {
    description: agent.description,
    instructions: agent.instructions ?? '',
    conversationStarters: agent.conversationStarters ?? [],
    model: agent.model,
  };

  const handleUpdateAgent = async ({ value }: { value: FormValues }) => {
    trackEvent({
      action: 'submit',
      category: 'agents',
      label: 'updateAgent',
    });

    await withToasts(
      updateAgent({
        uuid: agent.uuid,
        body: {
          ...value,
          documentUuids: agent.documents.map((document) => document.uuid),
        },
      }),
    )(agentToasts.updateAgent());
  };

  const handleDeleteDocument = async (e: MouseEvent<HTMLButtonElement>, documentUuid: string) => {
    trackEvent({
      action: 'click',
      category: 'agents',
      label: 'deleteDocument',
    });

    e.preventDefault();
    e.stopPropagation();

    await withToasts(
      updateAgent({
        uuid: agent.uuid,
        body: {
          documentUuids: agent.documents
            .filter((doc) => doc.uuid !== documentUuid)
            .map((doc) => doc.uuid),
        },
      }),
    )(agentToasts.updateAgent());
  };

  const form = useForm<FormValues>({
    defaultValues,
    onSubmit: handleUpdateAgent,
  });

  return (
    <SidePaneProvider className='flex h-content-with-row flex-1 flex-col'>
      <div className='relative h-full flex-grow overflow-y-auto bg-fill-strongest p-6'>
        <form
          className='flex flex-col gap-4'
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <div className='flex flex-col gap-8'>
            <FormItem>
              <FormItemLabel label={t('form.agentDescription.label')} htmlFor='description' />
              <form.Field
                name='description'
                validators={{
                  onBlur: ({ value }) =>
                    requiredValidator(t('form.agentDescription.validation.required'))(value),
                }}
              >
                {(field) => (
                  <Textarea
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onBlur={() => {
                      field.handleBlur();
                      form.handleSubmit();
                    }}
                    placeholder={t('form.agentDescription.placeholder')}
                    onChange={(e) => field.handleChange(e.target.value)}
                    errorMessage={field.state.meta.errors.join()}
                    className='p-6 text-content-body-strong'
                    rows={3}
                  />
                )}
              </form.Field>
            </FormItem>
            <FormItem>
              <FormItemLabel label={t('form.agentInstructions.label')} htmlFor='instructions' />
              <form.Field name='instructions'>
                {(field) => (
                  <div
                    className={cn(
                      'relative z-10 transition-all duration-300 ease-in-out',
                      isExpandedInstructions ? 'h-[600px]' : 'h-[300px]',
                    )}
                  >
                    <Textarea
                      id={field.name}
                      name={field.name}
                      value={field.state.value}
                      onBlur={() => {
                        field.handleBlur();
                        form.handleSubmit();
                      }}
                      placeholder={t('form.agentInstructions.placeholder')}
                      onChange={(e) => field.handleChange(e.target.value)}
                      errorMessage={field.state.meta.errors.join()}
                      className={cn(
                        'absolute inset-0 resize-none p-6 text-content-body-strong transition-all duration-300 ease-in-out',
                        'max-h-full overflow-y-auto',
                      )}
                      rows={isExpandedInstructions ? 24 : 12}
                    />
                    <IconButton
                      name='paragraph-spacing'
                      variant='unstyled'
                      className='absolute bottom-4 right-4 z-10'
                      onClick={() => setIsExpandedInstructions(!isExpandedInstructions)}
                    />
                  </div>
                )}
              </form.Field>
            </FormItem>
            <FormItem>
              <FormItemLabel label={t('form.agentFiles.label')} htmlFor='instructions' />
              <div className='border border-stroke-weaker p-6'>
                <div className='flex items-center gap-2'>
                  <UploadButton
                    onUpload={uploadFiles}
                    className='w-fit border-solid'
                    renderLeft={<Icon name='link-1' />}
                    variant='soft'
                    onClick={() => {
                      trackEvent({
                        action: 'click',
                        category: 'agents',
                        label: 'addDocuments',
                      });
                    }}
                  >
                    {t('action.addDocuments')}
                  </UploadButton>
                  <SelectDocumentsSheet onOpenChange={handleSelectedDocumentsChange}>
                    <SelectDocumentsSheet.Content allowFolderSelection />
                  </SelectDocumentsSheet>
                </div>
                {agent.documents.length > 0 && (
                  <List className='pt-2.5'>
                    {agent.documents.map((document) => (
                      <ListItem
                        key={document.uuid}
                        className='group flex items-center justify-between border-b py-2 hover:bg-fill-pressed-weak'
                      >
                        <div className='flex items-center gap-2'>
                          <Icon name={getDocumentIcon(document)} size='md' />
                          <Text>{document.name}</Text>
                        </div>
                        <IconButton
                          name='trash-4'
                          variant='unstyled'
                          size='md'
                          className='opacity-0 group-hover:opacity-100'
                          onClick={(e) => handleDeleteDocument(e, document.uuid)}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </div>
            </FormItem>
            <FormItem>
              <FormItemLabel
                label={t('form.agentConversationStarters.label')}
                htmlFor='conversationStarters'
              />
              <form.Field name='conversationStarters' mode='array'>
                {(field) => (
                  <div className='flex flex-col gap-3 border border-stroke-weaker p-6'>
                    <AgentConversationStarterDialog
                      onChange={(values) => {
                        field.pushValue(values);
                        form.handleSubmit();
                      }}
                    />
                    {field.state.value.map((conversationStarter) => (
                      <AgentConversationStarterDialog
                        key={conversationStarter.name}
                        conversationStarter={conversationStarter}
                        onDelete={() => {
                          field.handleChange(
                            field.state.value.filter((cs) => cs.name !== conversationStarter.name),
                          );
                          form.handleSubmit();
                        }}
                        onChange={(values) => {
                          field.handleChange(
                            field.state.value.map((cs) =>
                              cs.name === conversationStarter.name ? values : cs,
                            ),
                          );
                          form.handleSubmit();
                        }}
                      />
                    ))}
                  </div>
                )}
              </form.Field>
            </FormItem>
            {currentUser.role === UserRole.SUPERADMIN && (
              <FormItem>
                <FormItemLabel label={t('form.superAdminSettings.title')} />
                <div className='flex flex-col gap-1 border border-stroke-weaker p-6'>
                  <FormItemLabel label={t('form.superAdminSettings.aiModel')} htmlFor='model' />
                  <form.Field name='model'>
                    {(field) => (
                      <SelectAIModel
                        value={field.state.value}
                        onChange={(value) => {
                          field.handleChange(value);
                          form.handleSubmit();
                        }}
                      />
                    )}
                  </form.Field>
                </div>
              </FormItem>
            )}
          </div>
        </form>
      </div>
      <DocumentsUploadProgress className='mt-3' {...documentUploadProgress} />
      <ListenToAgentDocumentsStatus agent={agent} />
    </SidePaneProvider>
  );
};

export const UpdateAgentForm = withSelectedDocuments(Component);
