import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';

import type { ApiDocument } from '@legalfly/api/documents';
import { useSelectedDocuments } from '@legalfly/components/documentPicker/SelectedDocumentsProvider';
import { getDocumentIcon } from '@legalfly/components/documents/helpers';
import { Button } from '@legalfly/ui/button';
import { Divider } from '@legalfly/ui/divider';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { DownloadButton } from 'components/common/button/DownloadButton';
import { useAgents } from 'core/modules/agents';

import { DocumentSummary } from '../DocumentSummary';
import { useDocumentActions } from '../useDocumentActions';

interface Props {
  documents: ApiDocument[];
  showOpenDocument?: boolean;
}

export const DocumentsDetailPane = ({ documents, showOpenDocument = true }: Props) => {
  const { t } = useTranslation();

  const { clearSelectedDocuments } = useSelectedDocuments();

  const {
    isSingleFile,
    isSingleFolder,
    isSingleSelection,
    allowFolderActions,
    handleAnonymisation,
    handleDiscovery,
    handleReview,
    handleAgent,
    handleMultiReview,
    handleDownloadAnonimized,
    handleDownloadOriginal,
    handleDeleteDocuments,
    handleCreateDocumentsZip,
    handleCreateAnonymizedDocumentsZip,
    isDownloadingAnonimized,
    isDownloadingOriginal,
    isLoadingDeleteDocuments,
  } = useDocumentActions(documents);
  const { agents } = useAgents();

  return (
    <div>
      {isSingleSelection && showOpenDocument && (
        <>
          <Text className='mb-4 text-content-body-weak'>
            {isSingleFolder ? t('documents.detailPane.openFolder') : t('documents.detailPane.open')}
          </Text>
          <Button
            className='w-full'
            renderLeft={<Icon name={getDocumentIcon(documents[0])} />}
            onClick={() => {
              clearSelectedDocuments();
            }}
            variant='hard'
            asChild
          >
            <Link
              to={isSingleFolder ? '/documents/folder/$uuid' : '/documents/file/$uuid'}
              params={{ uuid: documents[0].uuid }}
            >
              <Text className='truncate' as='span'>
                {documents[0].name}
              </Text>
            </Link>
          </Button>
        </>
      )}

      {isSingleSelection && (
        <Divider variant='weak' className={cn(showOpenDocument ? 'my-8' : 'mb-8 mt-4')} />
      )}
      <Text className='mb-4 text-content-body-weak'>{t('documents.detailPane.agents')}</Text>
      <div className='flex flex-col gap-3'>
        <Button
          renderLeft={<Icon name='shield-tick' />}
          onClick={handleAnonymisation}
          disabled={!allowFolderActions}
        >
          {t('nav.anonymisation')}
        </Button>
        {isSingleFile ? (
          <Button renderLeft={<Icon name='check-done' />} onClick={handleReview}>
            {t('nav.review')}
          </Button>
        ) : (
          <Button
            renderLeft={<Icon name='table' />}
            disabled={!allowFolderActions}
            onClick={handleMultiReview}
          >
            {t('nav.multi-review')}
          </Button>
        )}
        <Button
          renderLeft={<Icon name='file-search' />}
          onClick={handleDiscovery}
          disabled={!allowFolderActions}
        >
          {t('nav.discovery')}
        </Button>
      </div>

      {agents.length > 0 && (
        <>
          <Divider variant='weak' className='my-8' />
          <Text className='mb-4 text-content-body-weak'>{t('nav.agents')}</Text>
          <div className='flex flex-col gap-3'>
            {agents.map((agent) => (
              <Button
                key={agent.uuid}
                renderLeft={<Icon name={agent.icon} />}
                onClick={() => handleAgent(agent.uuid)}
              >
                {agent.name}
              </Button>
            ))}
          </div>
        </>
      )}

      {(documents.length > 1 || isSingleFolder) && (
        <div>
          <Divider variant='weak' className='my-8' />
          <Text className='mb-4 mt-4 text-content-body-weak'>{t('label.action_other')}</Text>
          <div className='flex flex-col gap-3'>
            <Button
              renderLeft={<Icon name='download-1' />}
              onClick={() => handleCreateDocumentsZip()}
            >
              {t('documents.detailPane.downloadZip')}
            </Button>
            <Button
              renderLeft={<Icon name='download-1' />}
              onClick={() => handleCreateAnonymizedDocumentsZip()}
            >
              {t('documents.detailPane.downloadAnonymizedZip')}
            </Button>
            <Button
              renderLeft={<Icon name='trash-2' />}
              onClick={handleDeleteDocuments}
              isLoading={isLoadingDeleteDocuments}
            >
              {t('action.delete')}
            </Button>
          </div>
        </div>
      )}

      {isSingleFile && (
        <>
          <Divider variant='weak' className='my-8' />
          <div className='flex flex-col gap-3'>
            <DownloadButton
              variant='soft'
              className='w-full'
              onClick={handleDownloadAnonimized}
              isDownloading={isDownloadingAnonimized}
            >
              {t('documents.detailPane.download', {
                count: 1,
              })}
            </DownloadButton>
            <DownloadButton
              variant='soft'
              className='w-full'
              onClick={handleDownloadOriginal}
              isDownloading={isDownloadingOriginal}
            >
              {t('documents.detailPane.downloadOriginal')}
            </DownloadButton>
            <Suspense>
              <DocumentSummary fileUuid={documents[0].uuid} />
            </Suspense>
          </div>
        </>
      )}
    </div>
  );
};
