import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseCopilotInput } from '@legalfly/components/copilot/BaseCopilotInput';
import { requiredValidator } from '@legalfly/components/forms/validators';

import { useCopilotContext } from './CopilotProvider';

type Props = {
  size?: 'small' | 'large';
  className?: string;
  isLoading?: boolean;
};

export const CopilotInput = (props: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();

  const { onSendMessage, conversationUuid } = useCopilotContext();

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref, conversationUuid]);

  return (
    <BaseCopilotInput
      ref={ref}
      onSendMessage={onSendMessage}
      fieldPlaceholder={t('copilot.ask')}
      fieldValidators={{
        onChange: ({ value }) =>
          requiredValidator(t('form.copilotMessage.validation.required'))(value),
      }}
      {...props}
    />
  );
};

CopilotInput.displayName = 'CopilotInput';
