import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, QueryOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createUsersApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getUsers = (query: QueryOf<'users/get'> = {}) => {
    return client(api.users.list, { method: 'GET', query });
  };

  const getUser = ({ uuid }: ParamsOf<'users/:uuid/get'>) => {
    return client(api.users.detail, { method: 'GET', params: { uuid } });
  };

  const getCurrentUser = () => {
    return client(api.users.current);
  };

  const updateUser = ({
    uuid,
    body,
  }: ParamsOf<'users/:uuid/put'> & { body: BodyOf<'users/:uuid/put'> }) => {
    return client(api.users.update, { method: 'PUT', params: { uuid }, body });
  };

  const activateUser = ({ uuid }: ParamsOf<'users/:uuid/activate/put'>) => {
    return client(api.users.activate, { method: 'PUT', params: { uuid } });
  };

  const deactivateUser = ({ uuid }: ParamsOf<'users/:uuid/deactivate/put'>) => {
    return client(api.users.deactivate, { method: 'PUT', params: { uuid } });
  };

  const deleteUser = ({ uuid }: ParamsOf<'users/:uuid/delete'>) => {
    return client(api.users.delete, { method: 'DELETE', params: { uuid } });
  };

  const createUser = (body: BodyOf<'users/post'>) => {
    return client(api.users.create, { method: 'POST', body });
  };

  const updateUserAvatar = ({ file }: { file: FormData }) => {
    return client(api.users.updateAvatar, {
      method: 'PUT',
      body: file,
      headers: { 'Content-Type': undefined },
    });
  };

  return {
    getUsers,
    getUser,
    getCurrentUser,
    updateUser,
    activateUser,
    deactivateUser,
    deleteUser,
    createUser,
    updateUserAvatar,
  };
};

export type UsersApi = ReturnType<typeof createUsersApi>;
export type ApiUser = ResponseOf<'users/get'>['users'][number];
export type ApiUserStatistics = ResponseOf<'users/get'>['userStatistics'];
export type ApiUserStatistic = ResponseOf<'users/get'>['userStatistics'][string];
