import type { RefObject } from 'react';

import { cn } from '@legalfly/ui/utils';

type Props = {
  children: React.ReactNode;
  hasMessages: boolean;
  containerRef: RefObject<HTMLDivElement>;
  className?: string;
  stickyChildren?: React.ReactNode;
  stickyClassName?: string;
};

export const BaseCopilotScrollContainer = ({
  children,
  hasMessages,
  containerRef,
  className,
  stickyChildren,
  stickyClassName,
}: Props) => {
  return (
    <div
      ref={containerRef}
      className={cn(
        'relative flex flex-col overflow-auto bg-fill-maximal',
        hasMessages && 'flex-grow',
        className,
      )}
    >
      <div
        className={cn(
          'sticky top-0 z-10 flex max-h-11 min-h-11 w-full bg-fill-maximal',
          stickyClassName,
        )}
      >
        {stickyChildren}
      </div>
      {children}
    </div>
  );
};

BaseCopilotScrollContainer.displayName = 'BaseCopilotScrollContainer';
