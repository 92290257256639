import { type PropsWithChildren, useMemo } from 'react';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Focus from '@tiptap/extension-focus';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import { EditorContent, type EditorProviderProps, useEditor } from '@tiptap/react';

import { cn } from 'utils';

import { InputErrorMessage } from '../input/InputErrorMessage';
import { Toolbar } from './Toolbar';

const DEFAULT_EXTENSIONS = [
  Document,
  Paragraph,
  Text,
  Underline,
  Bold,
  Italic,
  Strike,
  BulletList,
  OrderedList,
  ListItem,
  Focus,
] as const;

interface EditorProps extends Omit<EditorProviderProps, 'content' | 'onUpdate'> {
  value: EditorProviderProps['content'];
  parentClassName?: string;
  className?: string;
  errorMessage?: string;
  errorClassName?: string;
  onChange?: EditorProviderProps['onUpdate'];
}

const getDefaultEditorClass = (className?: EditorProps['className']) =>
  cn(
    'w-full p-0 [&_ol]:list-decimal [&_ol]:ps-4 [&_ul]:list-disc [&_ul]:ps-4',
    'focus-visible:outline-0',
    'text-body-light text-content-body-strong',
    'disabled:pointer-events-none disabled:cursor-not-allowed disabled:text-content-body-disabled disabled:placeholder:text-content-body-disabled',
    className,
  );

export const Editor = ({
  parentClassName,
  className,
  errorMessage,
  errorClassName,
  children,
  extensions,
  value,
  onBlur,
  ...props
}: PropsWithChildren<EditorProps>) => {
  const combinedExtensions = useMemo(
    () => [...DEFAULT_EXTENSIONS, ...(extensions || [])],
    [extensions],
  );

  const editor = useEditor(
    {
      content: value,
      extensions: combinedExtensions,
      onBlur,
      autofocus: true,
      editorProps: {
        ...props.editorProps,
        attributes: {
          ...props.editorProps?.attributes,
          class: cn(getDefaultEditorClass(className)),
        },
      },
    },
    [value],
  );

  return (
    <div className={cn('w-full', parentClassName)}>
      <EditorContent editor={editor} />
      {children}
      <InputErrorMessage errorMessage={errorMessage} errorClassName={errorClassName} />
    </div>
  );
};

Editor.Toolbar = Toolbar;
