/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type ColumnDef } from '@tanstack/react-table';

import { type ApiDocument } from '@legalfly/api/documents';
import { FavoriteTypeEnum } from '@legalfly/api/favorites';
import type { UseColumnsProps } from '@legalfly/components/documentPicker/BaseDocumentPicker';
import { NameColumn } from '@legalfly/components/documentPicker/columns/name';
import { SelectColumn } from '@legalfly/components/documentPicker/columns/select';
import { UserAvatar } from '@legalfly/components/users/UserAvatar';
import { ContextMenu, ContextMenuTrigger } from '@legalfly/ui/contextMenu';
import { Text } from '@legalfly/ui/text';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';
import { FavoriteButton } from 'components/common/button/FavoriteButton';
import { useRetryFile } from 'core/modules/documents';

import { DocumentsContextMenu } from '../contextMenu/DocumentsContextMenu';
import { DocumentListMenu } from '../DocumentListMenu';

export const useDocumentPickerColumns = ({
  onDocumentClick,
  onDocumentDoubleClick,
  allowMultiple,
  allowSelection,
  allowContextMenu,
  allowDelete,
  allowFavorite,
}: UseColumnsProps) => {
  const { i18n } = useTranslation();
  const { retryFile } = useRetryFile();

  const columns = useMemo(() => {
    const columnDefs: ColumnDef<ApiDocument>[] = [
      {
        id: 'select',
        size: 50,
        header: ({ table }) => SelectColumn.Header({ allowMultiple, table }),
        cell: SelectColumn.Cell,
      },
      {
        id: 'name',
        accessorKey: 'name',
        header: NameColumn.Header,
        cell: ({ row, table }) => {
          const document = row.original;
          const selectedDocuments = table.getSelectedRowModel().rows.map((r) => r.original);
          const canSelect = row.getCanSelect();
          const disabled = !allowContextMenu || !canSelect;

          return (
            <ContextMenu>
              <ContextMenuTrigger asChild disabled={disabled}>
                <div
                  className='flex items-center gap-2 px-5 py-5 focus-visible:outline focus-visible:outline-1 focus-visible:-outline-offset-8 focus-visible:outline-stroke-weak'
                  onContextMenu={() => {
                    if (disabled) {
                      return;
                    }
                    // when right clicking on a document that is not selected
                    // deselect all other documents and then select the document
                    if (!selectedDocuments.some((d) => d.uuid === document.uuid)) {
                      table.toggleAllRowsSelected(false);
                      setTimeout(() => {
                        row.toggleSelected(true);
                      }, 0);
                    }
                  }}
                  onClick={(e) => {
                    switch (e.detail) {
                      case 0:
                      case 1:
                        onDocumentClick(document);
                        break;
                      case 2:
                        onDocumentDoubleClick?.(document);
                        break;
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.currentTarget.click();
                    }
                  }}
                  role='row'
                  tabIndex={0}
                >
                  <NameColumn.CellContent document={document} onRetryFile={retryFile} />
                </div>
              </ContextMenuTrigger>
              <DocumentsContextMenu document={document} selectedDocuments={selectedDocuments} />
            </ContextMenu>
          );
        },
      },
      {
        id: 'actions',
        size: 300,
        cell: ({ row }) => {
          const document = row.original;

          return (
            <div className='flex items-center justify-end gap-2 px-5'>
              {allowFavorite && (
                <FavoriteButton
                  className='opacity-0 transition-opacity duration-500 focus:opacity-100 group-hover:opacity-100'
                  entityType={FavoriteTypeEnum.DOCUMENT}
                  entity={document}
                />
              )}
              <Text
                className='text-content-body-weak'
                title={formatLongDate(document.updatedAt, i18n.language)}
              >
                {formatRelativeTime(document.updatedAt, i18n.language)}
              </Text>
              {document.owner && <UserAvatar size='sm' {...document.owner} />}
              <DocumentListMenu
                document={document}
                allowDelete={allowDelete}
                allowFavorite={allowFavorite}
              />
            </div>
          );
        },
      },
    ];

    if (!allowSelection) {
      return columnDefs.filter((column) => column.id !== 'select');
    }

    return columnDefs;
  }, [
    allowSelection,
    allowMultiple,
    allowContextMenu,
    retryFile,
    allowDelete,
    onDocumentClick,
    onDocumentDoubleClick,
    allowFavorite,
    i18n.language,
  ]);

  return columns;
};
