import { useTranslation } from 'react-i18next';

import type { ApiPlaybook } from '@legalfly/api/playbooks';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { List, ListItem } from '@legalfly/ui/list';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@legalfly/ui/sheet';

interface Props {
  playbook: ApiPlaybook;
  topOffset?: string;
}

export const PlaybookItemsSheet = ({ playbook, topOffset = '0' }: Props) => {
  const { t } = useTranslation('components');

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button size='sm' variant='soft'>
          {t('action.view')}
        </Button>
      </SheetTrigger>
      <SheetContent
        style={{
          top: topOffset,
        }}
        className='max-w-[90%] overflow-auto bg-fill-maximal sm:max-w-[80%] md:max-w-[60%] lg:max-w-[40%]'
      >
        <SheetHeader className='mb-4 bg-fill-maximal'>
          <SheetTitle>{playbook.name}</SheetTitle>
          <SheetClose />
        </SheetHeader>
        <List>
          {playbook.items.map((item) => (
            <ListItem key={item.uuid} className='justify-start gap-3'>
              <Icon name='list' />
              {item.name || item.question}
            </ListItem>
          ))}
        </List>
      </SheetContent>
    </Sheet>
  );
};
