import type { HTMLAttributes, ReactNode } from 'react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Card, CardFooter, CardIcon, CardSubtitle, CardTitle } from '@legalfly/ui/card';
import { useToast } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isSingle?: boolean;
  disabled?: boolean;
  withBackground?: boolean;
  onDropFiles: (files: File[]) => void;
  renderActions?: () => ReactNode;
}

export const DropZone = ({
  onDropFiles,
  isSingle = false,
  className,
  children,
  disabled = false,
  withBackground = false,
  renderActions,
  ...props
}: Props) => {
  const { toast } = useToast();
  const { t } = useTranslation();

  const onDrop = useCallback(
    (files: File[]) => {
      if (isSingle && files.length > 1) {
        toast({
          title: t('toasts.upload.single.title'),
          description: t('toasts.upload.single.description'),
          variant: 'danger',
        });
        return;
      }
      onDropFiles(files);
    },
    [isSingle, t, toast, onDropFiles],
  );

  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  const card = () => {
    return (
      <Card>
        <CardIcon name='upload-2' />
        <CardTitle className='mb-2'>{t('uploader.title')}</CardTitle>
        <CardSubtitle>{t('uploader.subtitle')}</CardSubtitle>
        <CardFooter>{renderActions?.()}</CardFooter>
      </Card>
    );
  };

  if (disabled) {
    return (
      <div className={className} {...props}>
        {children}
      </div>
    );
  }

  return (
    <div
      {...getRootProps()}
      className={cn(
        'relative flex w-full flex-1 flex-grow flex-col overflow-hidden',
        'border border-dashed border-transparent',
        withBackground &&
          'z-0 from-dropzone-start to-dropzone-stop/20 mask-blocked-background after:animate-dropzone',
        className,
        isDragActive && `border-stroke-strong`,
      )}
      {...props}
    >
      {children ? (
        children
      ) : (
        <div className={cn('flex flex-1 flex-col items-center justify-center')}>{card()}</div>
      )}
      {isDragActive && (
        <div className='absolute left-0 top-0 z-10 flex h-full w-full flex-1 items-center justify-center bg-risk-glow-neutral/30'>
          {card()}
        </div>
      )}
    </div>
  );
};
