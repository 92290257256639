import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { FormItemLabel } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { Input } from '@legalfly/ui/input';

import { PlaybookItemTooltip } from '../PlaybookItemTooltip';

export const PlaybookItemNameField = (props: ComponentProps<typeof Input>) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='mb-2 flex items-center gap-2'>
        <FormItemLabel label={`${t('form.name.label')}*`} htmlFor='name' />
        <PlaybookItemTooltip description={t('tooltips.playbooks.name')}>
          <Icon name='info-circle' className='-mt-3' />
        </PlaybookItemTooltip>
      </div>

      <Input {...props} />
    </>
  );
};
