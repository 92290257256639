import { useTranslation } from 'react-i18next';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';
import { useTableHeight } from 'common/hooks/useTableHeight';

import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, UploadButton } from '@legalfly/ui/button';
import { SearchInputForm } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@legalfly/ui/table';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';
import { ContentDropZone } from 'components/common/content/ContentDropZone';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { ContentSidebar } from 'components/common/content/ContentSidebar';
import { DropZone } from 'components/common/upload/DropZone';
import { getMultiReviewName } from 'components/multi-reviews/helpers/getMultiReviewName';
import { MultiReviewsInsights } from 'components/multi-reviews/MultiReviewsInsights';
import { MultiReviewsTableActionPopover } from 'components/multi-reviews/MultiReviewsTableActionPopover';
import { useCreateFiles } from 'core/modules/documents/helpers/useCreateFiles';
import { multiReviewsQueryOptions, useMultiReviews } from 'core/modules/multi-reviews';
import { playbookQueryOptions } from 'core/modules/playbooks';

export const Route = createFileRoute('/_auth/_layout/multi-review/')({
  component: MultiReviewOverviewRoute,
  loader({ context }) {
    return Promise.all([
      context.queryClient.ensureQueryData(playbookQueryOptions.playbooks()),
      context.queryClient.fetchQuery(multiReviewsQueryOptions.multiReviews()),
    ]);
  },
});

function MultiReviewOverviewRoute() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { multiReviews, stats } = useMultiReviews();
  const { tableHeight, tableRef } = useTableHeight();

  const { createFiles, isCreatingFiles } = useCreateFiles('multi-review');
  const { filteredItems: filteredMultiReviews, setKeyword } = useKeywordFilter(
    multiReviews,
    'name',
  );

  const uploadFiles = async (files: File[]) => {
    const results = await createFiles({ uuid: undefined, files });

    if (results.length) {
      navigate({
        to: '/multi-review/start',
      });
    }
  };

  const hasReviews = multiReviews.length > 0;

  return (
    <div className='flex gap-3'>
      <ContentDropZone
        parentClassName='flex-1'
        onDropFiles={(files: File[]) => {
          trackEvent({
            action: 'drop',
            category: 'multiReview',
          });
          uploadFiles(files);
        }}
        disabled={!hasReviews}
      >
        <ContentHeader>
          <ContentHeader.Title>{t('multiReview.title')}</ContentHeader.Title>
          <ContentHeader.SubTitle>{t('multiReview.subtitle')}</ContentHeader.SubTitle>
          <ContentHeader.Actions>
            <SearchInputForm
              placeholder={t('label.search')}
              onKeywordChange={setKeyword}
              onBlur={() =>
                trackEvent({
                  action: 'blur',
                  category: 'multiReview',
                  label: 'search',
                })
              }
            />
            <Link to='/multi-review/start' disabled={isCreatingFiles}>
              <Button renderLeft={<Icon name='folder' />} disabled={isCreatingFiles} variant='soft'>
                {t('multiReview.actions.select')}
              </Button>
            </Link>
            <UploadButton
              onUpload={uploadFiles}
              renderLeft={<Icon name='upload-1' />}
              isLoading={isCreatingFiles}
              onClick={() =>
                trackEvent({
                  action: 'click',
                  category: 'multiReview',
                  label: 'upload',
                })
              }
            >
              {t('action.uploadOrDrop')}
            </UploadButton>
          </ContentHeader.Actions>
        </ContentHeader>

        {hasReviews ? (
          <Table
            parentClassName='mb-4 h-fit overflow-y-auto relative'
            ref={tableRef}
            style={{ maxHeight: tableHeight }}
          >
            <TableHeader className='sticky top-0 z-10 bg-surface-base'>
              <TableRow className='shadow-table-border'>
                <TableHead>{t('multiReview.list.name')}</TableHead>
                <TableHead>{t('multiReview.list.date')}</TableHead>
                <TableHead>{t('multiReview.list.actions')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredMultiReviews.map((multiReview) => {
                const handleOnClick = () => {
                  navigate({
                    to: '/multi-review/$uuid',
                    params: { uuid: multiReview.uuid },
                  });
                };
                return (
                  <TableRow key={multiReview.uuid} className='cursor-pointer'>
                    <TableCell onClick={handleOnClick} className='break-all text-body-semibold'>
                      {getMultiReviewName(multiReview)}
                    </TableCell>
                    <TableCell
                      onClick={handleOnClick}
                      title={formatLongDate(multiReview.createdAt, i18n.language)}
                    >
                      {formatRelativeTime(multiReview.createdAt, i18n.language)}
                    </TableCell>
                    <TableCell>
                      <MultiReviewsTableActionPopover multiReview={multiReview} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <DropZone
            onDropFiles={(files: File[]) => {
              trackEvent({
                action: 'drop',
                category: 'multiReview',
              });
              uploadFiles(files);
            }}
            withBackground
            renderActions={() => (
              <div className='flex flex-col gap-3'>
                <UploadButton
                  onUpload={uploadFiles}
                  renderLeft={<Icon name='upload-1' />}
                  onClick={() =>
                    trackEvent({
                      action: 'click',
                      category: 'multiReview',
                      label: 'upload',
                    })
                  }
                >
                  {t('multiReview.actions.upload')}
                </UploadButton>
                <Button variant='soft' renderLeft={<Icon name='folder' />} asChild>
                  <Link to='/multi-review/start'>{t('multiReview.actions.select')}</Link>
                </Button>
              </div>
            )}
          />
        )}
      </ContentDropZone>
      {hasReviews && (
        <ContentSidebar>
          <MultiReviewsInsights stats={stats} />
        </ContentSidebar>
      )}
    </div>
  );
}
