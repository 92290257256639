import { useDownload } from './useDownload';

export const useDownloadBlobWithProgress = ({
  blob,
  fileName,
}: {
  blob: Blob;
  fileName: string;
}) => {
  const {
    progress,
    isDownloading,
    setProgress,
    setIsDownloading,
    cancelDownload,
    performDownload,
  } = useDownload();

  const handleDownload = async () => {
    if (isDownloading) {
      cancelDownload();
      return;
    }

    setIsDownloading(true);
    setProgress(0);
    const blobUrl = URL.createObjectURL(blob);
    await performDownload(blobUrl, fileName);
    URL.revokeObjectURL(blobUrl);
    setIsDownloading(false);
  };

  return { progress, isDownloading, handleDownload };
};
