import type { ApiDocument, ApiDocumentTypeFile } from '@legalfly/api/documents';
import { DocumentStatus, DocumentType } from '@legalfly/api/documents';
import type { IconName } from '@legalfly/ui/icon';

export const isDocumentFolder = (document: ApiDocument) => document.type === DocumentType.FOLDER;
export const isDocumentFile = (document: ApiDocument) => document.type === DocumentType.FILE;
export const isDocumentProcessing = (document: ApiDocumentTypeFile) => {
  return document.status !== DocumentStatus.COMPLETE && document.status !== DocumentStatus.ERROR;
};

export const getExtension = (filename: string) =>
  filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;

export const getFileIcon = (fileType: string): IconName => {
  switch (fileType) {
    case 'pdf':
      return 'file-pdf';
    case 'doc':
      return 'file-doc';
    case 'png':
      return 'file-png';
    case 'jpg':
      return 'file-jpg';
    default:
      return 'file-2';
  }
};

export const getDocumentIcon = (document: ApiDocument): IconName => {
  if (isDocumentFolder(document)) return 'folder';
  return getFileIcon(document.fileType);
};

export const getDocumentName = (document: ApiDocument) => {
  const { name } = document;
  if (isDocumentFolder(document)) return name;
  return `${name}.${document.extension}`;
};
