import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton } from '@legalfly/ui/button';
import { DocumentActions } from 'components/documents/detail/DocumentActions';
import { DocumentProvider } from 'components/documents/detail/DocumentProvider';
import { DocumentViewer } from 'components/documents/detail/DocumentViewer';
import ReviewDetailTabs from 'components/review/detail/ReviewDetailTabs';
import { EditableReviewName } from 'components/review/EditableReviewName';
import { reviewsQueryOptions, useListenToReviewStatus, useReview } from 'core/modules/reviews';

export const Route = createFileRoute('/_auth/_layout/review/$uuid')({
  component: ReviewDetailRoute,
  loader({ context, params }) {
    return context.queryClient.ensureQueryData(reviewsQueryOptions.review({ uuid: params.uuid }));
  },
});

function ReviewDetailRoute() {
  const { t } = useTranslation();
  const { uuid } = Route.useParams();
  const navigate = useNavigate();

  const { review } = useReview({ uuid });

  const [activeSnippetTextId, setActiveSnippetTextId] = useState<string>();
  const [activeSnippetRiskLevel, setActiveSnippetRiskLevel] = useState<string>();

  const handleHighlightItem = useCallback((snippetId: string, riskLevel: string) => {
    trackEvent(
      {
        action: 'click',
        category: 'reviewDetail',
        label: 'highlight',
      },
      { riskLevel },
    );
    setActiveSnippetTextId(snippetId);
    setActiveSnippetRiskLevel(riskLevel);
  }, []);

  useListenToReviewStatus({ uuid });

  return (
    <div className='flex gap-3'>
      <div className='flex h-content w-3/5 flex-col gap-3'>
        <DocumentProvider document={review.document}>
          <DocumentActions className='flex items-center gap-2'>
            <IconButton
              name='chevron-left'
              onClick={() => navigate({ to: '/review' })}
              variant='tertiary'
              size='sm'
            />
            <EditableReviewName review={review} />
            <DocumentActions.Container>
              <DocumentActions.ShareButton
                title={t('dialog.share.review.title')}
                description={t('dialog.share.review.description')}
                entityType='review'
                entityUuid={review.uuid}
              />
              <DocumentActions.AnonymousButton />
              <DocumentActions.DownloadMenu>
                <DocumentActions.DownloadMenu.MenuItemDownloadCsv review={review} />
              </DocumentActions.DownloadMenu>
            </DocumentActions.Container>
          </DocumentActions>
          <div className='h-full overflow-auto bg-fill-maximal px-content-x py-content-y'>
            <DocumentViewer
              activeSnippetTextId={activeSnippetTextId}
              activeSnippetRiskLevel={activeSnippetRiskLevel}
            />
          </div>
        </DocumentProvider>
      </div>
      <div className='relative h-content w-2/5 overflow-auto bg-fill-maximal px-6 pb-6'>
        <ReviewDetailTabs
          review={review}
          onHighlightItem={handleHighlightItem}
          activeSnippetTextId={activeSnippetTextId ?? ''}
        />
      </div>
    </div>
  );
}
