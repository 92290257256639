import type { ComponentProps } from 'react';

import { BaseSelectDocumentsSheet } from '@legalfly/components/documentPicker/BaseSelectDocumentsSheet';
import { useSelectedDocuments } from '@legalfly/components/documentPicker/SelectedDocumentsProvider';
import { isDocumentFile, isDocumentFolder } from '@legalfly/components/documents/helpers';
import { useCountFolderDocuments } from 'core/modules/documents';

import { SheetDocumentPicker } from './SheetDocumentPicker';

type Props = Omit<
  ComponentProps<typeof BaseSelectDocumentsSheet>,
  'selectedDocumentsCount' | 'containerClassName'
>;

export const SelectDocumentsSheet = (props: Props) => {
  const { selectedDocuments } = useSelectedDocuments();

  const selectedFolders = selectedDocuments.filter(isDocumentFolder);
  const selectedFiles = selectedDocuments.filter(isDocumentFile);

  const { numberOfDocuments } = useCountFolderDocuments({
    uuids: selectedFolders.map((d) => d.uuid),
    enabled: Boolean(selectedFolders.length),
  });

  const selectedDocumentsCount = selectedFolders.length
    ? numberOfDocuments + selectedFiles.length
    : selectedFiles.length;

  return (
    <BaseSelectDocumentsSheet
      selectedDocumentsCount={selectedDocumentsCount}
      containerClassName='max-w-[90%] sm:max-w-[80%] md:max-w-[60%] lg:max-w-[40%]'
      {...props}
    />
  );
};

SelectDocumentsSheet.Content = SheetDocumentPicker;
