import { initReactI18next } from 'react-i18next';
import type { BackendModule, InitOptions } from 'i18next';
import i18n from 'i18next';

import { legalFlyConfig } from '@legalfly/config';
import { noop } from '@legalfly/utils/fn';

const LazyImportPlugin: BackendModule = {
  type: 'backend',
  init: noop,
  read: async (language, namespace, callback) => {
    const jsonFile = (await import(`./translations/${namespace}/${language}.json`)).default;
    callback(null, jsonFile);
  },
};

export const initI18n = (options: InitOptions) =>
  i18n
    .use(LazyImportPlugin)
    .use(initReactI18next)
    .init<InitOptions>({
      supportedLngs: legalFlyConfig.supportedLanguages,
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      appendNamespaceToCIMode: true,
      react: {
        useSuspense: false,
      },
      lng: legalFlyConfig.defaultLanguage,
      ...options,
    });
