import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDefaultJurisdiction } from 'common/hooks/useDefaultJurisdiction';

import { ConversationTypeEnum } from '@legalfly/api/conversations';
import type { ApiReviewDetail, ApiReviewItem } from '@legalfly/api/reviews';
import ReviewDetailItems from '@legalfly/components/review/ReviewDetailItems';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@legalfly/ui/tabs';
import { withToasts } from '@legalfly/ui/toast';
import { useCreateConversationMessage } from 'core/modules/conversations';
import { conversationToasts } from 'core/modules/conversations/toasts';
import { useListenToReviewItem } from 'core/modules/reviews';

import { CommentsPopover } from '../../common/comments/CommentsPopover';
import { Copilot } from '../../conversations/copilot/Copilot';
import { ReviewDetailPrompts } from './ReviewDetailPrompts';

type TabValues = 'copilot' | 'items';

interface Props {
  review: ApiReviewDetail;
  onHighlightItem: (snippetId: string, riskLevel: string) => void;
  activeSnippetTextId: string;
}

const ReviewDetailTabs = ({ review, onHighlightItem, activeSnippetTextId }: Props) => {
  const { t } = useTranslation();
  const defaultJurisdiction = useDefaultJurisdiction();

  const [tab, setTab] = useState<TabValues>('items');
  const { createConversationMessage } = useCreateConversationMessage({
    type: ConversationTypeEnum.REVIEW,
  });

  const conversationUuid = review.conversations[0]?.uuid;

  const handleSendMessage = async (msg: string) => {
    await withToasts(
      createConversationMessage({
        uuid: conversationUuid,
        context: {
          documentUuids: [review.document.uuid],
        },
        question: msg,
        jurisdiction: review.jurisdiction ?? defaultJurisdiction,
      }),
    )(conversationToasts.createConversation());
  };

  const handleClickExplainItem = (item: ApiReviewItem) => {
    trackEvent({
      action: 'click',
      category: 'reviewDetail',
      label: 'explain',
    });
    setTab('copilot');

    const prompt = `
      ${t('copilot.prompt.explain.preText')}

      ${item.text}

      ${item.answer.text}
      
      ${t('copilot.prompt.explain.postText')}
    `.trim();
    handleSendMessage(prompt);
  };

  const handleClickRedraftItem = (item: ApiReviewItem) => {
    trackEvent({
      action: 'click',
      category: 'reviewDetail',
      label: 'redraft',
    });
    setTab('copilot');

    const redraftInstructions = item.context?.redraftInstructions
      ? `<span hidden>Redraft instructions: ${item.context.redraftInstructions}</span>`
      : '';

    const prompt = `
      ${t('copilot.prompt.redraft.preText')}

      ${item.text}

      ${item.answer.text}

      ${redraftInstructions} ${t('copilot.prompt.redraft.postText')}
    `.trim();
    handleSendMessage(prompt);
  };

  return (
    <Tabs
      onValueChange={(v) => {
        trackEvent(
          {
            action: 'click',
            category: 'reviewDetail',
            label: 'tab',
          },
          { tab: v },
        );
        setTab(v as TabValues);
      }}
      value={tab}
    >
      <div className='sticky top-0 z-50 bg-fill-maximal pb-4 pt-6'>
        <TabsList>
          <TabsTrigger value='copilot'>{t('review.detail.tabs.copilot')}</TabsTrigger>
          <TabsTrigger value='items'>{t('review.detail.tabs.items')}</TabsTrigger>
        </TabsList>
      </div>
      <TabsContent
        value='copilot'
        style={{
          height:
            // tabs padding top - 24px
            // tabs padding bottom - 16px
            // list padding bottom - 24px
            'calc(var(--lf-content-height) - var(--lf-tabs-list-height) - 24px - 16px - 24px)',
        }}
      >
        <Copilot
          conversationHasMessages
          onSendMessage={handleSendMessage}
          conversationType={ConversationTypeEnum.REVIEW}
          conversationUuid={conversationUuid}
        >
          <Copilot.ScrollContainer stickyClassName='hidden'>
            <ReviewDetailPrompts onSendMessage={handleSendMessage} />
            <Copilot.Messages
              onClickDocumentSnippet={onHighlightItem}
              analyticsMeta={{ category: 'reviewDetail' }}
            />
          </Copilot.ScrollContainer>
          <Copilot.Footer className='mt-auto w-full pt-4'>
            <Copilot.StopResponding />
            <Copilot.Input className='mb-0' />
          </Copilot.Footer>
        </Copilot>
      </TabsContent>
      <TabsContent value='items'>
        {review.items.map((item) => (
          <ReviewItemListener key={item.uuid} reviewUuid={review.uuid} itemUuid={item.uuid} />
        ))}
        <ReviewDetailItems
          review={review}
          onClickExplain={handleClickExplainItem}
          onClickRedraft={handleClickRedraftItem}
          onClickHighlight={onHighlightItem}
          activeSnippetTextId={activeSnippetTextId}
          renderItemActions={(item) => (
            <CommentsPopover entityUuid={item.uuid} entityType='reviewPlaybookItem' />
          )}
        />
      </TabsContent>
    </Tabs>
  );
};

const ReviewItemListener = ({
  reviewUuid,
  itemUuid,
}: {
  reviewUuid: ApiReviewDetail['uuid'];
  itemUuid: ApiReviewItem['uuid'];
}) => {
  useListenToReviewItem({ reviewUuid, itemUuid });

  return null;
};

export default memo(ReviewDetailTabs);
