import type { BodyOf, ParamsOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';
import type { ConversationTypeEnum } from './types';

export const createBaseConversationsApi = (fetcher: Fetcher, type: ConversationTypeEnum) => {
  const client = createClient(fetcher);

  return {
    getConversations: (): Promise<ResponseOf<`:entityType/conversations/get`>> =>
      client(`/:entityType/conversations`, {
        method: 'GET',
        params: { entityType: type },
      }),

    getConversation: ({
      uuid,
    }: ParamsOf<`:entityType/conversations/:uuid/get`>): Promise<
      ResponseOf<`:entityType/conversations/:uuid/get`>
    > =>
      client(`/:entityType/conversations/:uuid`, {
        method: 'GET',
        params: { uuid, entityType: type },
      }),

    createConversation: (
      body: BodyOf<`:entityType/conversations/post`>,
    ): Promise<ResponseOf<`:entityType/conversations/post`>> =>
      client(`/:entityType/conversations`, {
        method: 'POST',
        body,
        params: { entityType: type },
      }),

    createConversationMessage: (
      body: BodyOf<`:entityType/conversations/message/post`>,
    ): Promise<ResponseOf<`:entityType/conversations/message/post`>> =>
      client(`/:entityType/conversations/message`, {
        method: 'POST',
        body,
        params: { entityType: type },
      }),

    updateConversation: ({
      uuid,
      body,
    }: ParamsOf<`:entityType/conversations/:uuid/patch`> & {
      body: BodyOf<`:entityType/conversations/:uuid/patch`>;
    }): Promise<ResponseOf<`:entityType/conversations/:uuid/patch`>> =>
      client(`/:entityType/conversations/:uuid`, {
        method: 'PATCH',
        params: { uuid, entityType: type },
        body,
      }),

    deleteConversation: ({
      uuid,
    }: ParamsOf<`:entityType/conversations/:uuid/delete`>): Promise<
      ResponseOf<`:entityType/conversations/:uuid/delete`>
    > =>
      client(`/:entityType/conversations/:uuid`, {
        method: 'DELETE',
        params: { uuid, entityType: type },
      }),

    clearConversation: ({
      uuid,
    }: ParamsOf<`:entityType/conversations/:uuid/clear/post`>): Promise<
      ResponseOf<`:entityType/conversations/:uuid/clear/post`>
    > =>
      client(`/:entityType/conversations/:uuid/clear`, {
        method: 'POST',
        params: { uuid, entityType: type },
      }),

    stopConversationMessage: ({
      uuid,
      messageUuid,
    }: ParamsOf<`:entityType/conversations/:uuid/message/:messageUuid/stop/post`>): Promise<
      ResponseOf<`:entityType/conversations/:uuid/message/:messageUuid/stop/post`>
    > =>
      client(`/:entityType/conversations/:uuid/message/:messageUuid/stop`, {
        method: 'POST',
        params: { uuid, messageUuid, entityType: type },
      }),

    modifyConversationMessage: ({
      uuid,
      messageUuid,
      body,
    }: ParamsOf<`:entityType/conversations/:uuid/message/:messageUuid/modify/post`> & {
      body: BodyOf<`:entityType/conversations/:uuid/message/:messageUuid/modify/post`>;
    }): Promise<ResponseOf<`:entityType/conversations/:uuid/message/:messageUuid/modify/post`>> =>
      client(`/:entityType/conversations/:uuid/message/:messageUuid/modify`, {
        method: 'POST',
        params: { uuid, messageUuid, entityType: type },
        body,
      }),
  };
};
