import { useTranslation } from 'react-i18next';

import { Text } from '@legalfly/ui/text';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@legalfly/ui/tooltip';
import { cn } from '@legalfly/ui/utils';
import { formatRelativeTime } from '@legalfly/utils/date';

function getNow() {
  return new Date();
}

function getFiveMinutesAgo() {
  return new Date(getNow().getTime() - 5 * 60 * 1000);
}

function getAnHourAgo() {
  return new Date(getNow().getTime() - 1 * 60 * 60 * 1000);
}

interface AvatarStatusProps {
  lastActivity: Date;
}

export const AvatarStatus = ({ lastActivity }: AvatarStatusProps) => {
  const formattedLastActivity = new Date(lastActivity);
  const { i18n } = useTranslation();

  const className = cn('h-3 w-3 rounded-full', {
    'bg-content-body-success': formattedLastActivity >= getFiveMinutesAgo(),
    'bg-content-body-warning':
      formattedLastActivity >= getAnHourAgo() && formattedLastActivity < getFiveMinutesAgo(),
    'bg-content-body-info': formattedLastActivity < getAnHourAgo(),
  });

  return (
    <div className='absolute bottom-[-12px] right-[-12px] flex h-8 w-8 items-center justify-center'>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className={className} />
          </TooltipTrigger>
          <TooltipContent side='top'>
            <Text>{formatRelativeTime(lastActivity, i18n.language)}</Text>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
