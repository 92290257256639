import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiNotification } from '@legalfly/api/notifications';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, IconButton } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Popover, PopoverContent, PopoverTrigger } from '@legalfly/ui/popover';
import { Spinner } from '@legalfly/ui/spinner';
import { Text } from '@legalfly/ui/text';
import type { ToastProps } from '@legalfly/ui/toast';
import { useToast, withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { useWebSocketSubscriber } from '@legalfly/websockets';
import {
  notificationToasts,
  useDeleteAllNotifications,
  useMarkAllNotificationsAsSeen,
  useMarkNotificationAsSeen,
  useNotifications,
} from 'core/modules/notifications';
import { useHandleNotificationClick } from 'core/modules/notifications/useHandleNotificationClick';

import Notification from './Notification';

const NOTIFICATION_TYPE_VARIANTS: Record<ApiNotification['type'], ToastProps['variant']> = {
  FILE_UPLOADED: 'success',
  MULTI_REVIEW_COMPLETED: 'success',
  FILE_UPLOAD_FAILED: 'danger',
  QUEUE_ERROR: 'danger',
  ZIP_CREATION_FINISHED: 'success',
  ZIP_CREATION_FAILED: 'danger',
} as const;

const Notifications = () => {
  const { t } = useTranslation();
  const { toast, dismiss } = useToast();

  const [isOpen, setIsOpen] = useState(false);

  const { notifications, refetch: refetchNotifications } = useNotifications();
  const { markNotificationAsSeen } = useMarkNotificationAsSeen();
  const { markAllNotificationsAsSeen } = useMarkAllNotificationsAsSeen();
  const { deleteAllNotifications } = useDeleteAllNotifications();

  const { handleNotificationClick, isLoading } = useHandleNotificationClick();

  useWebSocketSubscriber('notification', (notification) => {
    refetchNotifications();
    if (!isOpen) {
      toast({
        title: t(`config.notifications.${notification.type}.title`),
        description: t(`config.notifications.${notification.type}.description`, {
          ...('entityName' in notification.metadata && {
            entityName: notification.metadata.entityName,
          }),
        }),
        variant: NOTIFICATION_TYPE_VARIANTS[notification.type],
        onClose: () => {
          withToasts(markNotificationAsSeen(notification.uuid))(
            notificationToasts.markNotificationAsSeen(),
          );
        },
        onClick: () => {
          handleNotificationClick(notification as ApiNotification);
        },
      });
    }
  });

  useEffect(() => {
    if (isOpen && notifications.length) {
      if (notifications.some((notification) => !notification.seenAt)) {
        withToasts(markAllNotificationsAsSeen())(notificationToasts.markAllNotificationsAsSeen());
      }
    }
  }, [isOpen, markAllNotificationsAsSeen, notifications]);

  const handleDeleteAllNotifications = () => {
    trackEvent({
      action: 'click',
      category: 'notifications',
      label: 'deleteAll',
    });
    withToasts(deleteAllNotifications())(notificationToasts.deleteAllNotifications());
  };

  const unseenNotifications = notifications.filter((notification) => !notification.seenAt);
  return (
    <Popover
      open={isOpen}
      onOpenChange={() => {
        dismiss();
        setIsOpen(!isOpen);
      }}
    >
      {isLoading && <Spinner />}
      <PopoverTrigger asChild>
        <div className='relative'>
          {unseenNotifications.length > 0 && (
            <div
              className={cn(
                'pointer-events-none absolute -right-1 -top-2 flex h-5 min-h-5 min-w-5 items-center justify-center rounded-full bg-risk-fill-high px-1 text-right text-surface-white',
              )}
            >
              <Text variant='caption' className='text-surface-white'>
                {unseenNotifications.length > 99 ? '99+' : unseenNotifications.length}
              </Text>
            </div>
          )}
          <IconButton
            name='bell'
            variant='tertiary'
            size='sm'
            onClick={() => {
              if (!isOpen) {
                trackEvent({
                  action: 'click',
                  category: 'notifications',
                  label: 'open',
                });
              }
            }}
          />
        </div>
      </PopoverTrigger>
      <PopoverContent className='flex w-[350px] flex-col' align='end'>
        <Text variant='heading2' className='py-2 text-center text-[12px]'>
          {t('config.notifications.title')}
        </Text>
        {notifications.length > 0 ? (
          <>
            <div className='max-h-[500px] overflow-y-auto'>
              {notifications.map((notification, index) => (
                <Notification
                  key={index}
                  isLast={index === notifications.length - 1}
                  notification={notification}
                />
              ))}
            </div>
            <Button
              className='mx-2 mb-1 mt-4 flex justify-center'
              variant='soft'
              onClick={handleDeleteAllNotifications}
              renderLeft={<Icon name='trash-2' />}
            >
              {t('label.clear')}
            </Button>
          </>
        ) : (
          <div className='p-2 text-center'>{t('label.no_results')}</div>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default Notifications;
