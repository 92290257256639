/* eslint-disable jsx-a11y/label-has-associated-control */

import { useTranslation } from 'react-i18next';
import type { HeaderContext } from '@tanstack/react-table';

import { type ApiDocument, DocumentStatus, DocumentType } from '@legalfly/api/documents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

import { getDocumentIcon, getDocumentName } from '../../documents/helpers';

const Header = ({ column }: HeaderContext<ApiDocument, unknown>) => {
  const { t } = useTranslation('components');

  return (
    <Button
      variant='unstyled'
      className='flex items-center gap-2'
      onClick={() => {
        column.toggleSorting(column.getIsSorted() === 'asc');
        trackEvent(
          {
            action: 'click',
            category: 'documents',
            label: 'sort',
          },
          { direction: column.getIsSorted(), column: column.id },
        );
      }}
    >
      {t('documents.documentPicker.name')}
      <Icon name='arrow-up' className={cn(column.getIsSorted() === 'asc' && 'rotate-180')} />
    </Button>
  );
};

interface CellProps {
  document: ApiDocument;
  onRetryFile?: ({ uuid }: { uuid: ApiDocument['uuid'] }) => void;
}

const CellContent = ({ document, onRetryFile }: CellProps) => {
  const { t } = useTranslation('components');

  return (
    <>
      <Icon name={getDocumentIcon(document)} className='min-h-5 min-w-5' />
      <Text className='line-clamp-2' as='span'>
        {getDocumentName(document)}
        {document.favorited && (
          <Icon name='star' size='xs' className='ms-2 fill-icon-strongest stroke-icon-strongest' />
        )}
      </Text>
      {typeof onRetryFile === 'function' &&
        document.type === DocumentType.FILE &&
        document.status === DocumentStatus.ERROR && (
          <>
            <Button
              variant='soft'
              className='ml-2 px-2'
              onClick={() => onRetryFile({ uuid: document.uuid })}
              renderLeft={<Icon name='refresh-ccw-5' />}
            >
              <Text variant='caption'>{t('documents.upload.retry')}</Text>
            </Button>
            <Icon name='x-close' className='ml-2 text-risk-fill-high' />
            <Text className='text-risk-fill-high'>{t('documents.upload.failed')}</Text>
          </>
        )}
    </>
  );
};

export const NameColumn = {
  Header,
  CellContent,
};
