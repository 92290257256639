import { useTranslation } from 'react-i18next';
import { Link, useLocation } from '@tanstack/react-router';

import { trackEvent } from '@legalfly/reporting/tracking';
import { Avatar, AvatarFallback } from '@legalfly/ui/avatar';
import { Button } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { changeTheme } from '@legalfly/ui/theme';
import { cn } from '@legalfly/ui/utils';
import { useWebSocketContext } from '@legalfly/websockets';
import Notifications from 'components/notifications/Notifications';
import { CurrentUserAvatar } from 'components/users/CurrentUserAvatar';
import { useAuth } from 'core/auth';
import { env } from 'core/env';

import { FeedbackDialog } from '../feedback/FeedbackDialog';

export const LayoutHeader = ({ isPending = false }: { isPending?: boolean }) => {
  const { t } = useTranslation();
  const isConnected = useWebSocketContext();

  const { logout } = useAuth();
  const { pathname: currentPath } = useLocation();

  const handleChangeTheme = () => {
    const newTheme = changeTheme();

    trackEvent({
      action: 'click',
      category: 'profile',
      label: newTheme,
    });
  };

  return (
    <header className='flex h-content-header bg-surface-nav py-[10px] pe-4 ps-6'>
      <div className='flex flex-1 items-center'>
        <Text variant='title' className='text-[24px] leading-[24px] tracking-[3px]'>
          legalfly
        </Text>
        {!env.isProduction && (
          <div
            className={cn(
              'ms-2 flex h-2 w-2 items-center justify-center rounded-full',
              isConnected ? 'bg-content-body-success' : 'bg-content-body-error',
            )}
          />
        )}
      </div>
      <div className='flex items-center gap-3'>
        {!isPending && <FeedbackDialog />}
        {!isPending && <Notifications />}
        <DropdownMenu
          onOpenChange={(isOpen) => {
            if (isOpen) {
              trackEvent({
                action: 'click',
                category: 'profile',
                label: 'open',
              });
            }
          }}
        >
          <DropdownMenuTrigger asChild>
            <Button
              variant='unstyled'
              size='sm'
              className='overflow-visible focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-4 focus-visible:outline-stroke-weak'
            >
              {isPending ? (
                <Avatar size='sm'>
                  <AvatarFallback>LF</AvatarFallback>
                </Avatar>
              ) : (
                <CurrentUserAvatar size='sm' />
              )}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end' className='min-w-80'>
            <DropdownMenuItem asChild isActive={currentPath === '/settings/profile'}>
              <Link to='/settings/profile' className='flex w-full items-center gap-2'>
                <Icon name='users-2' />
                {t('nav.myProfile')}
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild isActive={currentPath === '/settings/users'}>
              <Link to='/settings/users' className='flex w-full items-center gap-2'>
                <Icon name='users-plus' />
                {t('nav.users')}
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleChangeTheme} className='flex items-center gap-2'>
              <Icon name='sun' />
              <p className='light:hidden'>{`${t('label.lightMode')}`}</p>
              <p className='dark:hidden'>{`${t('label.darkMode')}`}</p>
            </DropdownMenuItem>
            <DropdownMenuSeparator variant='weak' className='my-1' />
            <DropdownMenuItem onClick={() => logout()} className='flex items-center gap-2'>
              <Icon name='logout-1' />
              {t('action.logout')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};
