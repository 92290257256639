import { BaseCopilotAttachDocuments } from '@legalfly/components/copilot/BaseCopilotAttachDocuments';
import { useUpdateConversation } from 'core/modules/conversations';

import { useCopilotContext } from '../CopilotProvider';

type Props = {
  onUpload: (files: File[]) => void;
};

const CopilotAttachDocuments = ({ onUpload }: Props) => {
  const { conversation, conversationType } = useCopilotContext();

  const { updateConversation } = useUpdateConversation({
    type: conversationType,
  });

  const handleDeleteDocument = async (documentUuid: string) => {
    if (!conversation) return;

    await updateConversation({
      uuid: conversation.uuid,
      body: {
        documentUuids: conversation.documents
          .filter((doc) => doc.uuid !== documentUuid)
          .map((doc) => doc.uuid),
      },
    });
  };

  return (
    <BaseCopilotAttachDocuments
      analyticsMeta={{ category: 'discovery' }}
      conversation={conversation}
      onUpload={onUpload}
      onDeleteDocument={handleDeleteDocument}
    />
  );
};

export default CopilotAttachDocuments;
