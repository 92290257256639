import type { HTMLAttributes } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import type { ApiDocumentTypeFile } from '@legalfly/api/documents';
import { type ApiPlaybook, PlaybookStatusEnum } from '@legalfly/api/playbooks';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { Divider } from '@legalfly/ui/divider';
import { FormItemError, FormItemLabel } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';

import { SelectDocumentCategory } from '../documentCategory/SelectDocumentCategory';
import { requiredValidator } from '../forms/validators';
import { SelectJurisdiction } from '../jurisdiction/SelectJurisdiction';
import { SelectLanguage } from '../language/SelectLanguage';
import { PlaybookListItem } from '../playbooks/PlaybookListItem';
import { SelectPlaybook } from '../playbooks/SelectPlaybook';
import { reviewToasts } from './toasts';

interface Props {
  document: ApiDocumentTypeFile;
  defaultJurisdiction: ApiDocumentTypeFile['jurisdiction'];
  playbooks: ApiPlaybook[];
  systemPlaybooks: ApiPlaybook[];
  onCategoryChange: (category: ApiDocumentTypeFile['category']) => Promise<ApiPlaybook[]>;
  onSubmit: (data: BaseNewReviewFormData) => Promise<void>;
}

export interface BaseNewReviewFormData {
  party: string;
  category: ApiDocumentTypeFile['category'];
  language: ApiDocumentTypeFile['languages'][number];
  jurisdiction: ApiDocumentTypeFile['jurisdiction'];
  playbooks: ApiPlaybook[];
  activePlaybookIds: ApiPlaybook['uuid'][];
}

const FormItem = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('flex flex-col items-start @xs:mt-4 @xs:flex-row', className)} {...props} />
  );
};

export const BaseNewReviewForm = ({
  document,
  defaultJurisdiction,
  playbooks,
  systemPlaybooks,
  onCategoryChange,
  onSubmit,
}: Props) => {
  const { i18n, t } = useTranslation('components');

  const form = useForm<BaseNewReviewFormData>({
    defaultValues: {
      party: document.parties?.length ? document.parties[0] : 'neutral',
      category: document.category,
      language: document.languages[0] ?? i18n.language,
      jurisdiction: document.jurisdiction ?? defaultJurisdiction,
      playbooks: [...systemPlaybooks],
      activePlaybookIds: systemPlaybooks.map((playbook) => playbook.uuid),
    },
    onSubmit: async ({ value }) => {
      trackEvent({
        action: 'submit',
        category: 'reviewNew',
      });

      await withToasts(onSubmit(value))(reviewToasts.createReview());
    },
  });

  useEffect(() => {
    const currentPlaybooks = form.getFieldValue('playbooks');

    const newPublicPlaybooks = currentPlaybooks.filter(
      (p) => p.status !== PlaybookStatusEnum.SYSTEM,
    );

    // new system books + current public/private books
    const playbooks = [...systemPlaybooks, ...newPublicPlaybooks];

    const activePlaybookIds = [
      ...systemPlaybooks.map((playbook) => playbook.uuid),
      ...newPublicPlaybooks.map((playbook) => playbook.uuid),
    ];

    form.setFieldValue('playbooks', playbooks);
    form.setFieldValue('activePlaybookIds', activePlaybookIds);
    form.validateField('playbooks', 'change');
  }, [form, systemPlaybooks]);

  const onChangeCategory = async (category: ApiDocumentTypeFile['category']) => {
    trackEvent(
      {
        action: 'click',
        category: 'reviewNew',
        label: 'changeCategory',
      },
      { value: category },
    );
    const systPbs = await onCategoryChange(category);

    const currentPlaybooks = form.getFieldValue('playbooks');

    const newPublicPlaybooks = currentPlaybooks.filter(
      (p) => p.status !== PlaybookStatusEnum.SYSTEM,
    );

    // new system books + current public/private books
    const playbooks = [...systPbs, ...newPublicPlaybooks];

    const activePlaybookIds = [
      ...systPbs.map((playbook) => playbook.uuid),
      ...newPublicPlaybooks.map((playbook) => playbook.uuid),
    ];

    form.setFieldValue('playbooks', playbooks);
    form.setFieldValue('activePlaybookIds', activePlaybookIds);
    form.validateField('playbooks', 'change');
  };

  return (
    <form
      className='@container'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <form.Field name='party'>
        {(field) => (
          <div className='flex flex-wrap gap-4 [&>*]:overflow-visible'>
            <Button
              variant='soft'
              className={cn(
                'flex-1 justify-center hover:bg-fill-lemon hover:text-content-body-constant-dark',
                field.state.value === 'neutral' && 'bg-fill-lemon text-content-body-constant-dark',
              )}
              onClick={() => field.handleChange('neutral')}
            >
              {t('review.new.parties.neutral')}
            </Button>
            {document.parties?.map((party) => (
              <Button
                key={party}
                variant='soft'
                className={cn(
                  'flex-1 justify-center hover:bg-fill-lemon hover:text-content-body-constant-dark',
                  field.state.value === party && 'bg-fill-lemon text-content-body-constant-dark',
                )}
                onClick={() => field.handleChange(party)}
              >
                {party}
              </Button>
            ))}
          </div>
        )}
      </form.Field>

      <Divider className='my-5' variant='weak' />

      <FormItem>
        <FormItemLabel
          label={t('review.new.contractType')}
          htmlFor='category'
          className='mr-auto w-full flex-1 text-content-body-placeholder'
        />
        <form.Field name='category'>
          {(field) => (
            <SelectDocumentCategory
              value={field.state.value}
              onChange={(c) => {
                field.handleChange(c);
                onChangeCategory(c);
              }}
              triggerProps={{ className: 'w-auto' }}
              contentProps={{ align: 'end' }}
            />
          )}
        </form.Field>
      </FormItem>

      <Divider className='my-5' variant='weak' />

      <FormItem>
        <FormItemLabel
          label={t('review.new.language')}
          htmlFor='language'
          className='mr-auto w-full flex-1 text-content-body-placeholder'
        />
        <form.Field name='language'>
          {(field) => (
            <SelectLanguage
              value={field.state.value}
              onChange={(language) => {
                trackEvent(
                  {
                    action: 'click',
                    category: 'reviewNew',
                    label: 'changeLanguage',
                  },
                  { language },
                );
                field.handleChange(language);
              }}
              triggerProps={{ className: 'w-auto' }}
              contentProps={{ align: 'end' }}
            />
          )}
        </form.Field>
      </FormItem>

      <Divider className='my-5' variant='weak' />

      <FormItem>
        <FormItemLabel
          label={t('review.new.jurisdiction')}
          htmlFor='jurisdiction'
          className='mr-auto w-full flex-1 text-content-body-placeholder'
        />
        <form.Field name='jurisdiction'>
          {(field) => (
            <SelectJurisdiction
              value={field.state.value}
              onChange={(jurisdiction) => {
                trackEvent(
                  {
                    action: 'click',
                    category: 'reviewNew',
                    label: 'changeJurisdiction',
                  },
                  { jurisdiction },
                );
                field.handleChange(jurisdiction);
              }}
              triggerProps={{ className: 'w-auto' }}
              contentProps={{ align: 'end' }}
            />
          )}
        </form.Field>
      </FormItem>

      <Divider className='my-5' variant='weak' />

      <FormItem className='@xs:flex-col @xs:items-start'>
        <FormItemLabel label={t('review.new.playbooks')} htmlFor='playbook' className='mb-0' />
        <form.Field
          name='playbooks'
          mode='array'
          validators={{
            onChange: ({ value }) => {
              const activePlaybookIds = form.getFieldValue('activePlaybookIds');
              return requiredValidator('Playbook is required')(
                Boolean(activePlaybookIds.length && value.length),
              );
            },
          }}
        >
          {(field) => (
            <>
              <Text variant='bodyLight' className='mt-2 text-content-body-placeholder'>
                {t('review.new.playbookDescription')}
              </Text>
              <div
                className={cn(
                  'flex w-full flex-col gap-3',
                  field.state.value.length ? 'my-5' : 'my-2',
                )}
              >
                {field.state.value.map((playbook, i) => {
                  return (
                    <form.Field key={playbook.uuid} name={`playbooks[${i}].uuid`}>
                      {(subField) => {
                        return (
                          <PlaybookListItem
                            playbook={playbook}
                            sheetTopOffset='calc(var(--lf-content-header-height) + var(--lf-body-padding) * 2)'
                            checked={form
                              .getFieldValue('activePlaybookIds')
                              .includes(subField.state.value)}
                            onCheckedChange={(checked) => {
                              trackEvent(
                                {
                                  action: 'click',
                                  category: 'reviewNew',
                                  label: checked ? 'selectPlaybook' : 'deselectPlaybook',
                                },
                                { status: playbook.status },
                              );

                              const currentActiveIds = form.getFieldValue('activePlaybookIds');

                              const updatedActiveIds = checked
                                ? [...currentActiveIds, subField.state.value]
                                : currentActiveIds.filter((id) => id !== subField.state.value);

                              form.setFieldValue('activePlaybookIds', updatedActiveIds);
                              field.setValue(field.state.value);
                            }}
                            onRemove={() => {
                              trackEvent({
                                action: 'click',
                                category: 'reviewNew',
                                label: 'removePlaybook',
                              });
                              form.setFieldValue(
                                'activePlaybookIds',
                                form
                                  .getFieldValue('activePlaybookIds')
                                  .filter((id) => id !== subField.state.value),
                              );
                              field.removeValue(i);
                            }}
                          />
                        );
                      }}
                    </form.Field>
                  );
                })}
              </div>

              <Text variant='bodyLight' className='mb-2 text-content-body-placeholder'>
                {t('review.new.playbookAddNewDescription')}
              </Text>
              <SelectPlaybook
                playbooks={playbooks}
                triggerProps={{ className: 'w-auto' }}
                selectedPlaybooks={field.state.value}
                onChange={(playbook) => {
                  trackEvent({
                    action: 'click',
                    category: 'reviewNew',
                    label: 'addPlaybook',
                  });

                  field.pushValue(playbook);
                  form.pushFieldValue('activePlaybookIds', playbook.uuid);

                  form.validateField('playbooks', 'change');
                }}
              />
              <FormItemError message={field.state.meta.errors.join()} />
            </>
          )}
        </form.Field>
      </FormItem>

      <Divider className='my-5' variant='weak' />

      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit, isSubmitting]) => (
          <Button
            type='submit'
            disabled={!canSubmit}
            isLoading={isSubmitting}
            renderLeft={<Icon name='check' />}
          >
            {t('review.new.start')}
          </Button>
        )}
      </form.Subscribe>
    </form>
  );
};
