/* istanbul ignore file -- @preserve */
// This file is generated by bun run scripts:icons
export const iconNames = [
  'search',
  'users-plus',
  'check-square',
  'file-search',
  'arrow-down',
  'list',
  'dots-horizontal',
  'file-doc',
  'translate',
  'bold-square',
  'globe-1',
  'mail-1',
  'cpu-chip-1',
  'x-circle',
  'chevron-down',
  'flag-3',
  'italic-square',
  'menu-5',
  'italic-01',
  'bold-01',
  'shield-tick',
  'chevron-up',
  'edit-4',
  'check-done',
  'chevron-right',
  'clipboard',
  'arrow-right',
  'thumbs-down',
  'archive',
  'sources',
  'book-open',
  'file-2',
  'upload-1',
  'bell',
  'settings-2',
  'eye-off',
  'paragraph-spacing',
  'home-line',
  'strikethrough-02',
  'strikethrough-square',
  'plus',
  'upload-2',
  'check',
  'cross-square',
  'gear',
  'info-circle',
  'folder-plus',
  'refresh',
  'layout-right',
  'link-1',
  'link-3',
  'paperclip',
  'thumbs-up',
  'paragraph-wrap',
  'table',
  'send',
  'file-jpg',
  'search-md',
  'download-1',
  'unorderedlist',
  'switch-horizontal',
  'star',
  'sun',
  'edit',
  'browser',
  'alert-circle',
  'moon-02',
  'download-2',
  'file-png',
  'plus-circle',
  'underline-01',
  'orderedlist',
  'trend-up-2',
  'trash-2',
  'eye',
  'refresh-ccw-2',
  'numberpoints-01',
  'dotpoints-01',
  'underline-square',
  'arrow-up',
  'trash-1',
  'sort',
  'arrow-narrow-right',
  'users-2',
  'globe',
  'users-3',
  'arrow-line-down',
  'expand-2',
  'trash-4',
  'refresh-ccw-5',
  'file-search-3',
  'message-text-square-1',
  'building-6',
  'logout-1',
  'folder',
  'file-pdf',
  'chevron-left',
  'arrows-triangle',
  'x-close',
  'align-left',
  'placeholder',
  'flag-switzerland',
  'flag-united_kingdom',
  'flag-greece',
  'flag-belgium',
  'flag-sweden',
  'flag-czechia',
  'flag-estonia',
  'flag-saudi_arabia',
  'flag-hungary',
  'flag-poland',
  'flag-ireland',
  'flag-finland',
  'flag-united_states',
  'flag-netherlands',
  'flag-denmark',
  'flag-italy',
  'flag-france',
  'flag-luxembourg',
  'flag-portugal',
  'flag-spain',
  'flag-austria',
  'flag-germany',
  'flag-canada',
] as const;

export type IconName = (typeof iconNames)[number];
