import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

const api = legalFlyConfig.api.paths;

export const createDocumentsApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const getFolder = ({ uuid }: ParamsOf<'documents/folder/:uuid?/get'>) => {
    return client(api.documents.folder.detail, { method: 'GET', params: { uuid } });
  };

  const getFile = ({ uuid }: ParamsOf<'documents/file/:uuid/get'>) => {
    return client(api.documents.files.detail, { method: 'GET', params: { uuid } });
  };

  const getFileDownloadUrl = ({ uuid }: ParamsOf<'documents/file/:uuid/get'>) => {
    return client(api.documents.files.download.original, { method: 'GET', params: { uuid } });
  };

  const getAnonymizedFileDownloadUrl = ({ uuid }: ParamsOf<'documents/file/:uuid/get'>) => {
    return client(api.documents.files.download.anonymized, { method: 'GET', params: { uuid } });
  };

  const deleteFile = ({ uuid }: ParamsOf<'documents/file/:uuid/delete'>) => {
    return client(api.documents.files.delete, { method: 'DELETE', params: { uuid } });
  };

  const getFileSnippets = ({ uuid }: ParamsOf<'documents/file/:uuid/snippets/get'>) => {
    return client(api.documents.files.snippets, { method: 'GET', params: { uuid } });
  };

  const getFileSummary = ({ uuid }: ParamsOf<'documents/file/:uuid/summary/get'>) => {
    return client(api.documents.files.summary, { method: 'GET', params: { uuid } });
  };

  const createFile = ({
    uuid,
    file,
  }: ParamsOf<'documents/file/:uuid?/post'> & { file: FormData }) => {
    return client(api.documents.files.create, {
      method: 'POST',
      params: { uuid },
      body: file,
      // remove the content type header so the browser can set it
      headers: { 'Content-Type': undefined },
    });
  };

  const createFolder = ({
    uuid,
    body,
  }: ParamsOf<'documents/folder/:uuid?/post'> & {
    body: BodyOf<'documents/folder/:uuid?/post'>;
  }) => {
    return client(api.documents.folder.create, { method: 'POST', params: { uuid }, body });
  };

  const deleteFolder = ({ uuid }: ParamsOf<'documents/folder/:uuid?/delete'>) => {
    return client(api.documents.folder.delete, { method: 'DELETE', params: { uuid } });
  };

  const createEntity = ({
    uuid,
    body,
  }: ParamsOf<'documents/file/:uuid/entities/post'> & {
    body: BodyOf<'documents/file/:uuid/entities/post'>;
  }) => {
    return client(api.documents.files.entities.create, { method: 'POST', params: { uuid }, body });
  };

  const updateEntity = ({
    uuid,
    entityUuid,
    body,
  }: ParamsOf<'documents/file/:uuid/entities/:entityUuid/put'> & {
    body: BodyOf<'documents/file/:uuid/entities/:entityUuid/put'>;
  }) => {
    return client(api.documents.files.entities.update, {
      method: 'PUT',
      params: { uuid, entityUuid },
      body,
    });
  };

  const deleteEntity = ({
    uuid,
    entityUuid,
  }: ParamsOf<'documents/file/:uuid/entities/:entityUuid/delete'>) => {
    return client(api.documents.files.entities.delete, {
      method: 'DELETE',
      params: { uuid, entityUuid },
    });
  };

  const updateFolder = ({
    uuid,
    body,
  }: ParamsOf<'documents/folder/:uuid?/put'> & {
    uuid: string;
    body: BodyOf<'documents/folder/:uuid?/put'>;
  }) => {
    return client(api.documents.folder.update, {
      method: 'PUT',
      params: { uuid },
      body,
    });
  };

  const dropDocuments = ({ body }: { body: BodyOf<'documents/folder/drop/post'> }) => {
    return client(api.documents.folder.drop, { method: 'POST', body });
  };

  const countFolderDocuments = ({ uuids }: ParamsOf<'documents/folder/count/:uuids/get'>) => {
    return client(api.documents.folder.count, { method: 'GET', params: { uuids } });
  };

  const retryFile = ({ uuid }: ParamsOf<'documents/file/:uuid/retry/post'>) => {
    return client(api.documents.files.retry, { method: 'POST', params: { uuid } });
  };

  const deleteDocuments = ({ body }: { body: BodyOf<'documents/delete'> }) => {
    return client(api.documents.delete, { method: 'DELETE', body });
  };

  const createDocumentsZip = ({ body }: { body: BodyOf<'documents/download/zip/post'> }) => {
    return client(api.documents.download.zip.original, { method: 'POST', body });
  };

  const createAnonymizedDocumentsZip = ({
    body,
  }: {
    body: BodyOf<'documents/download/zip/anonymized/post'>;
  }) => {
    return client(api.documents.download.zip.anonymized, { method: 'POST', body });
  };

  return {
    getFolder,
    getFile,
    getFileDownloadUrl,
    getAnonymizedFileDownloadUrl,
    getFileSnippets,
    createFile,
    createFolder,
    deleteFile,
    deleteFolder,
    createEntity,
    updateEntity,
    deleteEntity,
    dropDocuments,
    updateFolder,
    countFolderDocuments,
    retryFile,
    deleteDocuments,
    getFileSummary,
    createDocumentsZip,
    createAnonymizedDocumentsZip,
  };
};

export type DocumentsApi = ReturnType<typeof createDocumentsApi>;

export type ApiFolderContents = ResponseOf<'documents/folder/:uuid?/get'>;
export type ApiDocument = ApiFolderContents['documents'][number];
export type ApiDocumentTypeFile = Extract<ApiDocument, { type: 'file' }>;
export type ApiDocumentTypeFolder = Extract<ApiDocument, { type: 'folder' }>;

export type ApiDocumentSnippets = ResponseOf<'documents/file/:uuid/snippets/get'>;
export type ApiDocumentSnippetEntity = ApiDocumentSnippets['entities'][number];
export type ApiDocumentSnippetText = ApiDocumentSnippets['text'][number];
