import type { ComponentProps, ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import type { ApiReviewDetail, ApiReviewItem } from '@legalfly/api/reviews';
import { ReviewStatus } from '@legalfly/api/reviews';
import { Button } from '@legalfly/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@legalfly/ui/collapsible';
import { ProgressCard } from '@legalfly/ui/progress';
import { Text } from '@legalfly/ui/text';
import { groupBy } from '@legalfly/utils/groupBy';

import { getCompletedItemsCount, sortReviewItemsByRisk } from './helpers';
import ReviewDetailItem from './ReviewDetailItem';

interface ReviewDetailItemsProps
  extends Pick<
    ComponentProps<typeof ReviewDetailItem>,
    'onClickHighlight' | 'onClickExplain' | 'onClickRedraft' | 'activeSnippetTextId'
  > {
  review: ApiReviewDetail;
  renderItemActions?: (item: ApiReviewItem) => ReactNode;
}

const ReviewDetailItems = ({ review, ...rest }: ReviewDetailItemsProps) => {
  const { t } = useTranslation('components');
  const [showMore, setShowMore] = useState<Record<string, boolean>>({});

  const items = review.items;
  const completedItemsCount = getCompletedItemsCount(items);

  const groupedItems = groupBy(items, (item) => {
    if (item.groupName === 'lf_anomaly') {
      return t('review.new.anomalies');
    }
    return item.groupName ?? t('review.ungrouped');
  });

  const toggleShowMore = (groupName: string) => {
    setShowMore((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  return (
    <div className='flex flex-col gap-4'>
      {review.status !== ReviewStatus.COMPLETED && (
        <ProgressCard
          title={t(
            review.status === ReviewStatus.ANOMALIES
              ? 'review.new.progress.anomalies.title'
              : 'review.new.progress.general.title',
          )}
          subTitle={t(
            review.status === ReviewStatus.ANOMALIES
              ? 'review.new.progress.anomalies.subTitle'
              : 'review.new.progress.general.subTitle',
          )}
          action={`${completedItemsCount}/${items.length}`}
        >
          <ProgressCard.Bar value={Math.ceil((completedItemsCount / items.length) * 100)} />
        </ProgressCard>
      )}
      <div className='flex flex-col'>
        {Object.entries(groupedItems).map(([groupName, items]) => {
          const primaryItems = items.filter(
            (item) =>
              item.answer.level === 'high' ||
              item.answer.level === 'medium' ||
              item.type !== PlaybookItemTypeEnum.RISK_ASSESSMENT,
          );

          const secondaryItems = items.filter(
            (item) =>
              item.answer.level !== 'high' &&
              item.answer.level !== 'medium' &&
              item.type === PlaybookItemTypeEnum.RISK_ASSESSMENT,
          );

          const secondaryHasAnswer = secondaryItems.some((item) => Object.keys(item.answer).length);

          return (
            <Collapsible
              key={groupName}
              className='mb-3 flex flex-col border-b border-stroke-weaker data-[state=open]:pb-3'
              defaultOpen
            >
              <CollapsibleTrigger className='mb-3'>
                <Text className='truncate'>{groupName}</Text>
                <Text className='min-w-16 opacity-50'>
                  {`${primaryItems.length} ${t('label.item', { count: primaryItems.length })}`}
                </Text>
              </CollapsibleTrigger>
              <CollapsibleContent>
                {sortReviewItemsByRisk(primaryItems).map((item) => (
                  <ReviewDetailItem key={item.uuid} item={item} {...rest} />
                ))}
                {showMore[groupName] &&
                  sortReviewItemsByRisk(secondaryItems).map((item) => (
                    <ReviewDetailItem key={item.uuid} item={item} {...rest} />
                  ))}
                {secondaryHasAnswer && !showMore[groupName] && secondaryItems.length > 0 && (
                  <Button
                    onClick={() => toggleShowMore(groupName)}
                    className='w-full justify-center'
                  >
                    {t('review.actions.showLowerRiskItems')}
                  </Button>
                )}
              </CollapsibleContent>
            </Collapsible>
          );
        })}
      </div>
    </div>
  );
};

export default ReviewDetailItems;
