import type { PropsWithChildren } from 'react';

import { BaseCopilotActions } from '@legalfly/components/copilot/BaseCopilotActions';
import { SelectJurisdiction } from '@legalfly/components/jurisdiction/SelectJurisdiction';

import CopilotAttachDocuments from './actions/CopilotAttachDocuments';
import { SelectDocumentsSheet } from '../../documents/documentPicker/SelectDocumentsSheet';
import './styles.css';

const CopilotActions = ({ children }: PropsWithChildren) => {
  return (
    <BaseCopilotActions style={{ viewTransitionName: 'conversation' }} className='bg-fill-maximal'>
      {children}
    </BaseCopilotActions>
  );
};

CopilotActions.SelectDocuments = SelectDocumentsSheet;
CopilotActions.SelectJurisdiction = SelectJurisdiction;
CopilotActions.AttachDocuments = CopilotAttachDocuments;

export { CopilotActions };
