import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { AgentsApi } from '@legalfly/api/agents';
import type { DocumentsApi } from '@legalfly/api/documents';
import type { FavoritesApi } from '@legalfly/api/favorites';
import { FavoriteTypeEnum } from '@legalfly/api/favorites';

import { agentsQueryOptions } from '../agents/agentsQueryOptions';
import { documentsQueryOptions } from '../documents/documentsQueryOptions';

export const createFavoritesModule = ({
  favoritesApi,
  documentsApi,
  agentsApi,
}: {
  favoritesApi: FavoritesApi;
  documentsApi: DocumentsApi;
  agentsApi: AgentsApi;
}) => {
  const dQueryOptions = documentsQueryOptions({ documentsApi });
  const aQueryOptions = agentsQueryOptions({ agentsApi });

  const useToggleFavorite = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['toggle', 'favorite'],
      mutationFn: ({
        body: { entityUuid, entityType, favorited },
      }: {
        body: Parameters<FavoritesApi['toggleFavorite']>[0];
        metadata?: {
          parentUuid?: string;
        };
      }) => favoritesApi.toggleFavorite({ entityUuid, entityType, favorited }),
      onSuccess: ({ entityUuid, favorited }, { body: { entityType }, metadata }) => {
        switch (entityType) {
          case FavoriteTypeEnum.DOCUMENT:
            queryClient.setQueryData(
              dQueryOptions.folder({ uuid: metadata?.parentUuid }).queryKey,
              (old) => {
                if (!old) return old;
                return {
                  ...old,
                  documents: old.documents.map((doc) =>
                    doc.uuid === entityUuid ? { ...doc, favorited } : doc,
                  ),
                };
              },
            );
            break;
          case FavoriteTypeEnum.AGENT:
            queryClient.setQueryData(aQueryOptions.agents().queryKey, (old) => {
              if (!old) return old;
              return old.map((agent) =>
                agent.uuid === entityUuid ? { ...agent, favorited } : agent,
              );
            });

            queryClient.setQueryData(aQueryOptions.agent({ uuid: entityUuid }).queryKey, (old) => {
              if (!old) return old;
              return { ...old, favorited };
            });
            break;
        }
      },
    });

    return {
      toggleFavorite: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useToggleFavorite,
  };
};
