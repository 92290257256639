import { type ComponentProps } from 'react';

import { UserAvatar } from '@legalfly/components/users/UserAvatar';
import { useCurrentUser } from 'core/modules/users';

export const CurrentUserAvatar = (
  props: Omit<
    ComponentProps<typeof UserAvatar>,
    'avatarUrl' | 'firstName' | 'lastName' | 'lastActivity'
  >,
) => {
  const { currentUser } = useCurrentUser();

  return (
    <UserAvatar
      firstName={currentUser.firstName}
      lastName={currentUser.lastName}
      avatarUrl={currentUser.avatarUrl}
      {...props}
    />
  );
};
