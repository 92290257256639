import type { ComponentProps } from 'react';

import { PlaybookItemMultipleChoiceOption } from '@legalfly/components/playbooks/PlaybookItemMultipleChoiceOption';
import { PlaybookMultipleChoiceOptionsDialog } from '@legalfly/components/playbooks/PlaybookMultipleChoiceOptionsDialog';

export const PlaybookItemContextMultipleChoiceOptions = ({
  options,
  onChange,
}: Pick<ComponentProps<typeof PlaybookMultipleChoiceOptionsDialog>, 'options' | 'onChange'>) => {
  return (
    <div className='flex flex-col'>
      <div className='w-fit'>
        <PlaybookMultipleChoiceOptionsDialog options={options} onChange={onChange} />
      </div>

      {options && options.length > 0 && (
        <div className='mt-2 flex w-full flex-wrap gap-2'>
          {options.map((option, index) => (
            <PlaybookItemMultipleChoiceOption key={index} option={option} />
          ))}
        </div>
      )}
    </div>
  );
};
