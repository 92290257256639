import { useState } from 'react';

import type { ApiDocument, ApiDocumentTypeFile } from '@legalfly/api/documents';
import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import type { ApiReviewItem } from '@legalfly/api/reviews';
import ReviewDetailItem from '@legalfly/components/review/ReviewDetailItem';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Sheet } from '@legalfly/ui/sheet';
import { noop } from '@legalfly/utils/fn';
import { DocumentSnippetsViewerSheetContent } from 'components/documents/DocumentSnippetsViewerSheetContent';

interface Props {
  item: ApiReviewItem;
  document: ApiDocument;
}

export const PlaybookItemSandboxReview = ({ item, document }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSnippetTextId, setActiveSnippetTextId] = useState('');

  const snippetIds = item?.answer.snippetIds ?? [];

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <ReviewDetailItem
        key={isOpen ? 'open' : 'closed'}
        item={item}
        onClickExplain={noop}
        onClickRedraft={noop}
        onClickHighlight={(snippetId) => {
          trackEvent({
            action: 'click',
            category: 'playbooks',
            label: 'highlight',
          });
          setActiveSnippetTextId(snippetId);
          setIsOpen(true);
        }}
        activeSnippetTextId={activeSnippetTextId}
        isHighlighted={item.type === PlaybookItemTypeEnum.RISK_ASSESSMENT}
      />
      <DocumentSnippetsViewerSheetContent
        document={document as ApiDocumentTypeFile}
        snippetIds={snippetIds}
        activeSnippetTextId={activeSnippetTextId}
        question={item?.text}
        answer={item?.answer.text}
        setActiveSnippetTextId={(id) => {
          trackEvent({
            action: 'click',
            category: 'playbooks',
            label: 'highlight',
          });
          setActiveSnippetTextId(id);
        }}
      />
    </Sheet>
  );
};
