import type { TFunction } from 'i18next';
import isEmail from 'validator/es/lib/isEmail';

export const firstNameValidator = (t: TFunction) => (value: string) => {
  if (!value) {
    return t('form.firstName.validation.required');
  }
  if (value.length < 1) {
    return t('form.firstName.validation.minLength', { count: 1 });
  }
  return undefined;
};

export const lastNameValidator = (t: TFunction) => (value: string) => {
  if (!value) {
    return t('form.lastName.validation.required');
  }
  if (value.length < 1) {
    return t('form.lastName.validation.minLength', { count: 1 });
  }
  return undefined;
};

export const emailValidator = (t: TFunction) => (value: string) => {
  if (!value) {
    return t('form.email.validation.required');
  }
  if (!isEmail(value)) {
    return t('form.email.validation.invalid');
  }
  return undefined;
};

export const nameValidator = (t: TFunction) => (value: string) => {
  if (!value) {
    return t('form.name.validation.required');
  }
  return undefined;
};

export const requiredValidator = (msg: string) => (value: string | boolean) => {
  if (!value) {
    return msg;
  }
  return undefined;
};
