import { useQueryClient } from '@tanstack/react-query';

import type { ApiConversation, ConversationTypeEnum } from '@legalfly/api/conversations';
import { type ApiDocument, DocumentStatus } from '@legalfly/api/documents';
import { createConversationsModule } from '@legalfly/modules/conversations';
import type { WebSocketCallback } from '@legalfly/websockets';
import { useWebSocketSubscriber } from '@legalfly/websockets';

import {
  agentConversationsApi,
  discoveryConversationsApi,
  draftConversationsApi,
  reviewConversationsApi,
} from '../../di';

export const {
  useConversations,
  useConversation,
  useClearConversation,
  useCreateConversationMessage,
  useCreateConversation,
  useDeleteConversation,
  useStopConversationMessage,
  conversationsQueryOptions,
  useUpdateConversation,
  useUpdateConversationMessage,
  useAddConversationMessage,
} = createConversationsModule({
  agentApi: agentConversationsApi,
  discoveryApi: discoveryConversationsApi,
  draftApi: draftConversationsApi,
  reviewApi: reviewConversationsApi,
});

interface ListenToConversationDocumentStatusProps {
  document: ApiDocument;
  conversationUuid: ApiConversation['uuid'];
  conversationType: ConversationTypeEnum;
}

const useListenToConversationDocumentStatus = (
  { document, conversationUuid, conversationType }: ListenToConversationDocumentStatusProps,
  callback?: WebSocketCallback<`document_upload_status_${string}`>,
) => {
  const queryClient = useQueryClient();

  useWebSocketSubscriber(`document_upload_status_${document.uuid}`, (data) => {
    queryClient.setQueryData(
      conversationsQueryOptions.conversation({ type: conversationType, uuid: conversationUuid })
        .queryKey,
      (oldData) => {
        if (!oldData) return oldData;

        if (data.status === DocumentStatus.ERROR) {
          return {
            ...oldData,
            documents: oldData.documents.filter((doc) => doc.uuid !== document.uuid),
          };
        }

        const updatedDocuments = oldData.documents.map((doc) =>
          doc.uuid === document.uuid ? { ...doc, status: data.status } : doc,
        );
        return { ...oldData, documents: updatedDocuments };
      },
    );

    callback?.(data);
  });
};

const ListenToConversationDocumentStatus = ({
  document,
  conversationUuid,
  conversationType,
}: ListenToConversationDocumentStatusProps) => {
  useListenToConversationDocumentStatus({ document, conversationUuid, conversationType });
  return null;
};

export const ListenToConversationDocumentsStatus = ({
  conversationUuid,
  documents,
  conversationType,
}: {
  conversationUuid: ApiConversation['uuid'];
  documents: ApiDocument[];
  conversationType: ConversationTypeEnum;
}) =>
  documents.map((document) => (
    <ListenToConversationDocumentStatus
      key={document.uuid}
      document={document}
      conversationUuid={conversationUuid}
      conversationType={conversationType}
    />
  ));
