import { useTranslation } from 'react-i18next';

import type { ApiAgentDetail } from '@legalfly/api/agents';
import { ConversationTypeEnum } from '@legalfly/api/conversations';
import {
  DocumentsUploadProgress,
  useDocumentsUploadProgress,
} from '@legalfly/components/documentsUploadProgress';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import {
  OptimisticConversationProvider,
  useOptimisticConversationContext,
} from 'components/conversations/OptimisticConversationProvider';
import {
  ListenToConversationDocumentsStatus,
  useClearConversation,
} from 'core/modules/conversations';
import { conversationToasts } from 'core/modules/conversations/toasts';
import { useCreateFiles } from 'core/modules/documents/helpers/useCreateFiles';

import AgentCopilot from './AgentCopilot';

interface Props {
  agent: ApiAgentDetail;
}

const Component = ({ agent }: Props) => {
  const { t } = useTranslation();

  const { clearConversation } = useClearConversation({ type: ConversationTypeEnum.AGENT });
  const { createFiles, isCreatingFiles, numberOfFilesToUpload } = useCreateFiles('agents');
  const { conversation } = useOptimisticConversationContext();

  const handleClearConversation = async () => {
    if (!agent.sandboxConversationUuid) return;

    await withToasts(clearConversation({ uuid: agent.sandboxConversationUuid }))(
      conversationToasts.clearConversation(),
    );
  };

  const documentUploadProgress = useDocumentsUploadProgress(
    conversation?.documents ?? [],
    numberOfFilesToUpload,
  );

  return (
    <div
      className={cn(
        'flex flex-1 flex-col',
        !documentUploadProgress.isUploading
          ? 'h-content-with-row'
          : 'h-content-with-row-with-progress',
      )}
    >
      <div className='h-full flex-grow bg-fill-maximal py-8'>
        <div className='flex justify-between px-content-x'>
          <Button
            variant='soft'
            size='sm'
            renderLeft={<Icon name='refresh-ccw-2' size='xs' />}
            onClick={handleClearConversation}
          >
            {t('action.reset')}
          </Button>
          <Text className='text-content-body-strong'>{t('agents.detail.sandbox')}</Text>
        </div>
        <>
          <AgentCopilot
            agent={agent}
            conversationUuid={agent.sandboxConversationUuid}
            createFiles={createFiles}
            isCreatingFiles={isCreatingFiles}
            numberOfFilesToUpload={numberOfFilesToUpload}
            variant='sandbox'
          />
          <DocumentsUploadProgress className='mt-3' {...documentUploadProgress} />
          {conversation?.documents && conversation.documents.length > 0 && conversation?.uuid && (
            <ListenToConversationDocumentsStatus
              conversationUuid={conversation.uuid}
              documents={conversation.documents}
              conversationType={ConversationTypeEnum.AGENT}
            />
          )}
        </>
      </div>
    </div>
  );
};

export const AgentSandboxConversation = (props: Props) => {
  return (
    <OptimisticConversationProvider
      conversationUuid={props.agent.sandboxConversationUuid}
      conversationType={ConversationTypeEnum.AGENT}
    >
      <Component {...props} />
    </OptimisticConversationProvider>
  );
};
