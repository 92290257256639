import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import type { ApiDocument, ApiDocumentSnippetEntity } from '@legalfly/api/documents';
import { requiredValidator } from '@legalfly/components/forms/validators';
import { Button, IconButton } from '@legalfly/ui/button';
import { FormItem, FormItemLabel } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { Input } from '@legalfly/ui/input';
import { SidePaneClose } from '@legalfly/ui/sidePane';
import { withToasts } from '@legalfly/ui/toast';
import {
  documentsToasts,
  useCreateDocumentEntity,
  useDeleteDocumentEntity,
  useUpdateDocumentEntity,
} from 'core/modules/documents';

interface FormData {
  value: string;
  name: string;
}

interface Props {
  selectedEntity: Partial<ApiDocumentSnippetEntity> | null;
  documentUuid: ApiDocument['uuid'];
  onSubmitComplete?: () => void;
}

export const DocumentEntityForm = ({ selectedEntity, documentUuid, onSubmitComplete }: Props) => {
  const { t } = useTranslation();

  const { createDocumentEntity } = useCreateDocumentEntity();
  const { updateDocumentEntity } = useUpdateDocumentEntity();
  const { deleteDocumentEntity } = useDeleteDocumentEntity();

  const resetForm = () => {
    form.reset();
    form.setFieldValue('value', '');
    form.setFieldValue('name', '');
  };

  const handleSubmit = async ({ value }: { value: FormData }) => {
    if (selectedEntity?.uuid) {
      await withToasts(
        updateDocumentEntity({
          uuid: documentUuid,
          entityUuid: selectedEntity.uuid,
          body: {
            value: value.value,
            name: value.name,
          },
        }),
      )(documentsToasts.updateDocumentEntity());
    } else {
      await withToasts(
        createDocumentEntity({
          uuid: documentUuid,
          body: {
            name: value.name,
            value: value.value,
          },
        }),
      )(documentsToasts.createDocumentEntity());
    }

    resetForm();
    onSubmitComplete?.();
  };

  const handleDeleteEntity = async () => {
    if (!selectedEntity?.uuid) {
      return;
    }

    await withToasts(
      deleteDocumentEntity({
        uuid: documentUuid,
        entityUuid: selectedEntity?.uuid,
      }),
    )(documentsToasts.deleteDocumentEntity());

    resetForm();
    onSubmitComplete?.();
  };

  const form = useForm<FormData>({
    defaultValues: {
      value: selectedEntity?.value || '',
      name: selectedEntity?.name || '',
    },
    onSubmit: handleSubmit,
  });

  return (
    <form
      className='w-full'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormItem className='mt-4'>
        <FormItemLabel
          label={t('form.name.label')}
          className='mb-1 text-start text-content-body-strong'
        />
        <form.Field
          name='name'
          validators={{
            onBlur: ({ value }) => requiredValidator(t('form.name.validation.required'))(value),
          }}
        >
          {(field) => (
            <Input
              id={field.name}
              name={field.name}
              value={field.state.value}
              placeholder={t('form.name.placeholder')}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
              errorMessage={field.state.meta.errors.join()}
              className='h-9'
            />
          )}
        </form.Field>
      </FormItem>
      <FormItem className='mt-4'>
        <FormItemLabel
          label={t('form.value.label')}
          className='mb-1 text-start text-content-body-strong'
        />
        <form.Field
          name='value'
          validators={{
            onBlur: ({ value }) => requiredValidator(t('form.value.validation.required'))(value),
          }}
        >
          {(field) => (
            <Input
              id={field.name}
              placeholder={t('form.value.placeholder')}
              disabled={selectedEntity?.uuid === undefined}
              name={field.name}
              value={field.state.value}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
              errorMessage={field.state.meta.errors.join()}
              className='h-9'
            />
          )}
        </form.Field>
      </FormItem>

      <div className='mt-4 flex items-center justify-between gap-3'>
        <div className='flex items-center gap-3'>
          <SidePaneClose asChild>
            <Button variant='soft' renderLeft={<Icon name='x-close' />}>
              {t('action.cancel')}
            </Button>
          </SidePaneClose>
          <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
            {([canSubmit, isSubmitting]) => (
              <Button
                variant='soft'
                type='submit'
                disabled={!canSubmit}
                className='self-end'
                isLoading={isSubmitting}
                renderLeft={<Icon name='check' />}
              >
                {selectedEntity?.uuid ? t('action.save') : t('action.anonymise')}
              </Button>
            )}
          </form.Subscribe>
        </div>
        {selectedEntity?.uuid && (
          <IconButton
            className='size-4'
            variant='tertiary'
            name='trash-2'
            onClick={handleDeleteEntity}
          />
        )}
      </div>
    </form>
  );
};
