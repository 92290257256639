import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute, Navigate, redirect, useNavigate } from '@tanstack/react-router';

import { DocumentStatus } from '@legalfly/api/documents';
import { isDocumentProcessing } from '@legalfly/components/documents/helpers';
import {
  DocumentsUploadProgress,
  useDocumentsUploadProgress,
} from '@legalfly/components/documentsUploadProgress';
import { Divider } from '@legalfly/ui/divider';
import { Text } from '@legalfly/ui/text';
import { useWebSocketSubscriber } from '@legalfly/websockets';
import { DocumentActions } from 'components/documents/detail/DocumentActions';
import { DocumentProvider } from 'components/documents/detail/DocumentProvider';
import { DocumentViewer } from 'components/documents/detail/DocumentViewer';
import { NewReviewForm } from 'components/review/new/NewReviewForm';
import { documentsQueryOptions, useFile } from 'core/modules/documents';

export const Route = createFileRoute('/_auth/_layout/review/new')({
  component: NewReviewRoute,
  validateSearch: (search: {
    documentUuid: string;
  }): {
    documentUuid: string;
  } => {
    return {
      documentUuid: search?.documentUuid,
    };
  },
  beforeLoad: ({ search }) => {
    if (!search?.documentUuid) {
      throw redirect({ to: '/review/start' });
    }
  },
  loaderDeps: ({ search: { documentUuid } }) => ({ documentUuid }),
  loader({ context, deps: { documentUuid } }) {
    return context.queryClient.ensureQueryData(documentsQueryOptions.file(documentUuid));
  },
});

function NewReviewRoute() {
  const { documentUuid } = Route.useSearch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { file, refetch } = useFile(documentUuid);

  useWebSocketSubscriber(`document_upload_status_${file.uuid}`, () => {
    refetch();
  });

  const documentUploadProgress = useDocumentsUploadProgress(file ? [file] : [], 1);

  if (file.status === DocumentStatus.ERROR) {
    return <Navigate to='/review' />;
  }

  return (
    <div className='flex gap-3'>
      <div className='flex h-content w-3/5 flex-col gap-3'>
        <DocumentProvider document={file}>
          <DocumentActions>
            <DocumentActions.BackButton onClick={() => navigate({ to: '/review' })}>
              {t('review.title')}
            </DocumentActions.BackButton>
            {file.status !== DocumentStatus.ANONYMIZING && (
              <DocumentActions.Container>
                <DocumentActions.AnonymousButton />
                <DocumentActions.DownloadMenu />
              </DocumentActions.Container>
            )}
          </DocumentActions>
          <div className='h-full overflow-auto bg-fill-maximal px-content-x py-content-y'>
            <DocumentViewer />
          </div>
        </DocumentProvider>
      </div>
      <div
        className={`h-content w-2/5 overflow-auto bg-fill-maximal ${isDocumentProcessing(file) ? '' : 'p-10'}`}
      >
        {isDocumentProcessing(file) ? (
          <DocumentsUploadProgress {...documentUploadProgress} />
        ) : (
          <div className='relative'>
            <Text variant='heading2' className='mb-2 truncate'>
              {t('review.new.title')}
            </Text>
            <Text variant='bodyLarge' className='text-content-body-strong'>
              {t('review.new.description-1')}
            </Text>
            <Divider className='my-5' variant='weak' />
            <Text className='mb-5 text-content-body-placeholder'>
              {t('review.new.description-2')}
            </Text>
            <Suspense>
              <NewReviewForm document={file} />
            </Suspense>
          </div>
        )}
      </div>
    </div>
  );
}
