import { useNavigate } from '@tanstack/react-router';

import type { ApiNotification } from '@legalfly/api/notifications';

import { useDownloadZip } from '../documents/helpers/useDownloadZip';
import { useDeleteNotification } from '.';

export const useHandleNotificationClick = () => {
  const navigate = useNavigate();
  const { handleZipNotification, isDownloading } = useHandleZipNotification();

  const handleNotificationClick = (notification: ApiNotification) => {
    if (notification.type === 'FILE_UPLOADED') {
      navigate({
        to: '/anonymisation/file/$uuid',
        params: { uuid: notification.metadata.entityId },
      });
    } else if (notification.type === 'MULTI_REVIEW_COMPLETED') {
      navigate({
        to: '/multi-review/$uuid',
        params: { uuid: notification.metadata.entityId },
      });
    } else if (notification.type === 'FILE_UPLOAD_FAILED') {
      const path = notification.metadata.entityId
        ? {
            to: '/documents/folder/$uuid',
            params: { uuid: notification.metadata.entityId },
          }
        : { to: '/documents/folder' };

      navigate(path);
    } else if (notification.type === 'QUEUE_ERROR') {
      if (notification.metadata.entityType === 'file') {
        navigate({ to: '/documents/folder' });
      }
    } else if (notification.type === 'ZIP_CREATION_FAILED') {
      return undefined;
    } else if (notification.type === 'ZIP_CREATION_FINISHED') {
      return handleZipNotification(notification);
    } else {
      navigate({ to: '/' });
    }
    return undefined;
  };

  return { handleNotificationClick, isLoading: isDownloading };
};

const useHandleZipNotification = () => {
  const { downloadZip, isDownloading } = useDownloadZip();
  const { deleteNotification } = useDeleteNotification();

  const handleZipNotification = async (notification: ApiNotification) => {
    if (notification.type === 'ZIP_CREATION_FINISHED') {
      await downloadZip({ url: notification.metadata.url, filename: 'documents-archive.zip' });
      deleteNotification(notification.uuid);
    }
  };

  return {
    handleZipNotification,
    isDownloading,
  };
};
