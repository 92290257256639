import { useTranslation } from 'react-i18next';

import type { LLMModelType } from '@legalfly/api/core';
import { LLMModels } from '@legalfly/api/core';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@legalfly/ui/select';

export const SelectAIModel = ({
  value,
  onChange,
}: {
  value: LLMModelType;
  onChange: (value: LLMModelType) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className='min-w-28'>
        <SelectValue>{t(`config.llmModel.${value}`)}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {LLMModels.map((model) => (
          <SelectItem key={model} value={model}>
            {t(`config.llmModel.${model}`)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
