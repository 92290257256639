import { useQueryClient } from '@tanstack/react-query';

import type { ApiDocumentTypeFile } from '@legalfly/api/documents';
import { createPlaybooksModule } from '@legalfly/modules/playbooks';

import { playbooksApi } from '../../di';

const playbooksModule = createPlaybooksModule({
  playbooksApi,
});

export const {
  usePlaybooks,
  usePlaybook,
  playbookQueryOptions,
  useCreatePlaybook,
  useDeletePlaybook,
  useUpdatePlaybook,
  useCreatePlaybookItems,
  useDeletePlaybookItem,
  useUpdatePlaybookItem,
  useMovePlaybookItem,
  useCopyPlaybook,
} = playbooksModule;

export const useSystemPlaybooks = (category: ApiDocumentTypeFile['category']) => {
  const queryClient = useQueryClient();
  const res = playbooksModule.useSystemPlaybooks(category);

  const fetchSystemPlaybooks = async (category: ApiDocumentTypeFile['category']) => {
    return queryClient.fetchQuery(playbooksModule.playbookQueryOptions.systemPlaybooks(category));
  };

  return { fetchSystemPlaybooks, ...res };
};
