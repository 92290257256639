import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { type ApiReview, type ApiReviewItem, ReviewStatus } from '@legalfly/api/reviews';
import { createReviewsModule } from '@legalfly/modules/reviews';
import type { WebSocketCallback } from '@legalfly/websockets';
import { useWebSocketSubscriber } from '@legalfly/websockets';

import { reviewsApi } from '../../di';

export const {
  useReviews,
  useCreateReview,
  useReview,
  useDeleteReview,
  reviewsQueryOptions,
  useUpdateReview,
} = createReviewsModule({
  reviewsApi,
});

export const useListenToReviewStatus = ({ uuid }: { uuid: ApiReview['uuid'] }) => {
  const queryClient = useQueryClient();

  const listener: WebSocketCallback<`review_status_${string}`> = useCallback(
    (data) => {
      queryClient.setQueryData(
        reviewsQueryOptions.review({ uuid }).queryKey,
        (oldData) => oldData && { ...oldData, status: data.status },
      );

      if (data.status === ReviewStatus.COMPLETED) {
        queryClient.invalidateQueries({ queryKey: reviewsQueryOptions.review({ uuid }).queryKey });
      }
    },
    [uuid, queryClient],
  );

  useWebSocketSubscriber(`review_status_${uuid}`, listener);
};

export const useListenToReviewItem = ({
  reviewUuid,
  itemUuid,
}: {
  reviewUuid: ApiReview['uuid'];
  itemUuid: ApiReviewItem['uuid'];
}) => {
  const queryClient = useQueryClient();

  const listener: WebSocketCallback<`review_item_${string}`> = useCallback(
    (data) => {
      queryClient.setQueryData(
        reviewsQueryOptions.review({ uuid: reviewUuid }).queryKey,
        (oldData) => {
          if (!oldData) return oldData;
          const items = oldData.items.map((item) => (item.uuid === data.uuid ? data : item));
          return { ...oldData, items };
        },
      );
    },
    [reviewUuid, queryClient],
  );

  useWebSocketSubscriber(`review_item_${itemUuid}`, listener);
};
