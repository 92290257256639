import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { CollaborationApi } from '@legalfly/api/collaboration';

import { collaborationQueryOptions } from './collaborationQueryOptions';

export const createCollaborationModule = ({
  collaborationApi,
}: {
  collaborationApi: CollaborationApi;
}) => {
  const queryOptions = collaborationQueryOptions({ collaborationApi });

  const usePermissions = (params: Parameters<typeof collaborationApi.getPermissions>[0]) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.permissions(params));

    return {
      permissions: data,
      isLoading,
      error,
    };
  };

  const useSyncPermissions = (params: Parameters<typeof collaborationApi.getPermissions>[0]) => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['collaboration', 'syncPermissions'],
      mutationFn: collaborationApi.syncPermissions,
      onSuccess: (res) => {
        queryClient.setQueryData(queryOptions.permissions(params).queryKey, res);
      },
    });

    return {
      syncPermissions: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    usePermissions,
    useSyncPermissions,
    collaborationQueryOptions: queryOptions,
  };
};
