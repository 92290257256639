import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const commentsToasts = createToasts({
  deleteComment: () => ({
    errorToast: {
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteComment.description'),
    },
  }),
  addComment: () => ({
    errorToast: {
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.addComment.description'),
    },
  }),
});
