export enum FeedbackFeature {
  Review = 'review',
  MultiReview = 'multi_review',
  Dashboard = 'dashboard',
  Drafts = 'drafts',
  Templates = 'templates',
  Discovery = 'discovery',
  Anonymization = 'anonymization',
  Documents = 'documents',
  Settings = 'settings',
  Playbooks = 'playbooks',
  Agents = 'agents',
}
