import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type ApiDocumentSnippetEntity, DocumentStatus } from '@legalfly/api/documents';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { SidePaneTrigger, useSidePaneContext } from '@legalfly/ui/sidePane';
import { Spinner } from '@legalfly/ui/spinner';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { useWebSocketSubscriber } from '@legalfly/websockets';
import HighlightPopover from 'components/common/highlight/HighlightPopover';
import { AnonymisationSidePane } from 'components/documents/AnonymisationSidePane';
import { useFileSnippets } from 'core/modules/documents';

import { useDocumentContext } from './DocumentProvider';
import DocumentSnippet from './DocumentSnippet';

interface Props {
  activeSnippetTextId?: string;
  activeSnippetRiskLevel?: string;
  selectedEntity?: Partial<ApiDocumentSnippetEntity> | null;
  onEntityClick?: (entity: ApiDocumentSnippetEntity) => void;
  className?: string;
}

export const DocumentViewer = ({
  activeSnippetTextId,
  activeSnippetRiskLevel,
  selectedEntity,
  onEntityClick,
  className,
}: Props) => {
  const { isAnonymous, document } = useDocumentContext();
  const { snippets, refetch, isLoading } = useFileSnippets(document.uuid);

  useEffect(() => {
    if (!activeSnippetTextId || document.status !== DocumentStatus.COMPLETE) {
      return;
    }

    const observer = new MutationObserver(() => {
      scrollToSnippet(activeSnippetTextId);
    });

    observer.observe(window.document.body, { childList: true, subtree: true });

    scrollToSnippet(activeSnippetTextId);

    // Cleanup the observer when the component unmounts or dependencies change
    return () => observer.disconnect();
  }, [activeSnippetTextId, document.status]);

  const scrollToSnippet = (snippet: string) => {
    const element = window.document.getElementById(snippet);
    if (element) {
      element.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  useWebSocketSubscriber(`document_upload_status_${document.uuid}`, () => {
    refetch();
  });

  if (isLoading || snippets?.text.length === 0) {
    return (
      <div className='my-5 flex justify-center'>
        <Spinner size='md' />
      </div>
    );
  }

  return (
    <div className={cn('h-full w-auto px-10 py-3', className)}>
      <Text as='h2' variant='subheadingSemiBold' className='break-all'>
        {document.name}
      </Text>
      <div className='prose py-4 dark:prose-invert'>
        {snippets?.text.map((snippet) => (
          <DocumentSnippet
            key={snippet.uuid}
            entities={snippets.entities}
            snippet={snippet}
            isAnonymous={isAnonymous}
            selectedText={selectedEntity?.value || ''}
            onEntityClick={onEntityClick}
            isActive={snippet.uuid === activeSnippetTextId}
            riskLevel={activeSnippetRiskLevel}
          />
        ))}
      </div>
    </div>
  );
};

export const DocumentViewerWithAnonymisation = () => {
  const { t } = useTranslation();
  const { onOpenChange } = useSidePaneContext('AnonymisationDetail');
  const [selectedEntity, setSelectedEntity] = useState<ApiDocumentSnippetEntity | null>(null);

  const [highlightedText, setHighlightedText] = useState<string>('');

  const handleHighlight = useCallback(
    (text: string) => {
      setHighlightedText(text);
    },
    [setHighlightedText],
  );

  const handleNewEntityHighlight = () => {
    setSelectedEntity({
      value: highlightedText,
      name: '',
      uuid: '',
      type: '',
    });
    onOpenChange(true);
  };

  const handleEntityClick = (entity: ApiDocumentSnippetEntity) => {
    setSelectedEntity({
      value: entity.value,
      name: entity.name,
      uuid: entity.uuid,
      type: entity.type,
    });
  };

  return (
    <HighlightPopover
      onHighlight={handleHighlight}
      popoverContent={
        <SidePaneTrigger asChild onClick={handleNewEntityHighlight}>
          <Button
            className='m-1 border-0 outline-none hover:bg-surface-base'
            renderLeft={<Icon name='plus-circle' />}
            onClick={handleNewEntityHighlight}
          >
            {t('anonymisation.actions.anonymise')}
          </Button>
        </SidePaneTrigger>
      }
    >
      <DocumentViewer selectedEntity={selectedEntity} onEntityClick={handleEntityClick} />
      <AnonymisationSidePane
        selectedEntity={selectedEntity}
        setSelectedEntity={setSelectedEntity}
      />
    </HighlightPopover>
  );
};
