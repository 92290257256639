import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import type { PlaybookItem } from '@legalfly/backend/src/entity/PlaybookItem';

export function mapStringToPlaybookItems(
  playbookItems: string,
): Pick<PlaybookItem, 'name' | 'question' | 'type'>[] {
  return playbookItems
    .split('\n')
    .filter((item) => item.trim() !== '')
    .map((item) => ({ name: item, question: item, type: PlaybookItemTypeEnum.QUESTION }));
}
