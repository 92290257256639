import { useTranslation } from 'react-i18next';

import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import { FormItemLabel } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';

import { PlaybookItemTooltip } from '../PlaybookItemTooltip';
import type { PlaybookItemFormType } from '../types';

interface PlaybookItemQuestionLabelProps {
  type: PlaybookItemFormType;
}

export const PlaybookItemQuestionLabel = ({ type }: PlaybookItemQuestionLabelProps) => {
  const { t } = useTranslation();
  return (
    <div className='mb-2 flex items-center gap-2'>
      <FormItemLabel
        label={`${
          {
            [PlaybookItemTypeEnum.QUESTION]: t('playbooks.question.question'),
            [PlaybookItemTypeEnum.YES_NO]: t('playbooks.question.condition'),
            [PlaybookItemTypeEnum.RISK_ASSESSMENT]: t('playbooks.question.topic'),
            [PlaybookItemTypeEnum.MULTIPLE_CHOICE]: t('playbooks.question.condition'),
          }[type]
        }*`}
        htmlFor='question'
      />
      <PlaybookItemTooltip description={t('tooltips.playbooks.question')}>
        <Icon name='info-circle' className='-mt-2' />
      </PlaybookItemTooltip>
    </div>
  );
};
