import { useTranslation } from 'react-i18next';
import type { NotFoundRouteProps } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';

import { BaseDefaultNotFound } from '@legalfly/components/errors/BaseDefaultNotFound';

import { Layout } from '../layout/Layout';

export const DefaultNotFound = (props: NotFoundRouteProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Layout>
      <BaseDefaultNotFound
        {...props}
        className='h-content'
        cta={{ label: t('errors.card.cta'), onClick: () => navigate({ to: '/' }) }}
      />
    </Layout>
  );
};
