import { queryOptions } from '@tanstack/react-query';

import type { CommentsApi } from '@legalfly/api/comments';

export const commentsQueryOptions = ({ commentsApi }: { commentsApi: CommentsApi }) => ({
  comments: (params: Parameters<CommentsApi['getComments']>[0]) =>
    queryOptions({
      queryKey: ['comments', params].filter(Boolean),
      queryFn: () => commentsApi.getComments(params),
    }),
});
