import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';
import { useDefaultJurisdiction } from 'common/hooks/useDefaultJurisdiction';

import {
  emailValidator,
  firstNameValidator,
  lastNameValidator,
} from '@legalfly/components/forms/validators';
import { SelectJurisdiction } from '@legalfly/components/jurisdiction/SelectJurisdiction';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { FormItem, FormItemLabel } from '@legalfly/ui/form';
import { Input } from '@legalfly/ui/input';
import { withToasts } from '@legalfly/ui/toast';
import { useCreateUser } from 'core/modules/users';
import { userToasts } from 'core/modules/users/toasts';

interface Props {
  afterSubmit: () => void;
}

export const CreateUserForm = ({ afterSubmit }: Props) => {
  const { t } = useTranslation();
  const { createUser } = useCreateUser();

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    jurisdiction: useDefaultJurisdiction(),
  };

  type FormValues = typeof defaultValues;

  const onCreateUser = async ({ value }: { value: FormValues }) => {
    trackEvent({
      category: 'users',
      action: 'click',
      label: 'invite',
    });
    await withToasts(
      createUser({
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        jurisdiction: value.jurisdiction,
      }),
    )(userToasts.createUser());
    afterSubmit();
  };

  const form = useForm<FormValues>({
    defaultValues,
    onSubmit: onCreateUser,
  });

  return (
    <form
      className='flex flex-col gap-4'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormItem>
        <FormItemLabel label={t('form.email.label')} />
        <form.Field
          name='email'
          validators={{
            onBlur: ({ value }) => emailValidator(t)(value),
          }}
        >
          {(field) => (
            <Input
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              autoComplete='email'
              errorMessage={field.state.meta.errors.join()}
            />
          )}
        </form.Field>
      </FormItem>

      <div className='flex flex-col gap-4 md:flex-row'>
        <FormItem className='flex-1'>
          <FormItemLabel label={t('form.firstName.label')} htmlFor='firstName' />
          <form.Field
            name='firstName'
            validators={{
              onBlur: ({ value }) => firstNameValidator(t)(value),
            }}
          >
            {(field) => (
              <Input
                id={field.name}
                name={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                autoComplete='given-name'
                errorMessage={field.state.meta.errors.join()}
              />
            )}
          </form.Field>
        </FormItem>

        <FormItem className='flex-1'>
          <FormItemLabel label={t('form.lastName.label')} htmlFor='lastName' />
          <form.Field
            name='lastName'
            validators={{
              onBlur: ({ value }) => lastNameValidator(t)(value),
            }}
          >
            {(field) => (
              <Input
                id={field.name}
                name={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                autoComplete='family-name'
                errorMessage={field.state.meta.errors.join()}
              />
            )}
          </form.Field>
        </FormItem>
      </div>
      <FormItem className='flex-1'>
        <FormItemLabel label={t('form.jurisdiction.label')} htmlFor='jurisdiction' />
        <form.Field
          name='jurisdiction'
          validators={{
            onBlur: ({ value }) => lastNameValidator(t)(value),
          }}
        >
          {(field) => (
            <SelectJurisdiction
              value={field.state.value}
              onChange={(value) => {
                field.handleChange(value);
              }}
              triggerProps={{ className: 'px-3 py-4 w-full' }}
              contentProps={{ align: 'end' }}
            />
          )}
        </form.Field>
      </FormItem>

      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit, isSubmitting]) => (
          <Button type='submit' disabled={!canSubmit} className='self-end' isLoading={isSubmitting}>
            {t('action.inviteUser')}
          </Button>
        )}
      </form.Subscribe>
    </form>
  );
};
