import { queryOptions } from '@tanstack/react-query';

import type { ApiDocument, ApiFolderContents, DocumentsApi } from '@legalfly/api/documents';

export const documentsQueryOptions = ({ documentsApi }: { documentsApi: DocumentsApi }) => ({
  folder: <TData = ApiFolderContents>({
    uuid,
    select,
  }: {
    uuid?: ApiDocument['uuid'];
    select?: (data: ApiFolderContents) => TData;
  } = {}) =>
    queryOptions({
      queryKey: ['folder', uuid],
      queryFn: () => documentsApi.getFolder({ uuid }),
      select,
    }),
  file: (uuid: ApiDocument['uuid']) =>
    queryOptions({
      queryKey: ['file', uuid],
      queryFn: () => documentsApi.getFile({ uuid }),
    }),
  fileSnippets: (uuid: ApiDocument['uuid']) =>
    queryOptions({
      queryKey: ['file', 'snippets', uuid],
      queryFn: () => documentsApi.getFileSnippets({ uuid }),
    }),
  fileSummary: (uuid: ApiDocument['uuid']) =>
    queryOptions({
      queryKey: ['file', 'summary', uuid],
      queryFn: () => documentsApi.getFileSummary({ uuid }),
    }),
  getFileDownloadUrl: (uuid: ApiDocument['uuid']) =>
    queryOptions({
      queryKey: ['file', 'download', uuid],
      queryFn: () => documentsApi.getFileDownloadUrl({ uuid }),
    }),
  getAnonymizedFileDownloadUrl: (uuid: ApiDocument['uuid']) =>
    queryOptions({
      queryKey: ['file', 'download', 'anonymised', uuid],
      queryFn: () => documentsApi.getAnonymizedFileDownloadUrl({ uuid }),
    }),
  countFolderDocuments: ({
    uuids,
    enabled = true,
  }: {
    uuids: ApiDocument['uuid'][];
    enabled: boolean;
  }) =>
    queryOptions({
      queryKey: ['count', 'documents', uuids.join()],
      queryFn: () => documentsApi.countFolderDocuments({ uuids: uuids.join() }),
      enabled,
      placeholderData: (prev) => prev,
    }),
});
