import type { ComponentProps } from 'react';

import { CommentsList } from '@legalfly/components/comments/CommentsList';
import { IconButton } from '@legalfly/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@legalfly/ui/popover';
import { cn } from '@legalfly/ui/utils';

import { AddCommentRow } from './AddCommentRow';

export const BaseCommentsPopover = ({ children, ...rest }: ComponentProps<typeof Popover>) => {
  return (
    <Popover {...rest}>
      <PopoverTrigger asChild>
        <IconButton
          name='message-text-square-1'
          variant='tertiary'
          size='sm'
          className='data-[state=open]:bg-fill-hover-strong'
        />
      </PopoverTrigger>
      {children}
    </Popover>
  );
};

export const BaseCommentsPopoverContent = ({
  children,
  className,
  ...rest
}: ComponentProps<typeof PopoverContent>) => {
  return (
    <PopoverContent align='end' className={cn('w-[450px] p-0', className)} {...rest}>
      <div className='flex flex-col'>{children}</div>
    </PopoverContent>
  );
};

BaseCommentsPopoverContent.CommentsList = CommentsList;
BaseCommentsPopoverContent.AddCommentRow = AddCommentRow;
