import { useSnippetHighlights } from '../documents/hooks/useSnippetHighlights';

interface Props {
  children: React.ReactNode;
  snippetIds: string[];
  riskLevel: string;
  startIndex: number;
  activeSnippetTextId: string;
  onHighlight: (snippetId: string, riskLevel: string) => void;
}

export const SnippetHighlightBackground = ({
  children,
  snippetIds,
  riskLevel,
  startIndex,
  activeSnippetTextId,
  onHighlight,
}: Props) => {
  const { handleNext } = useSnippetHighlights({
    index: startIndex,
    snippetIds,
    riskLevel,
    activeSnippetTextId,
    onHighlight,
  });

  if (!snippetIds.length) {
    return <>{children}</>;
  }

  return (
    <button className='w-full text-left' onClick={handleNext}>
      {children}
    </button>
  );
};
