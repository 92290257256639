import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { SuggestionProps } from '@tiptap/suggestion';

import type { ApiUser } from '@legalfly/api/users';
import { Command, CommandEmpty, CommandItem, CommandList } from '@legalfly/ui/command';
import { Popover, PopoverAnchor, PopoverContent } from '@legalfly/ui/popover';
import { useDebouncedValue } from '@legalfly/utils/hooks';

type Props = SuggestionProps<ApiUser, { id: string; label: string }> & {
  useUsers: (params: { isSuspenseEnabled: boolean; keyword: string; usePreviousData: boolean }) => {
    users: ApiUser[];
  };
};
type Ref = { onKeyDown: (p: { event: KeyboardEvent }) => boolean };

export const MentionsList = forwardRef<Ref, Props>(
  ({ query, command, clientRect, useUsers }, ref) => {
    const { t } = useTranslation('components');
    const commandRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(true);

    const debouncedQuery = useDebouncedValue(query, 300);
    const { users } = useUsers({
      isSuspenseEnabled: false,
      keyword: debouncedQuery,
      usePreviousData: true,
    });

    // after pressing escape, the popover is closed, so we need to open it again when the query changes
    useEffect(() => {
      setOpen(true);
    }, [debouncedQuery]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        // forward these events to the command list
        if (['ArrowUp', 'ArrowDown', 'Enter'].includes(event.key)) {
          event.preventDefault();
          event.stopPropagation();

          commandRef.current?.dispatchEvent(
            new KeyboardEvent('keydown', {
              key: event.key,
              bubbles: true,
            }),
          );

          return true;
        }

        if (event.key === 'Escape') {
          setOpen(false);
          return true;
        }

        return false;
      },
    }));

    // https://github.com/radix-ui/primitives/blob/main/packages/react/popper/src/Popper.tsx#L84
    // can be anything that has getBoundingClientRect method, so the popover will find out where to display it
    const virtualRef = useRef({
      getBoundingClientRect: clientRect,
    });

    return (
      <Popover open={open} onOpenChange={setOpen} modal={false}>
        {/* @ts-expect-error tis ok */}
        <PopoverAnchor virtualRef={virtualRef} />
        <PopoverContent
          className='w-64 p-0'
          align='start'
          // approximate width of the @ character
          alignOffset={12}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <Command ref={commandRef}>
            <CommandList>
              {users.length ? (
                users.map((user) => (
                  <CommandItem
                    key={user.uuid}
                    value={user.uuid}
                    onSelect={() =>
                      command({ id: user.uuid, label: `${user.firstName} ${user.lastName}` })
                    }
                  >
                    {user.firstName} {user.lastName}
                  </CommandItem>
                ))
              ) : (
                <CommandEmpty className='h-12 px-4 py-3'>
                  {t('comments.create.search.empty')}
                </CommandEmpty>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

MentionsList.displayName = 'MentionsList';
