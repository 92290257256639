export const permissionConfig = {
  entities: {
    agent: [],
    discovery: [],
    draft: [],
    multiReview: [],
    review: [],
    template: [],
  },
  owners: ['user', 'team', 'organization'] as const,
} as const;

export const permissionEntityTypes = [
  'agent',
  'discovery',
  'draft',
  'multiReview',
  'review',
  'template',
] as const;

export const baseActions = ['view' /*, 'edit', 'remove'*/] as const;

export const uniquePermissions = [
  ...baseActions,
  ...Object.values(permissionConfig.entities).flat(),
] as const;

export type PermissionEntityType = (typeof permissionEntityTypes)[number];
export type PermissionOwnerType = (typeof permissionConfig.owners)[number];
