import deepmerge from '@fastify/deepmerge';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { ApiReview, ReviewsApi } from '@legalfly/api/reviews';

import { reviewsQueryOptions } from './reviewsQueryOptions';

const merge = deepmerge({ all: true });

export const createReviewsModule = ({ reviewsApi }: { reviewsApi: ReviewsApi }) => {
  const queryOptions = reviewsQueryOptions({ reviewsApi });

  const useReviews = (query?: Parameters<ReviewsApi['getReviews']>[0]) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.reviews(query));

    return {
      reviews: data.reviews,
      stats: data.stats,
      isLoading,
      error,
    };
  };

  const useReview = ({ uuid }: { uuid: ApiReview['uuid'] }) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.review({ uuid }));

    return {
      review: data,
      isLoading,
      error,
    };
  };

  const useCreateReview = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['review', 'create'],
      mutationFn: reviewsApi.createReview,
      onSuccess: (review) => {
        queryClient.setQueryData(queryOptions.reviews().queryKey, (data) => {
          if (!data) {
            return;
          }

          return {
            ...data,
            reviews: [...(data?.reviews ?? []), review],
          };
        });

        queryClient.setQueryData(queryOptions.review({ uuid: review.uuid }).queryKey, review);
      },
    });

    return {
      createReview: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteReview = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['review', 'delete'],
      mutationFn: reviewsApi.deleteReview,
      onSuccess: (_, { uuid }) => {
        queryClient.setQueryData(queryOptions.reviews().queryKey, (data) => {
          if (!data) {
            return;
          }

          return {
            ...data,
            reviews: data.reviews?.filter((review) => review.uuid !== uuid),
          };
        });
      },
    });

    return {
      deleteReview: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateReview = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['review', 'update'],
      mutationFn: reviewsApi.updateReview,
      onSuccess: (review) => {
        queryClient.setQueryData(queryOptions.review({ uuid: review.uuid }).queryKey, (old) => {
          return merge(review, old);
        });

        queryClient.setQueryData(queryOptions.reviews().queryKey, (data) => {
          if (!data) return;

          return {
            ...data,
            reviews: data.reviews.map((r) => (r.uuid === review.uuid ? merge(r, review) : r)),
          };
        });
      },
    });

    return {
      updateReview: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useReviews,
    useReview,
    useCreateReview,
    useDeleteReview,
    useUpdateReview,
    reviewsQueryOptions: queryOptions,
  };
};
