import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import { agentStatus, type AgentStatusType } from '@legalfly/api/agents';
import { nameValidator, requiredValidator } from '@legalfly/components/forms/validators';
import { Button } from '@legalfly/ui/button';
import { FormItem, FormItemLabel } from '@legalfly/ui/form';
import { type IconName } from '@legalfly/ui/icon';
import { Input } from '@legalfly/ui/input';
import { Textarea } from '@legalfly/ui/textarea';
import { cn } from '@legalfly/ui/utils';

import { AgentIconPickerPopover } from './AgentIconPickerPopover';

interface FormValues {
  name: string;
  icon: IconName;
  description?: string;
  status: AgentStatusType;
}

interface Props {
  defaultValues: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onCancel: VoidFunction;
  submitLabel: string;
  includeDescription?: boolean;
  isLoading?: boolean;
}

export const AgentForm = ({
  defaultValues,
  onSubmit,
  onCancel,
  isLoading,
  submitLabel,
  includeDescription = false,
}: Props) => {
  const { t } = useTranslation();

  const handleSubmit = async ({ value }: { value: FormValues }) => {
    await onSubmit(value);
  };

  const form = useForm<FormValues>({
    defaultValues,
    onSubmit: handleSubmit,
  });

  return (
    <form
      className='flex flex-col gap-4'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormItem>
        <FormItemLabel label={t('form.playbookStatus.label')} htmlFor='status' />
        <form.Field name='status'>
          {(field) => (
            <div className='flex gap-4'>
              <Button
                className={cn(field.state.value === agentStatus.PRIVATE && 'bg-fill-weak')}
                onClick={() => field.handleChange(agentStatus.PRIVATE)}
              >
                {t('form.status.private')}
              </Button>
              <Button
                className={cn(field.state.value === agentStatus.PUBLIC && 'bg-fill-weak')}
                onClick={() => field.handleChange(agentStatus.PUBLIC)}
              >
                {t('form.status.company')}
              </Button>
            </div>
          )}
        </form.Field>
      </FormItem>
      <div className='flex flex-row gap-4'>
        <FormItem className='flex-1'>
          <FormItemLabel
            className='text-content-body-strong'
            label={t('form.agentName.label')}
            htmlFor='name'
          />
          <form.Field
            name='name'
            validators={{
              onBlur: ({ value }) => nameValidator(t)(value),
            }}
          >
            {(field) => (
              <Input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                id={field.name}
                name={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                placeholder={t('form.agentName.placeholder')}
                onChange={(e) => field.handleChange(e.target.value)}
                errorMessage={field.state.meta.errors.join()}
              />
            )}
          </form.Field>
        </FormItem>
        <FormItem>
          <FormItemLabel
            className='text-content-body-strong'
            label={t('form.agentIcon.label')}
            htmlFor='icon'
          />
          <form.Field name='icon'>
            {(field) => (
              <AgentIconPickerPopover value={field.state.value} onChange={field.handleChange} />
            )}
          </form.Field>
        </FormItem>
      </div>

      {includeDescription && (
        <FormItem>
          <FormItemLabel
            className='text-content-body-strong'
            label={t('form.agentDescription.label')}
            htmlFor='description'
          />
          <form.Field
            name='description'
            validators={{
              onBlur: ({ value }) =>
                requiredValidator(t('form.agentDescription.validation.required'))(value ?? ''),
            }}
          >
            {(field) => (
              <Textarea
                id={field.name}
                name={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                placeholder={t('form.agentDescription.placeholder')}
                onChange={(e) => field.handleChange(e.target.value)}
                errorMessage={field.state.meta.errors.join()}
                rows={5}
              />
            )}
          </form.Field>
        </FormItem>
      )}

      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit, isSubmitting]) => (
          <div className='flex items-center justify-end gap-4'>
            <Button onClick={onCancel}>{t('action.cancel')}</Button>
            <Button
              type='submit'
              disabled={!canSubmit}
              className='self-end'
              isLoading={isLoading || isSubmitting}
            >
              {submitLabel}
            </Button>
          </div>
        )}
      </form.Subscribe>
    </form>
  );
};
