import type { ComponentProps } from 'react';

import { BaseCopilotScrollContainer } from '@legalfly/components/copilot/BaseCopilotScrollContainer';

import { useCopilotContext } from './CopilotProvider';

type Props = Omit<
  ComponentProps<typeof BaseCopilotScrollContainer>,
  'hasMessages' | 'containerRef'
>;

export const CopilotScrollContainer = (props: Props) => {
  const { conversation, containerRef } = useCopilotContext();

  const hasMessages = Boolean(conversation?.messages.length);

  return (
    <BaseCopilotScrollContainer hasMessages={hasMessages} containerRef={containerRef} {...props} />
  );
};
