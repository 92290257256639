import { createFileRoute } from '@tanstack/react-router';

import { withSelectedDocuments } from '@legalfly/components/documentPicker/withSelectedDocuments';
import { documentsQueryOptions } from 'core/modules/documents';

import { ReviewStartRoute } from './index';

export const Route = createFileRoute('/_auth/_layout/review/start/$uuid')({
  component: withSelectedDocuments(ReviewStartNestedFolderRoute),
  loader: ({ context, params }) =>
    context.queryClient.ensureQueryData(documentsQueryOptions.folder({ uuid: params.uuid })),
});

function ReviewStartNestedFolderRoute() {
  const { uuid } = Route.useParams();
  return <ReviewStartRoute uuid={uuid} />;
}
