import type { ComponentProps } from 'react';

import { Text } from '@legalfly/ui/text';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@legalfly/ui/tooltip';

interface TooltipProps {
  title?: string;
  description?: string;
  children: React.ReactNode;
  side?: ComponentProps<typeof TooltipContent>['side'];
  hideArrow?: boolean;
}

export const PlaybookItemTooltip = ({
  title,
  description,
  children,
  side = 'right',
  hideArrow = false,
}: TooltipProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div>{children}</div>
        </TooltipTrigger>
        <TooltipContent side={side}>
          <div className='flex max-w-80 flex-col gap-2 p-4'>
            {title && <Text variant='bodySemiBold'>{title}</Text>}
            {description && <Text variant='bodyLight'>{description}</Text>}
          </div>
          {!hideArrow && <TooltipArrow width={24} height={16} />}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
