import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const collaborationToasts = createToasts({
  syncPermissions: () => ({
    errorToast: {
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.syncPermissions.description'),
    },
  }),
});
