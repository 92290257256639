import { useTranslation } from 'react-i18next';

import { ErrorCard } from '@legalfly/ui/error';

interface Props {
  className?: string;
  cta?: {
    label: string;
    onClick: () => void;
  };
}

export const BaseDefaultNotFound = ({ className, cta }: Props) => {
  const { t } = useTranslation('components');

  return (
    <ErrorCard className={className}>
      <ErrorCard.Content>
        <ErrorCard.Title>{t('errors.notFound.title')}</ErrorCard.Title>
        <ErrorCard.Message>{t('errors.notFound.message')}</ErrorCard.Message>
        {cta && <ErrorCard.Action onClick={cta.onClick}>{cta.label}</ErrorCard.Action>}
      </ErrorCard.Content>
    </ErrorCard>
  );
};
