import { useEffect, useRef } from 'react';
import { useForm } from '@tanstack/react-form';

import { Input } from '@legalfly/ui/input';

interface Props {
  name: string;
  onSubmit: (name: string) => Promise<void>;
  onCancel: () => void;
}

export const EditableNameField = ({ name: defaultName, onSubmit, onCancel }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const form = useForm({
    defaultValues: {
      name: defaultName,
    },
    onSubmit: async ({ value }) => {
      if (value.name !== defaultName) {
        await onSubmit(value.name);
      } else {
        onCancel();
      }
    },
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  const handleSelect = () => {
    inputRef.current?.select();
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <form.Field name='name'>
        {(field) => (
          <Input
            ref={inputRef}
            value={field.state.value}
            onChange={(e) => field.handleChange(e.target.value)}
            onFocus={handleSelect}
            onMouseUp={(e) => {
              e.preventDefault();
              handleSelect();
            }}
            onBlur={() => {
              field.handleBlur();
              form.handleSubmit();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') form.handleSubmit();
              if (e.key === 'Escape') onCancel();
            }}
            onClick={(e) => e.stopPropagation()}
            className='h-9'
          />
        )}
      </form.Field>
    </form>
  );
};
