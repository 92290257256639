import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Toasts } from '@legalfly/ui/toast';
import { createToasts, withToasts } from '@legalfly/ui/toast';

const stripHtmlTags = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export function useCopyToClipboard(toast?: Toasts) {
  const { t } = useTranslation('components');

  const toasts = useMemo(() => {
    return createToasts({
      copyToClipboard() {
        return {
          successToast: {
            title: t('toast.success.copyToClipboard.title'),
            description: t('toast.success.copyToClipboard.description'),
          },
          errorToast: {
            title: t('toast.error.title'),
            description: t('toast.error.copyToClipboard.description'),
          },
          ...toast,
        };
      },
    });
  }, [t, toast]);

  const copyToClipboard = useCallback(
    async (text: string) => {
      if (!navigator.clipboard) {
        console.warn('Clipboard API not available');
        return;
      }

      await withToasts(navigator.clipboard.writeText(stripHtmlTags(text)))(
        toasts.copyToClipboard(),
      );
    },
    [toasts],
  );

  return copyToClipboard;
}
