import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import type { LLMModelType } from '@legalfly/api/core';
import type { ApiPlaybook, ApiPlaybookItem } from '@legalfly/api/playbooks';
import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import { UserRole } from '@legalfly/api/users';
import { requiredValidator } from '@legalfly/components/forms/validators';
import type { OptionValues } from '@legalfly/components/playbooks/PlaybookMultipleChoiceOptionsDialog';
import { trackEvent } from '@legalfly/reporting/tracking';
import { FormItem, FormItemLabel } from '@legalfly/ui/form';
import { TextareaAutoSize } from '@legalfly/ui/textarea';
import { SelectAIModel } from 'components/admin/SelectAIModel';
import { useUpdatePlaybookItem } from 'core/modules/playbooks';
import { useCurrentUser } from 'core/modules/users';

import { usePlaybookItemActions } from './context/PlaybookItemProvider';
import { PlaybookItemContextMultipleChoiceOptions } from './PlaybookItemFormComponents/PlaybookItemContextMultipleChoiceOptions';
import { PlaybookItemContextRedraftInstructionsField } from './PlaybookItemFormComponents/PlaybookItemContextRedraftInstructionsField';
import { PlaybookItemContextTextField } from './PlaybookItemFormComponents/PlaybookItemContextTextField';
import { PlaybookItemNameField } from './PlaybookItemFormComponents/PlaybookItemNameField';
import { PlaybookItemQuestionLabel } from './PlaybookItemFormComponents/PlaybookItemQuestionLabel';
import { PlaybookItemTypeField } from './PlaybookItemFormComponents/PlaybookItemTypeField';

interface Props {
  playbookItem: ApiPlaybookItem;
  playbookUuid: ApiPlaybook['uuid'];
}

type PlaybookItemFormType = Exclude<PlaybookItemTypeEnum, PlaybookItemTypeEnum.ANOMALY>;

export const PlaybookItemForm = ({ playbookItem, playbookUuid }: Props) => {
  const { t } = useTranslation();
  const { reset, setIsFormValid } = usePlaybookItemActions();

  const { updatePlaybookItem } = useUpdatePlaybookItem();
  const { currentUser } = useCurrentUser();

  const defaultValues = {
    type: playbookItem.type as PlaybookItemFormType,
    name: playbookItem.name,
    question: playbookItem.question,
    contextText: playbookItem.context?.text ?? '',
    contextDocumentIds: playbookItem.context?.documentIds ?? [],
    contextMultipleChoiceOptions: playbookItem.context?.multipleChoiceOptions ?? [],
    contextRedraftInstructions: playbookItem.context?.redraftInstructions ?? '',
    contextLLMModel: playbookItem.context?.llmModel ?? 'default',
  };

  const form = useForm<typeof defaultValues>({
    defaultValues,
  });

  // the onMount validator is not cleared when input changes
  // need to recheck the actual values for validation :/
  // https://github.com/TanStack/form/issues/689
  const [name, question] = form.useStore((state) => [state.values.name, state.values.question]);
  useEffect(() => {
    setIsFormValid(Boolean(name) && Boolean(question));
  }, [name, question, setIsFormValid]);

  const onChangeType = (type: PlaybookItemFormType) => {
    trackEvent({
      action: 'click',
      category: 'playbooksItem',
      label: 'changeType',
    });

    // don't reset the selected playbook item and isFormValid
    reset(['selectedPlaybookItem', 'isFormValid']);
    form.setFieldValue('type', type);

    if (form.getFieldValue('contextMultipleChoiceOptions')?.length > 0) {
      form.setFieldValue('contextMultipleChoiceOptions', []);
    }

    updatePlaybookItem({
      playbookUuid,
      itemUuid: playbookItem.uuid,
      body: { type, context: { multipleChoiceOptions: [] } },
    });
  };

  const onBlurName = (value: string) => {
    updatePlaybookItem({
      playbookUuid,
      itemUuid: playbookItem.uuid,
      body: { name: value },
    });
  };

  const onBlurQuestion = (value: string) => {
    updatePlaybookItem({
      playbookUuid,
      itemUuid: playbookItem.uuid,
      body: { question: value },
    });
  };

  const onBlurContext = <Key extends keyof NonNullable<ApiPlaybookItem['context']>>(
    contextKey: Key,
    value: NonNullable<ApiPlaybookItem['context']>[Key],
  ) => {
    updatePlaybookItem({
      playbookUuid,
      itemUuid: playbookItem.uuid,
      body: { context: { [contextKey]: value } },
    });
  };

  const onChangeMultipleChoiceOptions = (updatedOptions: OptionValues[]) => {
    updatePlaybookItem({
      playbookUuid,
      itemUuid: playbookItem.uuid,
      body: {
        context: {
          multipleChoiceOptions: updatedOptions,
        },
      },
    });
  };

  const onChangeModel = (value: LLMModelType) => {
    updatePlaybookItem({
      playbookUuid,
      itemUuid: playbookItem.uuid,
      body: { context: { llmModel: value } },
    });
  };

  return (
    <form
      className='mb-10 flex flex-col gap-4'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormItem>
        <form.Field name='type'>
          {(field) => (
            <PlaybookItemTypeField name='type' value={field.state.value} onChange={onChangeType} />
          )}
        </form.Field>
      </FormItem>

      <FormItem>
        <form.Field
          name='name'
          validators={{
            onBlur: ({ value }) => requiredValidator(t('form.name.validation.required'))(value),
          }}
        >
          {(field) => (
            <PlaybookItemNameField
              id={field.name}
              name={field.name}
              value={field.state.value}
              placeholder={t('form.name.placeholder')}
              errorMessage={field.state.meta.errors.join()}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={() => {
                field.handleBlur();
                if (!field.state.meta.errors.length && field.state.meta.isDirty) {
                  onBlurName(field.state.value);
                }
              }}
            />
          )}
        </form.Field>
      </FormItem>

      <FormItem>
        <form.Subscribe selector={(state) => state.values.type}>
          {(type) => <PlaybookItemQuestionLabel type={type} />}
        </form.Subscribe>

        <form.Field
          name='question'
          validators={{
            onBlur: ({ value }) => requiredValidator(t('form.question.validation.required'))(value),
          }}
        >
          {(field) => (
            <TextareaAutoSize
              id={field.name}
              name={field.name}
              value={field.state.value}
              placeholder={t('form.question.placeholder')}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={() => {
                field.handleBlur();
                if (!field.state.meta.errors.length && field.state.meta.isDirty) {
                  onBlurQuestion(field.state.value);
                }
              }}
              errorMessage={field.state.meta.errors.join()}
            />
          )}
        </form.Field>
      </FormItem>

      {form.getFieldValue('type') === PlaybookItemTypeEnum.MULTIPLE_CHOICE && (
        <FormItem>
          <form.Field name='contextMultipleChoiceOptions' mode='array'>
            {(field) => (
              <PlaybookItemContextMultipleChoiceOptions
                options={field.state.value}
                onChange={(options) => {
                  field.handleChange(options);
                  onChangeMultipleChoiceOptions(options);
                }}
              />
            )}
          </form.Field>
        </FormItem>
      )}

      <FormItem>
        <form.Field name='contextText'>
          {(field) => (
            <PlaybookItemContextTextField
              name={field.name}
              value={field.state.value}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={() => {
                field.handleBlur();
                if (!field.state.meta.errors.length && field.state.meta.isDirty) {
                  onBlurContext('text', field.state.value);
                }
              }}
              errorMessage={field.state.meta.errors.join()}
            />
          )}
        </form.Field>
      </FormItem>

      <FormItem>
        <form.Field name='contextRedraftInstructions'>
          {(field) => (
            <PlaybookItemContextRedraftInstructionsField
              name={field.name}
              value={field.state.value}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={() => {
                field.handleBlur();
                if (!field.state.meta.errors.length && field.state.meta.isDirty) {
                  onBlurContext('redraftInstructions', field.state.value);
                }
              }}
              errorMessage={field.state.meta.errors.join()}
            />
          )}
        </form.Field>
      </FormItem>

      {currentUser.role === UserRole.SUPERADMIN && (
        <FormItem>
          <FormItemLabel label={t('form.superAdminSettings.title')} />
          <div className='flex flex-col gap-1 border border-stroke-weaker p-6'>
            <FormItemLabel label={t('form.superAdminSettings.aiModel')} htmlFor='contextLLMModel' />
            <form.Field name='contextLLMModel'>
              {(field) => (
                <SelectAIModel
                  value={field.state.value}
                  onChange={(value) => {
                    onChangeModel(value);
                    field.handleChange(value);
                  }}
                />
              )}
            </form.Field>
          </div>
        </FormItem>
      )}
    </form>
  );
};
