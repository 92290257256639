import { useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDefaultJurisdiction } from 'common/hooks/useDefaultJurisdiction';

import type { ApiPlaybook } from '@legalfly/api/playbooks';
import type { ApiReviewItem } from '@legalfly/api/reviews';
import { useSelectedDocuments } from '@legalfly/components/documentPicker/SelectedDocumentsProvider';
import { isDocumentFile } from '@legalfly/components/documents/helpers';
import { reviewToasts } from '@legalfly/components/review/toasts';
import { trackEvent } from '@legalfly/reporting/tracking';
import { useToast, withToasts } from '@legalfly/ui/toast';
import { getKeys } from '@legalfly/utils/object';
import type { WebSocketCallback } from '@legalfly/websockets';
import { WebSocketManagerInstance } from '@legalfly/websockets';
import { useCreateReview } from 'core/modules/reviews';

import type { PlaybookItemAction, PlaybookItemActions, PlaybookItemState } from './types';

const initialState: PlaybookItemState = {
  selectedPlaybookItem: undefined,
  reviewItem: undefined,
  layout: 'review',
  isLoading: false,
  isFormValid: false,
};

function reducer(state: PlaybookItemState, action: PlaybookItemAction): PlaybookItemState {
  switch (action.type) {
    case 'SET_LAYOUT':
      return { ...state, layout: action.payload };
    case 'SET_SELECTED_PLAYBOOK_ITEM':
      return { ...state, ...initialState, selectedPlaybookItem: action.payload };
    case 'SET_REVIEW_ITEM':
      return { ...state, reviewItem: action.payload };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'RESET': {
      if (!action.payload.length) return initialState;

      return getKeys(initialState).reduce(
        (acc, key) => {
          if (action.payload.includes(key)) {
            // @ts-expect-error tis ok
            acc[key] = state[key];
          }
          return acc;
        },
        { ...initialState },
      );
    }
    case 'SET_IS_FORM_VALID':
      return { ...state, isFormValid: action.payload };
    default:
      return state;
  }
}

export function usePlaybookItem(playbookUuid: ApiPlaybook['uuid']) {
  const { t, i18n } = useTranslation();
  const { selectedDocuments, clearSelectedDocuments } = useSelectedDocuments();
  const defaultJurisdiction = useDefaultJurisdiction();
  const { toast } = useToast();

  const { createReview } = useCreateReview();

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions: PlaybookItemActions = useMemo(
    () => ({
      setLayout: (layout) => {
        trackEvent(
          {
            action: 'click',
            category: 'playbooksItem',
            label: 'changeLayout',
          },
          {
            layout,
          },
        );

        dispatch({ type: 'SET_LAYOUT', payload: layout });
      },

      setSelectedPlaybookItem: (item) => {
        clearSelectedDocuments();
        dispatch({ type: 'SET_SELECTED_PLAYBOOK_ITEM', payload: item });
      },

      setReviewItem: (item) => {
        dispatch({ type: 'SET_REVIEW_ITEM', payload: item });
      },

      reset: (preserve = []) => {
        clearSelectedDocuments();
        dispatch({ type: 'RESET', payload: preserve });
      },

      setIsLoading: (isLoading) => {
        dispatch({ type: 'SET_IS_LOADING', payload: isLoading });
      },

      setIsFormValid: (isValid) => {
        dispatch({ type: 'SET_IS_FORM_VALID', payload: isValid });
      },

      handleStart: async (action) => {
        trackEvent({
          action: 'click',
          category: 'playbooksItem',
          label: action,
        });

        actions.setIsLoading(true);
        const document = selectedDocuments[0];

        const { items } = await withToasts(
          createReview({
            body: {
              category: isDocumentFile(document) ? document.category : 'UNDEFINED_DOCUMENT',
              party: 'neutral',
              language: isDocumentFile(document) ? document.languages[0] : i18n.language,
              jurisdiction: isDocumentFile(document) ? document.jurisdiction : defaultJurisdiction,
              documentUuid: document.uuid,
              playbookUuids: [playbookUuid],
              playbookItemUuids: [state.selectedPlaybookItem?.uuid!],
              isSandbox: true,
            },
          }),
        )(reviewToasts.createReview());

        const reviewItemUuid = items.find(
          (item) => item.playbookItemUuid === state.selectedPlaybookItem?.uuid,
        )?.uuid;

        if (reviewItemUuid) {
          const callback: WebSocketCallback<`review_item_${string}`> = (data) => {
            clearTimeout(timeoutId);
            actions.setReviewItem(data as ApiReviewItem);
            actions.setIsLoading(false);
          };

          const timeoutId = setTimeout(() => {
            toast({
              variant: 'warning',
              title: t('playbooks.warnings.noAnswer.title'),
              description: t('playbooks.warnings.noAnswer.description'),
            });
            actions.setIsLoading(false);
            WebSocketManagerInstance.unsubscribe(`review_item_${reviewItemUuid}`, callback);
          }, 1000 * 20);

          WebSocketManagerInstance.subscribe(`review_item_${reviewItemUuid}`, callback);
        }
      },
    }),
    [
      clearSelectedDocuments,
      createReview,
      defaultJurisdiction,
      i18n.language,
      playbookUuid,
      selectedDocuments,
      state.selectedPlaybookItem?.uuid,
      t,
      toast,
    ],
  );

  return {
    ...state,
    selectedDocument: selectedDocuments[0],
    actions,
  };
}
