import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { type TFunction } from 'i18next';

import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import { FormItemLabel } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';

import { PlaybookItemTooltip } from '../PlaybookItemTooltip';
import SelectPlaybookItemType from '../select/SelectPlaybookItemType';
import type { PlaybookItemFormType } from '../types';

interface PlaybookItemTypeFieldProps
  extends Pick<ComponentProps<typeof SelectPlaybookItemType>, 'value' | 'onChange'> {
  name: string;
}

function getPlaybookItemTooltips(t: TFunction, type: PlaybookItemFormType) {
  const tooltipMappings = {
    titles: {
      [PlaybookItemTypeEnum.MULTIPLE_CHOICE]: t(
        'tooltips.playbooks.playbookTypes.multipleChoice.title',
      ),
      [PlaybookItemTypeEnum.YES_NO]: t('tooltips.playbooks.playbookTypes.yesNo.title'),
      [PlaybookItemTypeEnum.RISK_ASSESSMENT]: t(
        'tooltips.playbooks.playbookTypes.riskAssessment.title',
      ),
      [PlaybookItemTypeEnum.QUESTION]: t('tooltips.playbooks.playbookTypes.question.title'),
    },
    descriptions: {
      [PlaybookItemTypeEnum.MULTIPLE_CHOICE]: t(
        'tooltips.playbooks.playbookTypes.multipleChoice.description',
      ),
      [PlaybookItemTypeEnum.YES_NO]: t('tooltips.playbooks.playbookTypes.yesNo.description'),
      [PlaybookItemTypeEnum.RISK_ASSESSMENT]: t(
        'tooltips.playbooks.playbookTypes.riskAssessment.description',
      ),
      [PlaybookItemTypeEnum.QUESTION]: t('tooltips.playbooks.playbookTypes.question.description'),
    },
  } as const;

  return {
    title: tooltipMappings.titles[type],
    description: tooltipMappings.descriptions[type],
  };
}

export const PlaybookItemTypeField = ({ name, value, onChange }: PlaybookItemTypeFieldProps) => {
  const { t } = useTranslation();
  const { title, description } = getPlaybookItemTooltips(t, value);

  return (
    <>
      <div className='mb-2 flex items-center gap-2'>
        <FormItemLabel label={`${t('form.type.label')}*`} htmlFor={name} />
        <PlaybookItemTooltip title={title} description={description}>
          <Icon name='info-circle' className='-mt-2' />
        </PlaybookItemTooltip>
      </div>
      <div className='flex w-full'>
        <SelectPlaybookItemType triggerProps={{ id: name }} value={value} onChange={onChange} />
      </div>
    </>
  );
};
