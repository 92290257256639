import type { RefObject } from 'react';
import { useEffect, useLayoutEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

import type { ApiConversationDetail } from '@legalfly/api/conversations';

interface Props {
  conversation: ApiConversationDetail | undefined;
  isLoadingConversation: boolean;
  isConversationLoaded: boolean;
  containerRef: RefObject<HTMLDivElement>;
  children: (props: { scrollHeight: number }) => React.ReactNode;
}

export const BaseCopilotMessages = ({
  containerRef,
  conversation,
  isLoadingConversation,
  isConversationLoaded,
  children,
}: Props) => {
  const [scrollHeight, setScrollHeight] = useState(0);

  useResizeObserver<HTMLDivElement>({
    ref: containerRef,
    onResize: ({ height }) => {
      setScrollHeight(height ?? 0);
    },
  });

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const lastMessage = conversation?.messages.slice(-1)[0];
    if (lastMessage?.assistantMessage !== '') {
      return;
    }

    const bubbleGroups = containerRef.current.getElementsByClassName('copilot-message');

    if (bubbleGroups.length > 0) {
      const lastBubbleGroup = bubbleGroups[bubbleGroups.length - 1] as HTMLElement;

      const scrollTo = lastBubbleGroup.offsetTop + 10;

      containerRef.current.scrollTo({
        top: scrollTo,
        behavior: 'smooth',
      });
    }
  }, [containerRef, conversation?.messages]);

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      if (containerRef.current) {
        containerRef.current.style.visibility = 'hidden';
      }

      setTimeout(() => {
        if (containerRef.current && scrollHeight > 0) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
          containerRef.current.style.visibility = 'visible';
        }
      }, 0);
    });
  }, [conversation?.uuid, containerRef, scrollHeight, isConversationLoaded]);

  if (isLoadingConversation) {
    return null;
  }

  return <div className='relative flex flex-col'>{children({ scrollHeight })}</div>;
};
