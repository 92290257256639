import { useTranslation } from 'react-i18next';

import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';

interface Props {
  title: string;
  createdAt: Date;
  className?: string;
}

export const DraftingHeader = ({ title, createdAt, className }: Props) => {
  const { i18n } = useTranslation();

  return (
    <div
      className={cn(
        'mx-auto mb-5 flex min-h-24 justify-between bg-gradient-to-r from-fill-strongest from-60% to-fill-lemon/15 px-6 py-4',
        className,
      )}
    >
      <div className='flex w-full gap-2 self-end pe-2'>
        <Text className={cn('w-full truncate font-writer text-heading uppercase')}>{title}</Text>
      </div>
      <div className='flex flex-col items-end justify-between'>
        <Text
          variant='captionLight'
          className='text-content-body-weak'
          title={formatLongDate(createdAt, i18n.language)}
        >
          {formatRelativeTime(createdAt, i18n.language)}
        </Text>
        <Text className='font-writer'>Legalfly</Text>
      </div>
    </div>
  );
};
